import React, { useState, useEffect,useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import db from "./Firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from "date-fns";
import TopPanel from "./TopPanel";
import Footer from "./Footer";
import "./profiilePage.css";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import ExportWallet from "./ExportWallet";
import { getUserInfo, calculateChanges, reconsileWallet,handleLogout } from "./Utility";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AddTransaction } from "./forms";
import { ReportPdf } from "./pages";
import { ConversastionLogs,EmailLogs,CallLogs } from './components'
import GlobalContext from "./context/GlobalContext";

function ProfilePage() {
  const {permissible_roles} = useContext(GlobalContext);
  const navigate = useNavigate();
  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn") === "true";
    console.log(loggedIn);
    if (!loggedIn) {
      navigate("/login");
  }else{
      if(permissible_roles.length>0){
          if(!permissible_roles.includes('profile')){
              handleLogout()
              navigate("/permission_denied");
          }
      }
  }
  }, [navigate,permissible_roles]);
  
  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  };

  const [showTransactionForm, setShowTransactionForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // State to manage loader for Save action

  const { loggedIn, userId, username, loggedIn_user } = getUserInfo();
  let params = useParams();
  const moment = extendMoment(Moment);
  const [timeOfDay, setTimeOfDay] = useState("");
  const [data, setData] = useState({});
  const [vacationData, setVacationData] = useState([]);
  const [ticketsData, setTicketsData] = useState([]);
  const [activitiesData, setActivitiesData] = useState([]);
  const [orderListData, setOrderListData] = useState([]);
  const [uniqueOrders, setUniqueOrders] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [delPreData, setdelPreData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hubData, setHubData] = useState({});
  const [deliveryExecutives, setDeliveryExecutives] = useState({});

  const [edit, setEdit] = useState(false);
  const [editID, setEditID] = useState("");

  const initialFormState = {
    amount: "",
    description: "",
    reason: "",
    status: "",
    created_date: new Date(),
  };
  const [submit, setSubmit] = useState(initialFormState);

  const initialFormStateCredit = {
    credit_limit: "",
    send_notification: false,
    status: "",
    created_date: new Date(),
  };
  const [submitCredit, setSubmitCredit] = useState(initialFormStateCredit);

  const initialFormStateDF = {
    delivery_mode: "",
    time: "",
    additional_instruction: "",
    status: "",
    created_date: new Date(),
  };
  const [submitDF, setSubmitDF] = useState(initialFormStateDF);

  const [dfID, setDfID] = useState("");

  const initialFormStateCash = {
    amount: "",
    date: "",
    time: "",
    status: "",
    created_date: new Date(),
  };
  const [submitCash, setSubmitCash] = useState(initialFormStateCash);

  const initialFormStateSRL = {
    amount: "",
    email: false,
    sms: false,
    status: "",
    created_date: new Date(),
  };
  const [submitSRL, setSubmitSRL] = useState(initialFormStateSRL);

  const initialFormStateVacation = {
    start_date: "",
    end_date: "",
    source: "",
    status: "1",
    created_date: new Date(),
    updated_date: new Date(),
  };
  const [submitVacation, setSubmitVacation] = useState(
    initialFormStateVacation
  );

  const initialFormStateTickets = {
    category: "",
    status: "",
    priority: "",
    due_date: "",
    owner: "",
    description: "",
    visible_on: "",
    status: "1",
    created_date: new Date(),
    updated_date: new Date(),
    ticket_id: "",
  };
  const [submitTickets, setSubmitTickets] = useState(initialFormStateTickets);
  const [oldTicketData, setOldTicketData] = useState();
  const [activeTab, setActivetab] = useState("Home");
  const [oid, setOid] = useState("");
  const [startDate, setStartDate] = useState(addDays(new Date(), 1));
  const [endDate, setEndDate] = useState(null);
  const [adhocDeliveryDate, setAdhocDeliveryDate] = useState(new Date());
  const [cartItems, setCartItems] = useState([]);
  const [activeCart, setSetActiveCart] = useState("Subscription");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [subscriptionDataID, setSubscriptionDataID] = useState("");
  const [startCalendarDate, setStartCalendarDate] = useState(new Date());
  const [subscriptionCalendarData, setSubscriptionCalendarData] = useState([]);
  const [subscriptionCalendarFutureData, setSubscriptionCalendarFutureData] =
    useState([]);
  const [newQuantityBU, setNewQuantityBU] = useState("");
  const [startDateBU, setStartDateBU] = useState(null);
  const [endDateBU, setEndDateBU] = useState(null);
  const [sidBU, setSidBU] = useState("");
  const [updateCustomer, setUpdateCustomer] = useState("");
  const [OrderHistoryCount, setOrderHistoryCount] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [showCreditButton, setShowCreditButton] = useState(false);
  const [calendarDate, setCalendarDate] = useState(getTomorrowDate());
  const [showModal, setShowModal] = useState("");
  const [isOnVacation, setIsOnVacation] = useState(false);
  const [resumeDate, setResumeDate] = useState(null);
  const [selectedSubscriptionToPause, setSelectedSubscriptionToPause] =
    useState("");
  const [selectedSubIdToPause, setSelectedSubIdToPause] = useState("");
  const [afterEleven, setAfterEleven] = useState(false);

  const openModal = () => {
    // alert("ooo")
    window.modelshow();
  };

  const closeModal = () => {
    window.modalHide();
  };

  function generateRandomTicket() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let ticket_id = "";
    const length = 8;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      ticket_id += characters[randomIndex];
    }

    return ticket_id;
  }

  // const navi = (id) => {
  //     const url = `/edit_customers/${id}`;
  //     const newTab = window.open(url, '_blank');
  //     newTab.focus();
  // }

  const navi = (id) => {
    const url = `/edit_customers/${id}`;
    navigate(url);
    // const newTab = window.open(url, '_blank');
    // newTab.focus();
  };

  const handleAddToCart = (item) => {
    const sId = generateCustomerId();
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
    const day = String(startDate.getDate()).padStart(2, "0"); // Add leading zero if necessary
    const dateString = `${year}-${month}-${day}`;

    const timestamp = new Date(dateString);
    // Create a new object with the start_date property added
    const newItem = {
      ...item,
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      interval: 1,
      coupon_code: "",
      quantity: 1,
      subscription_id: sId,
      start_date: timestamp,
      delivered_by: hubData.hub_name,
      delivering_to: `Home - ${data.data && data.data.customer_address},${
        data.data && data.data.floor
      },${data.data && data.data.flat_villa_no}, ${
        data.data && data.data.pincode
      }`,
      status: "1",
      customer_id: `${data.data && data.data.customer_id}`,
      customer_name: `${data.data && data.data.customer_name}`,
      customer_phone: `${data.data && data.data.customer_phone}`,
      created_date: new Date(),
      updated_date: new Date(),
      reason: "",
      resume_date: new Date(),
    };

    // Add the new item to the cart
    setCartItems((prevItems) => [...prevItems, newItem]);
  };

  const [products, setProducts] = useState([]);

  const checkVacationStatus = async () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    try {
      const vacationQuerySnapshot = await db
        .collection("customers_vacation")
        .where("customer_id", "==", params.id)
        .get();

      const currentDate = new Date();
      let onVacation = false;

      vacationQuerySnapshot.forEach((doc) => {
        const vacationData = doc.data();
        const startDate = vacationData.start_date.toDate();
        startDate.setHours(0, 0, 0, 0);
        const endDate = vacationData.end_date.toDate();
        endDate.setHours(23, 59, 59, 999);
        if (today >= startDate && endOfToday <= endDate) {
          onVacation = true;
          console.log("on vacation");
        }
      });

      setIsOnVacation(onVacation);
    } catch (error) {
      console.error("Error fetching vacation data:", error);
    }
  };

  useEffect(() => {
    checkVacationStatus();
    getCalendarDate();
  }, []);

  useEffect(() => {
    const unsubscribe = db
      .collection("products_data")
      .orderBy("created_date", "desc")
      .onSnapshot((snapshot) => {
        setProducts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
            selectedOption: doc.data().packagingOptions[0], // Initialize selected option with the first option
          }))
        );
      });
    // console.log(products)

    return () => unsubscribe(); // Unsubscribe from snapshot listener when component unmounts
  }, []);

  useEffect(() => {
    setSelectedCustomerId(params.id);
    const unsubscribe = db
      .collection("subscriptions_data")
      .where("customer_id", "==", params.id)
      .onSnapshot((snapshot) => {
        const subsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          subs: doc.data(),
        }));
        setSubscriptionData(subsData);
        // console.log(); // This will log an array of objects where each object has an 'id' field representing the document ID
        setSubscriptionDataID(subsData[0]?.id);
      });

    return () => unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
  }, []);

  useEffect(() => {
    const unsubscribe = db
      .collection("subscriptions_data")
      .where("customer_id", "==", params.id)
      .where("status", "==", "1")
      .onSnapshot((snapshot) => {
        const subsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          subs: doc.data(),
        }));
      });

    return () => unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
  }, []);

  const [subscriptionAmount, setSubscriptionAmount] = useState(0);

  useEffect(() => {
    const unsubscribe = db
      .collection("subscriptions_data")
      .where("customer_id", "==", params.id)
      .where("status", "==", "1")
      .onSnapshot((snapshot) => {
        const subsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          const totalAmount = data.quantity * data.price;
          return { id: doc.id, subs: data, totalAmount: totalAmount };
        });

        const totalAmountSum = subsData.reduce((accumulator, current) => {
          return accumulator + current.totalAmount;
        }, 0);
        setSubscriptionAmount(totalAmountSum);
      });

    // Cleanup function
    return () => unsubscribe();
  }, []);

  const subscriptionReasonUpdate = async () => {
    try {
      const subscriptionsRef = db.collection("subscriptions_data");
      const customerDataRef = db.collection("customers_data");

      // Query for subscriptions matching the customer ID
      const querySnapshot = await subscriptionsRef
        .where("customer_id", "==", params.id)
        .get();

      const customerDataSnapshot = await customerDataRef
        .where("customer_id", "==", params.id)
        .get();

      // Ensure customer data is retrieved
      if (customerDataSnapshot.empty) {
        throw new Error("Customer data not found");
      }

      const customerData = customerDataSnapshot.docs[0].data();

      const batch = db.batch();
      const activities = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();

        if (data.reason === "cron") {
          // If reason is 'cron', update status to '1'
          batch.update(doc.ref, { status: "1", reason: "" });
          activities.push({
            action: "Subscription Resumed",
            created_date: new Date(),
            customer_id: customerData.customer_id,
            customer_name: customerData.customer_name,
            customer_phone: customerData.customer_phone,
            description: `Subscription resumed for ${data.subscription_id} after wallet was recharged`,
            object: "Subscription Resumed",
            user: "System",
          });
        }
      });

      // Commit the batch
      await batch.commit();

      // Add customer activities
      const activitiesRef = db.collection("customer_activities");
      const activityPromises = activities.map((activity) =>
        activitiesRef.add(activity)
      );
      await Promise.all(activityPromises);
      console.log("Subscriptions updated successfully");
    } catch (error) {
      console.error("Error updating subscriptions:", error);
    }
  };

  const [selectedIndex, setSelectedIndex] = useState("");

  const handleSaveIndex = (index) => {
    setSelectedIndex(index);
  };

  const [newQuantity, setNewQuantity] = useState("");
  const [quantityMap, setQuantityMap] = useState({});
  const [updateDate, setUpdateDate] = useState("");
  const [chechVacDate, setChechVacDate] = useState("");
  const [upProd, setUpProd] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");

  const shiftByDay = (next_delivery_date, docid) => {
    const newDate = moment(next_delivery_date)
      .add(1, "days")
      .format("YYYY-MM-DD");

    Swal.fire({
      title: `Do you want to change subscription date from - ${moment(
        next_delivery_date
      ).format("MMMM Do")} to ${moment(newDate).format("MMMM Do")}`,
      showCancelButton: true,
      confirmButtonText: "Shift by one day",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Saved!", `New delivery date: ${newDate}`, "success");
        db.collection("subscriptions_data")
          .doc(docid)
          .update({ next_delivery_date: newDate })
          .then(() => {
            console.log("Document successfully updated!");
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
      }
    });
  };

  const openbulkQt = (sid, name, subscription) => {
    setNewQuantityBU("");
    setSidBU(sid);
    setSelectedSubscription(subscription);
    setUpProd(name);
    window.bqlopen();
  };

  const closebulkQt = () => {
    window.bqlclose();
  };

  const [editingRow, setEditingRow] = useState(null);
  const [quantityFU, setQuantityFU] = useState("");
  const handleSaveClick = async (id, sid, pname) => {
    if (quantityFU === "" || quantityFU < 0) {
      alert("Please enter a valid quantity");
      return;
    }
  
    setIsSaving(true);
    try {
      const formattedDate = moment(calendarDate).format("YYYY-MM-DD");
      const querySnapshot = await db
        .collection("bulk_update_quantity")
        .where("delivery_date", "==", formattedDate)
        .where("subscription_id", "==", sid)
        .get();
  
      if (!querySnapshot.empty) {
        // Document exists, update the quantity
        querySnapshot.forEach(async (doc) => {
          await doc.ref.update({ quantity: parseInt(quantityFU) });
        });
      } else {
        // Document does not exist, create a new entry
        await db.collection("bulk_update_quantity").add({
          customer_id: data.data.customer_id,
          date: new Date(calendarDate),
          delivery_date: formattedDate,
          quantity: parseInt(quantityFU),
          subscription_id: sid,
          status: "1",
          product_name: pname,
          // Add other necessary fields here
        });
      }
  
      // Update the subscription data state with the new quantity
      setSubscriptionCalendarFutureData((prevData) =>
        prevData.map((item) =>
          item.id === id
            ? {
                ...item,
                subs: { ...item.subs, quantity: parseInt(quantityFU) },
              }
            : item
        )
      );
  
      // Log the activity in Firestore
      await db.collection("customer_activities").add({
        customer_phone: data.data && data.data.customer_phone,
        customer_id: data.data && data.data.customer_id,
        customer_name: data.data && data.data.customer_name,
        customer_address: data.data && data.data.customer_address,
        hub_name: data.data && data.data.hub_name,
        delivery_exe_id: data.data && data.data.delivery_exe_id,
        user: loggedIn_user,
        object: "Subscription",
        action: "Change Quantity (Calendar)",
        description: `Quantity changed to ${quantityFU} for ${calendarDate} in calendar for subscription ID: ${sid} through CRM by ${loggedIn_user}`,
        date: new Date(),
        created_date: new Date(),
      });
  
      console.log("Activities added");
      setEditingRow(null); // Reset the editing state
    } catch (error) {
      console.error("Error updating or creating document: ", error);
    } finally {
      setIsSaving(false); // Hide loader after completion
    }
  };
  
  const handleDateChange = async (date) => {
    try {
      const today = moment(date).startOf("day");
      const endOfToday = moment(date).endOf("day");

      setChechVacDate(date);
      setStartCalendarDate(date);

      if (date) {
        const dateString = moment(date).format("YYYY-MM-DD");
        setUpdateDate(dateString);

        let isOnVacation = false;

        const vacationQuerySnapshot = await db
          .collection("customers_vacation")
          .where("customer_id", "==", params.id)
          .get();

        vacationQuerySnapshot.forEach((doc) => {
          const vacationData = doc.data();
          const startDate = moment(vacationData.start_date.toDate()).startOf(
            "day"
          );
          const endDate = moment(vacationData.end_date.toDate()).endOf("day");

          if (today.isBetween(startDate, endDate, null, "[]")) {
            console.log("on vacation");
            isOnVacation = true;
            setSubscriptionCalendarFutureData([]);
            Swal.fire("On Vacation");
          } else {
            console.log("not on vacation");
          }
        });

        if (!isOnVacation) {
          const subscriptionSnapshot = await db
            .collection("subscriptions_data")
            .where("customer_id", "==", params.id)
            .where("status", "==", "1")
            .get();

          if (!subscriptionSnapshot.empty) {
            const subscriptionData = subscriptionSnapshot.docs.map((doc) => ({
              id: doc.id,
              subs: doc.data(),
            }));

            const bulkUpdateQuery = db
              .collection("bulk_update_quantity")
              .where("customer_id", "==", params.id)
              .where("delivery_date", "==", moment(date).format("YYYY-MM-DD"));

            const bulkUpdateSnapshot = await bulkUpdateQuery.get();
            const bulkUpdateData = bulkUpdateSnapshot.docs.map((doc) => ({
              id: doc.id,
              subs: doc.data(),
            }));

            const subscriptionDataForDate = subscriptionData.filter((item) => {
              const {
                subscription_type: type,
                interval,
                next_delivery_date,
              } = item.subs;
              const nextDeliveryDate =
                moment(next_delivery_date).startOf("day");
              const selectedDate = moment(date).startOf("day");

              if (type === "Everyday") {
                // Ensure it is only for the selected date and future dates, not before the next delivery date
                return selectedDate.isSameOrAfter(nextDeliveryDate, "day");
              } else if (type === "On-Interval") {
                const diffDays = selectedDate.diff(nextDeliveryDate, "days");
                return diffDays >= 0 && diffDays % interval === 0;
              } else if (type === "Custom") {
                const dayOfWeek = selectedDate.format("dddd").toLowerCase();
                return (
                  item.subs[dayOfWeek] > 0 &&
                  selectedDate.isSameOrAfter(nextDeliveryDate)
                );
              } else if (type === "One Time") {
                return selectedDate.isSame(nextDeliveryDate, "day");
              }
              return false;
            });

            subscriptionDataForDate.forEach((sub) => {
              const bulkUpdate = bulkUpdateData.find(
                (update) =>
                  update.subs.subscription_id === sub.subs.subscription_id
              );
              if (bulkUpdate) {
                sub.subs.quantity = bulkUpdate.subs.quantity;
              } else if (sub.subs.subscription_type === "Custom") {
                const dayOfWeek = new Date(date)
                  .toLocaleDateString("en-US", { weekday: "long" })
                  .toLowerCase();
                sub.subs.quantity = sub.subs[dayOfWeek] || 0;
              }
            });

            setSubscriptionCalendarFutureData(subscriptionDataForDate);
          } else {
            setSubscriptionCalendarFutureData([]);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };
  const handleEditClick = (index, initialQuantity, id) => {
    setEditingRow(index);
    setQuantityFU(initialQuantity);
    // alert(id)
  };

  const handleQuantityUpdate = (e) => {
    const { value } = e.target;
    setNewQuantity(value);
  };

  const handleQuantityUpdateSave = (sid) => {
    // Convert checkVacDate to a string in the 'YYYY-MM-DD' format
    let checkVacDateStr = chechVacDate.toISOString().split("T")[0];

    // Fetch existing vacations for the same customer from the database
    db.collection("customers_vacation")
      .where("customer_id", "==", params.id)
      .get()
      .then((querySnapshot) => {
        let overlappingVacationExists = false;

        querySnapshot.forEach((doc) => {
          const vacation = doc.data();
          const vacationStartDateStr = vacation.start_date
            .toDate()
            .toISOString()
            .split("T")[0];
          const vacationEndDateStr = vacation.end_date
            .toDate()
            .toISOString()
            .split("T")[0];

          // Check if the selected date lies between the start and end dates of the vacation
          if (
            checkVacDateStr >= vacationStartDateStr &&
            checkVacDateStr <= vacationEndDateStr
          ) {
            overlappingVacationExists = true;
          }
        });

        if (overlappingVacationExists) {
          // If there is a vacation that includes the selected date, show an alert
          alert(
            "Cannot update quantity because there is an active vacation that includes the selected date."
          );
        } else {
          // If no overlapping vacations, proceed with the quantity update
          updateSubscriptionQuantityInFirestore(updateDate, sid, newQuantity);
          setSelectedIndex("");
          refreshDateChange(updateDate);
        }
      })
      .catch((error) => {
        console.error("Error fetching existing vacations:", error);
        // Handle the error accordingly (e.g., show an error message)
      });
  };

  const refreshDateChange = async (date) => {
    try {
      // Fetch subscription data from Firestore based on the customer_phone
      const snapshot = await db
        .collection("subscriptions_data")
        .where("customer_id", "==", params.id)
        .get();

      if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // Check if subscription period data is available for the selected date
        const subscriptionDataForDate = data
          .map(
            (item) => item.subscription_period && item.subscription_period[date]
          )
          .filter((item) => item);
        setSubscriptionCalendarData(subscriptionDataForDate);
      } else {
        setSubscriptionCalendarData([]);
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  const handleChangeCartDate = (date, index) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error("Invalid date selected");
      return; // Ensure the function exits if the date is invalid
    }
  
    // Update startDate state
    setStartDate(date);
  
    // Format the date to "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary
    const formattedDate = `${year}-${month}-${day}`;
  
    // Convert formatted date back to a Date object (for consistency)
    const timestamp = new Date(formattedDate);
  
    // Update cartItems state
    setCartItems((prevItems) => {
      return prevItems.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            start_date: timestamp, // Update the start_date field
          };
        }
        return item;
      });
    });
  };
  
  const handleChangeEndDate = (date, index) => {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error("Invalid end date selected");
      return;
    }
  
    setEndDate(date);
  
    setCartItems((prevItems) => {
      return prevItems.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            end_date: date, // Update the end_date field
          };
        }
        return item;
      });
    });
  };
  
  const [showNth, setShowNth] = useState(false);
  const handleInterval = async (interval, index) => {
    if (interval === "nth") {
      handleInterval("5", index);
      setShowNth(true);
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index].interval =
          interval === "nth" ? 5 : parseInt(interval);
        // updatedItems[index].interval_category = "nth"; // Update the start_date field in the cart item
        return updatedItems;
      });
    } else {
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index].interval = parseInt(interval); // Update the start_date field in the cart item
        // updatedItems[index].interval_category = ""; //
        return updatedItems;
      });
      setShowNth(false);
    }
  };

  const [userMapID, setUserMapID] = useState({});

  const usermap = () => {};

  // Function to calculate the next delivery date for custom-day subscriptions
  const calculateNextDeliveryDate = (currentDate, selectedDays) => {
    let nextDeliveryDate = new Date(currentDate);

    // Move the current date to the next day and iterate until a match is found
    do {
      nextDeliveryDate.setDate(nextDeliveryDate.getDate() + 1);
      const dayOfWeek = nextDeliveryDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

      if (selectedDays.includes(dayOfWeek)) {
        break;
      }
    } while (true);

    return nextDeliveryDate;
  };

  const handleChangenCustom = (e, index, day) => {
    const { value } = e.target;
    // Update the cartItems array with the modified item
    const updatedCartItems = cartItems.map((item, idx) => {
      if (idx === index) {
        // Create a copy of the item to update
        const updatedItem = { ...item };
        // Update the value of the specified day
        updatedItem[day] = parseInt(value); // Convert value to integer if needed
        return updatedItem;
      }
      return item;
    });
    // Set the updated cartItems array
    setCartItems(updatedCartItems);
    // Log the updated cartItems array
  };
  const handleChangenth = (e, index) => {
    // alert(e.target.value);
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].interval = parseInt(e.target.value); // Update the start_date field in the cart item
      return updatedItems;
    });
  };
  const [showIntervalType, setShowIntervalType] = useState("");
  const handleChangeCart = (e, index) => {
    const { id, value, checked } = e.target;
    if (id === "Everyday" || id === "On-Interval" || id === "Custom") {
      setShowIntervalType(value);
      const nextDeliveryDates = new Date();
      nextDeliveryDates.setDate(startDate.getDate() + 1);
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index].subscription_type = value; // Update the type field
        updatedItems[index].interval = 1; // Update the type field
        updatedItems[index].next_delivery_date = nextDeliveryDates;
        return updatedItems;
      });
      if (id === "On-Interval") {
        handleInterval("2", index);
      } else {
        handleInterval("", index);
      }
      if (id === "Everyday") {
        setCartItems((prevItems) => {
          const updatedItems = [...prevItems];
          updatedItems[index].interval = 1;
          return updatedItems;
        });
      }
    } else {
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index][id] = value;
        return updatedItems;
      });
    }
    if (id === "Everyday" || id === "Custom") {
      setShowNth(false);
    }
  };

  const handleRemoveFromCart = (index) => {
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  // Define your custom quantities
  const customQuantities = {
    sunday: "2",
    monday: "4",
    tuesday: "6",
    // Add more days if needed
  };

  const [productDataID, setProductDataID] = useState({});

  const productmap = () => {
    setProductDataID({});
    db.collection("products_data").onSnapshot((snapshot) => {
      const productDataIDData = snapshot.docs.map((doc) => {
        const data = doc.data();

        const { productName, image, packagingOptions } = data;

        return { [productName]: image };
      });

      // Set the state with the fetched data
      setProductDataID(Object.assign({}, ...productDataIDData));
    });
  };

  const calculateTotalAmount = (orderId) => {
    return orderListData
      .filter((order) => order.data.order_id === orderId)
      .map(
        (filteredOrder) =>
          filteredOrder.data.quantity * filteredOrder.data.price
      )
      .reduce((total, current) => total + current, 0);
  };

  const extractUtilisedBalances = async (orderId) => {
    // Filter orders based on orderId
    console.log("extract : ", orderListData);
    const filteredOrders = orderListData.filter(
      (order) => order.data.order_id === orderId
    );

    // Initialize variables to accumulate total balances
    let utilised_wallet_balance = 0;
    let utilised_credit_limit = 0;

    // Iterate through filtered orders to sum up balances
    filteredOrders.forEach((order) => {
      const orderData = order.data;
      if (orderData.status != "2") {
        utilised_wallet_balance += orderData.utilised_wallet_balance || 0;
        utilised_credit_limit += orderData.utilised_credit_limit || 0;
      }
    });
    return {
      utilised_wallet_balance,
      utilised_credit_limit,
    };
  };

  function calculateNextDelivery(obj) {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    // Get current date and time
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // Get the current day (0 for Sunday, 1 for Monday, etc.)

    // Find the next delivery day with a value greater than 0
    let nextDeliveryDay;
    for (let i = 1; i <= 7; i++) {
      const nextDayIndex = (currentDay + i) % 7; // Get the index of the next day
      const day = days[nextDayIndex]; // Get the day name
      if (obj[day] > 0) {
        nextDeliveryDay = nextDayIndex;
        break;
      }
    }

    // Calculate the next delivery date starting from tomorrow
    if (nextDeliveryDay !== undefined) {
      const nextDeliveryDate = new Date();
      nextDeliveryDate.setDate(currentDate.getDate() + 1); // Start from tomorrow
      while (nextDeliveryDate.getDay() !== nextDeliveryDay) {
        nextDeliveryDate.setDate(nextDeliveryDate.getDate() + 1); // Move to the next day
      }
      return nextDeliveryDate.toISOString().split("T")[0]; // Format as "YYYY-MM-DD"
    } else {
      return null; // No delivery days found
    }
  }

  const generateOrderId = (customer_id) => {
    const timestamp = Date.now().toString();
    const todayDate = moment(new Date()).format("YYYY-MM-DD");
    //const uniqueOrderId = `${customer_id}${todayDate}`;
    const randomComponent = Math.floor(Math.random() * 100);
    const uniqueOrderId =
      (timestamp % 100000000).toString().padStart(6, "0") +
      randomComponent.toString().padStart(2, "0");
    return uniqueOrderId;
  };

  // const handleCheckout = async () => {
  //   setLoading(true);
  
  //   try {
  //     let wallet_balance = data.data?.wallet_balance || 0;
  //     let credit_limit = data.data?.credit_limit || 0;
  //     let remaining_amount = 0;
  //     const orders = [];
  
  //     const showToast = (type, message) => {
  //       Swal.fire({
  //         icon: type,
  //         title: message,
  //         toast: true,
  //         background: "#69aba6",
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 6000,
  //         timerProgressBar: true,
  //       });
  //     };
  
  //     for (const item of cartItems) {
  //       const startDate = new Date(item.start_date).toISOString().split('T')[0]; // Format: yyyy-mm-dd
  //       const endDate = new Date(item.end_date).toISOString().split('T')[0]; // Format: yyyy-mm-dd
  //       const today = new Date().toISOString().split('T')[0];
  
  //       // Validation: Start date cannot be the current date
  //       if (startDate === today && showModal === "addSubscription") {
  //         showToast("error", "Start date cannot be the current date.");
  //         setLoading(false);
  //         return;
  //       }
  
  //       // Validation: End date cannot be earlier than the start date
  //       if (endDate < startDate && showModal === "addSubscription") {
  //         showToast("error", "End date cannot be earlier than the start date.");
  //         setLoading(false);
  //         return;
  //       }
  
  //       // Calculate item total cost
  //       const itemTotal = item.quantity * item.price;
  
  //       let utilised_wallet_balance = 0;
  //       let utilised_credit_limit = 0;
  
  //       if (itemTotal <= wallet_balance) {
  //         // Wallet balance is sufficient
  //         utilised_wallet_balance = itemTotal;
  //         wallet_balance -= utilised_wallet_balance;
  //       } else if (itemTotal <= wallet_balance + credit_limit) {
  //         // Use both wallet and credit limit
  //         utilised_wallet_balance = wallet_balance;
  //         utilised_credit_limit = itemTotal - wallet_balance;
  //         wallet_balance = 0;
  //         credit_limit -= utilised_credit_limit;
  //       } else {
  //         // Insufficient funds
  //         remaining_amount = itemTotal - (wallet_balance + credit_limit);
  //         showToast("error", `You need additional ${remaining_amount} to proceed.`);
  //         setLoading(false);
  //         return;
  //       }
  
  //       orders.push({
  //         item,
  //         utilised_wallet_balance,
  //         utilised_credit_limit,
  //       });
  //     }
  
  //     if (orders.length === 0) {
  //       showToast("error", `You need additional ${remaining_amount} to proceed.`);
  //       return;
  //     }
  
  //     // Process Orders
  //     for (const order of orders) {
  //       const item = order.item;
  //       const { utilised_wallet_balance, utilised_credit_limit } = order;
  
  //       // Set default end date if not provided
  //       if (!item.end_date) {
  //         item.end_date = new Date("3000-01-01");
  //       }
  
  //       if (showModal === "addSubscription") {
  //         // Add Subscription Logic
  //         item.next_delivery_date = moment(item.start_date).format("YYYY-MM-DD");
  //         if (["Custom", "One Time"].includes(item.subscription_type)) {
  //           item.interval = 0;
  //         }
  
  //         await db.collection("subscriptions_data").add(item);
  //         await db.collection("customer_activities").add({
  //           customer_phone: data.data?.customer_phone,
  //           customer_id: data.data?.customer_id,
  //           customer_name: data.data?.customer_name,
  //           customer_address: data.data?.customer_address,
  //           hub_name: data.data?.hub_name,
  //           delivery_exe_id: data.data?.delivery_exe_id,
  //           user: "Server",
  //           object: "Subscription",
  //           action: "Create",
  //           description: `${item.subscription_type} Subscription ID:${item.subscription_id} ${item.product_name} ${item.package_unit} created through System by ${loggedIn_user}. Start date:${item.next_delivery_date}`,
  //           date: new Date(),
  //           created_date: new Date(),
  //         });
  //       } else {
  //         // Adhoc Order Logic
  //         const orderDetails = {
  //           cancelled_reason: "",
  //           cancelled_time: "",
  //           created_date: item.created_date,
  //           customer_id: item.customer_id,
  //           customer_name: item.customer_name,
  //           customer_phone: item.customer_phone,
  //           delivering_to: item.delivering_to,
  //           delivery_date: moment(adhocDeliveryDate).format("YYYY-MM-DD"),
  //           delivery_exe_id: data.data?.delivery_exe_id || "",
  //           delivery_time: "00:00:00",
  //           delivery_timestamp: adhocDeliveryDate,
  //           handling_charges: 0,
  //           hub_name: item.delivered_by,
  //           latitude: data.data?.latitude || "",
  //           location: data.data?.location || "",
  //           longitude: data.data?.longitude || "",
  //           marked_delivered_by: "",
  //           order_id: generateOrderId(item.customer_id),
  //           order_type: "Re-Order",
  //           package_unit: item.package_unit,
  //           price: item.price,
  //           product_name: item.product_name,
  //           quantity: item.quantity,
  //           status: "0",
  //           subscription_id: "0",
  //           tax: 0,
  //           total_amount: item.price * item.quantity,
  //           updated_date: item.created_date,
  //           utilised_wallet_balance,
  //           utilised_credit_limit,
  //         };
  
  //         await db.collection("order_history").add(orderDetails);
  
  //         await db.collection("customer_activities").add({
  //           customer_phone: data.data?.customer_phone,
  //           customer_id: data.data?.customer_id,
  //           customer_name: data.data?.customer_name,
  //           customer_address: data.data?.customer_address,
  //           hub_name: data.data?.hub_name,
  //           delivery_exe_id: data.data?.delivery_exe_id,
  //           user: "Server",
  //           object: "Order",
  //           action: "Create",
  //           description: `Adhoc Order ID:${orderDetails.order_id} ${item.product_name} ${item.package_unit} created through System by ${loggedIn_user}. Delivery date:${orderDetails.delivery_date}`,
  //           date: new Date(),
  //           created_date: new Date(),
  //         });
  
  //         // Update wallet balance and credit limit
  //         const querySnapshot = await db
  //           .collection("customers_data")
  //           .where("customer_id", "==", item.customer_id)
  //           .get();
  
  //         querySnapshot.forEach((doc) => {
  //           db.collection("customers_data").doc(doc.id).update({
  //             wallet_balance: wallet_balance,
  //             credit_limit: credit_limit,
  //           });
  //         });
  //       }
  //     }
  
  //     // Show Success Message
  //     showToast("success", "Checkout completed successfully!");
  //     setCartItems([]);
  //     closeSubscription();
  //   } catch (error) {
  //     console.error("Error during checkout:", error);
  //   } finally {
  //     setLoading(false); // Re-enable the button
  //   }
  // };
  

  
  const handleCheckout = async () => {
    setLoading(true);

    try {
      // Retrieve initial balances
      let wallet_balance = data.data?.wallet_balance || 0;
      let credit_limit = data.data?.credit_limit || 0;
      let remaining_amount = 0;
      const orders = [];

      for (const item of cartItems) {
      const startDate = new Date(item.start_date);
      const endDate = new Date(item.end_date);
      const today = new Date();
      // Ensure the current date cannot be selected for start date
      if (
        startDate.toDateString() === today.toDateString() &&
        showModal === "addSubscription"
      ) {
        const Toast = Swal.mixin({
          toast: true,
          background: "#69aba6",
          position: "top-end",
          showConfirmButton: false,
          timer: 6000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: `Start date cannot be the current date.`,
        });
        setLoading(false);
        return;
      }

      // Ensure end date is not earlier than start date
      if (endDate < startDate && showModal === "addSubscription") {
        const Toast = Swal.mixin({
          toast: true,
          background: "#69aba6",
          position: "top-end",
          showConfirmButton: false,
          timer: 6000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: `End date cannot be earlier than the start date.`,
        });
        setLoading(false);
        return;
      }
    
        const itemTotal = item.quantity * item.price;

        let utilised_wallet_balance = 0;
        let utilised_credit_limit = 0;

        if (itemTotal <= wallet_balance) {
          // Wallet balance is sufficient for this item
          utilised_wallet_balance = itemTotal;
          utilised_credit_limit = 0;
          wallet_balance -= utilised_wallet_balance;
        } else if (wallet_balance >= 0) {
          if (itemTotal <= wallet_balance + credit_limit) {
            // Wallet balance and credit limit together are sufficient
            utilised_wallet_balance = wallet_balance;
            utilised_credit_limit = itemTotal - wallet_balance;
            credit_limit -= utilised_credit_limit;
            wallet_balance -= utilised_wallet_balance;
            wallet_balance -= utilised_credit_limit;
          } else {
            remaining_amount = itemTotal - (wallet_balance + credit_limit);
            const Toast = Swal.mixin({
              toast: true,
              background: "#69aba6",
              position: "top-end",
              showConfirmButton: false,
              timer: 6000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: `You need additional ${remaining_amount} to proceed`,
            });
            return;
          }
        } else if (wallet_balance < 0) {
          if (itemTotal <= credit_limit) {
            utilised_wallet_balance = 0;
            utilised_credit_limit = itemTotal;
            credit_limit -= utilised_credit_limit;
            wallet_balance -= utilised_credit_limit;
          }
        } else {
          // Neither wallet balance nor credit limit can cover the item total
          utilised_wallet_balance = 0;
          utilised_credit_limit = 0;
          console.error(`Insufficient funds for item: ${item.product_name}`);
          remaining_amount = itemTotal - (wallet_balance + credit_limit);
          const Toast = Swal.mixin({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `You need additional ${remaining_amount} to proceed`,
          });
          return;
        }

        orders.push({
          item,
          utilised_wallet_balance,
          utilised_credit_limit,
        });
      }

      if (orders.length > 0) {
        for (const order of orders) {
          const item = order.item;
          const utilised_wallet_balance = order.utilised_wallet_balance;
          const utilised_credit_limit = order.utilised_credit_limit;

          if (item.end_date === "") {
            // Set a default far future date instead of null
            // item.end_date = new Date("3000-01-01");
            item.end_date = null;
          }

          if (showModal === "addSubscription") {
            item.next_delivery_date = moment(item.start_date).format(
              "YYYY-MM-DD"
            );
            if (
              item.subscription_type === "Custom" ||
              item.subscription_type === "One Time"
            ) {
              item.interval = 0;
            }
            await db.collection("subscriptions_data").add(item);
            await db.collection("customer_activities").add({
              customer_phone: data.data?.customer_phone,
              customer_id: data.data?.customer_id,
              customer_name: data.data?.customer_name,
              customer_address: data.data?.customer_address,
              hub_name: data.data?.hub_name,
              delivery_exe_id: data.data?.delivery_exe_id,
              user: "Server",
              object: "Subscription",
              action: "Create",
              description: `${item.subscription_type} Subscription ID:${item.subscription_id} ${item.product_name} ${item.package_unit} created through System by ${loggedIn_user}. Start date:${item.next_delivery_date}`,
              date: new Date(),
              created_date: new Date(),
            });
          } else {
            // Adhoc order logic
            const orderDetails = {
              cancelled_reason: "",
              cancelled_time: "",
              created_date: item.created_date,
              customer_id: item.customer_id,
              customer_name: item.customer_name,
              customer_phone: item.customer_phone,
              delivering_to: item.delivering_to,
              delivery_date: moment(adhocDeliveryDate).format("YYYY-MM-DD"),
              delivery_exe_id: data.data?.delivery_exe_id || "",
              delivery_time: "00:00:00",
              delivery_timestamp: adhocDeliveryDate,
              handling_charges: 0,
              hub_name: item.delivered_by,
              latitude: data.data?.latitude || "",
              location: data.data?.location || "",
              longitude: data.data?.longitude || "",
              marked_delivered_by: "",
              order_id: generateOrderId(item.customer_id),
              order_type: "Re-Order",
              package_unit: item.package_unit,
              price: item.price,
              product_name: item.product_name,
              quantity: item.quantity,
              status: "0",
              subscription_id: "0",
              tax: 0,
              total_amount: item.price * item.quantity,
              updated_date: item.created_date,
              utilised_wallet_balance,
              utilised_credit_limit,
            };
            await db.collection("order_history").add(orderDetails);

            // Add activity to customer_activities table
            await db.collection("customer_activities").add({
              customer_phone: data.data?.customer_phone,
              customer_id: data.data?.customer_id,
              customer_name: data.data?.customer_name,
              customer_address: data.data?.customer_address,
              hub_name: data.data?.hub_name,
              delivery_exe_id: data.data?.delivery_exe_id,
              user: "Server",
              object: "Order",
              action: "Create",
              description: `Adhoc Order ID:${orderDetails.order_id} ${item.product_name} ${item.package_unit} created through System by ${loggedIn_user}. Delivery date:${orderDetails.delivery_date}`,
              date: new Date(),
              created_date: new Date(),
            });

            const querySnapshot = await db
              .collection("customers_data")
              .where("customer_id", "==", item.customer_id)
              .get();

            querySnapshot.forEach((doc) => {
              db.collection("customers_data")
                .doc(doc.id)
                .update({
                  wallet_balance: wallet_balance,
                  credit_limit: credit_limit,
                })
                .then(() => {
                  console.log("Wallet balance updated successfully");
                })
                .catch((error) => {
                  console.error("Error updating wallet balance:", error);
                });
            });

            const Toast = Swal.mixin({
              toast: true,
              background: "#69aba6",
              position: "top-end",
              showConfirmButton: false,
              timer: 6000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: `Adhoc Order Created Successfully`,
            });
          }
        }
        if (showModal !== "addSubscription") {
          wallethistoryAdhoc(
            "debit",
            cartItems.reduce(
              (total, item) => total + item.quantity * item.price,
              0
            ),
            wallet_balance,
            "Manual Order Executed"
          );
        }
        console.log("All cart items pushed to database successfully!");
        setCartItems([]);
        closeSubscription();
      } else {
        const Toast = Swal.mixin({
          toast: true,
          background: "#69aba6",
          position: "top-end",
          showConfirmButton: false,
          timer: 6000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: `You need additional ${remaining_amount} to proceed`,
        });
      }
    } finally {
      setLoading(false); // Re-enable button
    }
  };


  function getDayIndex(day) {
    switch (day) {
      case "sunday":
        return 0;
      case "monday":
        return 1;
      case "tuesday":
        return 2;
      case "wednesday":
        return 3;
      case "thursday":
        return 4;
      case "friday":
        return 5;
      case "saturday":
        return 6;
      default:
        return -1; // Invalid day
    }
  }

  const handleQuantityChange = (index, action) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.map((item, i) => {
        if (i === index) {
          let newQuantity;
          if (action === "add") {
            newQuantity = Number(item.quantity) + 1;
          } else if (action === "subtract") {
            newQuantity = Math.max(Number(item.quantity) - 1, 1); // Ensure quantity never goes below 1
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      });
      return updatedItems;
    });
  };

  const deleteProduct = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("subscriptions_data")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Deleted Successfully");
            Swal.fire("Deleted!", "Subscription has been deleted.", "success");
          });
        //db.collection('subscriptions_data').doc(id).update({ status: "0" });
      }
    });
    db.collection("customer_activities")
      .add({
        customer_phone: data.data && data.data.customer_phone,
        customer_id: data.data && data.data.customer_id,
        customer_name: data.data && data.data.customer_name,
        customer_address: data.data && data.data.customer_name,
        hub_name: data.data && data.data.hub_name,
        delivery_exe_id: data.data && data.data.delivery_exe_id,
        user: loggedIn_user,
        object: "Subscription",
        action: "Delete Subscription",
        description: `Subscription has been deleted by ${loggedIn_user}`,
        date: new Date(),
        created_date: new Date(),
      })
      .then(() => {
        console.log("added activiteies");
      });
  };

  const handleRemoveFututeBulk = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("bulk_update_quantity")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Deleted Successfully");
            Swal.fire("Deleted!", "Subscription has been deleted.", "success");
          });
      }
    });
  };
  const handleCalendarDateChange = (date) => {
    setCalendarDate(date);
    handleDateChange(date);
  };
  // Function to update subscription quantity in Firestore
  const updateSubscriptionQuantityInFirestore = async (
    date,
    subscriptionId,
    newQuantity
  ) => {
    try {
      const snapshot = await db.collection("subscriptions_data").get();
      snapshot.forEach((doc) => {
        const subscriptionPeriod = doc.data().subscription_period;
        if (subscriptionPeriod[date]) {
          subscriptionPeriod[date].forEach((product) => {
            if (product.subscription_id === subscriptionId) {
              product.quantity = newQuantity;
            }
          });
          // Update the document in db
          db.collection("subscriptions_data")
            .doc(doc.id)
            .update({ subscription_period: subscriptionPeriod });
          console.log("Subscription quantity updated successfully.");
        }
      });
    } catch (error) {
      console.error("Error updating subscription quantity:", error);
    }
  };

  const handleQuantityChangeIndex = async (subscriptionId, newQuantity) => {
    try {
      // Fetch the document containing the subscription data from Firestore
      const docRef = db.collection("subscriptions_data").doc(subscriptionId);
      const doc = await docRef.get();

      if (!doc.exists) {
        console.log("Document does not exist");
        return;
      }

      // Extract the subscription data from the document
      const subscriptionData = doc.data();

      // Find the index of the product within the "products" array that matches the subscription ID
      const productIndex = subscriptionData.products.findIndex(
        (product) => product.subscription_id === "38228274"
      );

      if (productIndex !== -1) {
        // Update the quantity of the matching product directly in the Firestore document
        await docRef.update({
          [`products.${productIndex}.quantity`]: newQuantity,
        });

        console.log("Quantity updated successfully");
      } else {
        console.log("Product not found in the document");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  function generateCustomerId() {
    const now = new Date();
    const timestamp = now.getTime(); // Get the timestamp in milliseconds since January 1, 1970
    const random4Digits = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0"); // Generate a random 4-digit number

    // Take the last 4 digits of the timestamp and concatenate with the random 4-digit number
    const customerId =
      (timestamp % 10000).toString().padStart(4, "0") + random4Digits;

    return customerId;
  }

  const changeCart = (name, index) => {
    setUpdateCustomer(name);
    const sId = generateCustomerId();
    setCartItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].subscription_type = name; // Update the type field
      updatedItems[index].subscription_id = sId; // Update the type field
      return updatedItems;
    });
    if (name === "Subscription") {
      setSetActiveCart(name);
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index].subscription_type = "Everyday";
        updatedItems[index].interval = 1;
        updatedItems[index].end_date = "";
        return updatedItems;
      });

      // setCartItems([])
    } else {
      setSetActiveCart(name);
      // setCartItems([])
      setEndDate(startDate);
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
      const day = String(startDate.getDate()).padStart(2, "0"); // Add leading zero if necessary
      const dateString = `${year}-${month}-${day}`;
      const timestamp = new Date(dateString);
      setCartItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[index].end_date = timestamp; // Update the start_date field in the cart item
        updatedItems[index].interval = 0; // Update the start_date field in the cart item
        // updatedItems[index].interval_category = ""; // Update the start_date field in the cart item
        // updatedItems[index].custom = []; // Update the start_date field in the cart item
        updatedItems[index].subscription_type = "One Time"; // Update the start_date field in the cart item
        return updatedItems;
      });
    }
  };

  const resetActivities = () => {
    db.collection("customer_activities")
      .orderBy("created_date", "desc")
      .where("customer_id", "==", params.id)
      .onSnapshot((snapshot) => {
        setActivitiesData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };
  const [editingRowOrd, setEditingRowOrd] = useState(null);
  const [quantityOrd, setQuantityOrd] = useState("");

  const handleEditClickOrd = (index, initialQuantity) => {
    setEditingRowOrd(index);
    setQuantityOrd(initialQuantity);
  };
  const handleSaveClickOrd = (id, qty, price) => {
    if (quantityOrd * price - qty * price > 0) {
      if (quantityOrd * price - qty * price >= data.data?.wallet_balance) {
        alert(
          `insufficient wallet balance need to recharge for  Rs ${
            quantityOrd * price - qty * price
          }`
        );
      } else {
        const deduct = quantityOrd * price - qty * price;
        const amount = parseInt(data.data?.wallet_balance) - parseInt(deduct);
        wallethistory("Debit", deduct);
        // alert(`sufficient wallet balance ${(quantityOrd * price - qty * price)}`)
        db.collection("order_history")
          .doc(id)
          .update({ quantity: parseInt(quantityOrd) });
        db.collection("customers_data")
          .where("customer_id", "==", params.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              db.collection("customers_data")
                .doc(doc.id)
                .update({
                  wallet_balance: parseInt(amount),
                })
                .then(() => {
                  console.log("Wallet balance updated successfully");
                  // Proceed with the rest of your logic here, such as adding wallet history
                })
                .catch((error) => {
                  console.error("Error updating wallet balance:", error);
                });
            });
          });
      }
    } else {
      const refund = quantityOrd * price - qty * price;
      const amount = parseInt(data.data?.wallet_balance) - parseInt(refund);
      wallethistory("Credit", refund);
      // alert(refund)
      // alert(amount)
      // alert(`sufficient wallet balance ${(quantityOrd * price - qty * price)}`)
      db.collection("order_history")
        .doc(id)
        .update({ quantity: parseInt(quantityOrd) });
      db.collection("customers_data")
        .where("customer_id", "==", params.id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.collection("customers_data")
              .doc(doc.id)
              .update({
                wallet_balance: parseInt(amount),
              })
              .then(() => {
                console.log("Wallet balance updated successfully");
                // Proceed with the rest of your logic here, such as adding wallet history
              })
              .catch((error) => {
                console.error("Error updating wallet balance:", error);
              });
          });
        });
    }
    setEditingRowOrd(false);
  };

  const handleSubmitUpdateQuantity = async (event) => {
    event.preventDefault();
    const nextDeliveryDate = new Date(selectedSubscription.next_delivery_date);
    nextDeliveryDate.setHours(0, 0, 0, 0);
    const enteredStartedDate = new Date(startDateBU);
    enteredStartedDate.setHours(0, 0, 0, 0);

    console.log("selected sub nexty del ");
    console.log("start date BU :", startDateBU);
    if (enteredStartedDate < nextDeliveryDate) {
      alert(
        "Subscription next delivery date is greater than the entered dates"
      );
      return;
    }
    bulkUpdateQuantities2(startDateBU, endDateBU, sidBU, newQuantityBU, data);
  };

  const fetchExistingEntries = async (subscription_id, date) => {
    const snapshot = await db
      .collection("bulk_update_quantity")
      .where("delivery_date", "==", moment(date).format("YYYY-MM-DD"))
      .where("subscription_id", "==", subscription_id)
      .get();

    const entries = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return entries;
    //setExistingEntries(entries);
  };

  const bulkUpdateQuantities2 = async (
    startDate,
    endDate,
    sid,
    newQuantity,
    data
  ) => {
    //e.preventDefault();
    if (!startDate || !endDate || !newQuantity) {
      alert("Please fill all fields");
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    const batch = db.batch();

    try {
      for (let i = 0; i <= daysDifference; i++) {
        const currentDate = new Date(start);
        currentDate.setDate(start.getDate() + i);
        const existingEntries = await fetchExistingEntries(sid, currentDate);
        console.log("Existing entries : ", existingEntries);
        const existingEntry = existingEntries.find(
          (entry) =>
            moment(entry.date.toDate()).format("YYYY-MM-DD") ===
            moment(currentDate).format("YYYY-MM-DD")
        );

        if (existingEntry) {
          // Update existing entry
          const docRef = db
            .collection("bulk_update_quantity")
            .doc(existingEntry.id);
          batch.update(docRef, { quantity: parseInt(newQuantity) });
        } else {
          // Add new entry
          const entryData = {
            date: currentDate,
            quantity: parseInt(newQuantity),
            delivery_date: moment(currentDate).format("YYYY-MM-DD"),
            customer_id: data.data && data.data.customer_id, // Access data passed from handleSubmitUpdateQuantity
            status: "1",
            product_name: upProd,
            subscription_id: sid,
            // Add other fields as needed
          };
          const docRef = db.collection("bulk_update_quantity").doc(); // Generate a new document reference
          batch.set(docRef, entryData);
        }
      }

      // Commit the batch write
      await batch.commit();
      db.collection("customer_activities")
        .add({
          customer_phone: data.data && data.data.customer_phone,
          customer_id: data.data && data.data.customer_id,
          customer_name: data.data && data.data.customer_name,
          customer_address: data.data && data.data.customer_name,
          hub_name: data.data && data.data.hub_name,
          delivery_exe_id: data.data && data.data.delivery_exe_id,
          user: "",
          object: "Subscription",
          action: "Changed Quantity in Bulk",
          description: `Quantity for subscription with subscription ID ${sid} has been changed to ${newQuantity} from Date ${moment(
            startDate
          ).format("DD-MM-YYYY")} to ${moment(endDate).format(
            "DD-MM-YYYY"
          )} by ${loggedIn_user}`,
          //'description': description,
          date: new Date(),
          created_date: new Date(),
        })
        .then(() => {
          console.log("added activiteies");
        });

      //alert(`Successfully updated entries in Firebase!`);
      closebulkQt();
    } catch (error) {
      console.error("Error creating entries in the database:", error);
    }
  };

  const resetSearchOrders = () => {
    setStartDate(null);
    setEndDate(null);
    fetchOrderHistory();
  };

  const resetWallet = () => {
    db.collection("wallet_history")
      .orderBy("created_date", "desc")
      .where("customer_id", "==", params.id)
      .onSnapshot((snapshot) => {
        setWalletData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  const handleDateRange = () => {
    const startDateTimestamp = startDate ? startDate.getTime() : 0;
    const endDateTimestamp = endDate ? endDate.getTime() : new Date().getTime();

    db.collection("customer_activities")
      .where("created_date", ">=", new Date(startDateTimestamp))
      .where("created_date", "<=", new Date(endDateTimestamp))
      .where("customer_id", "==", params.id)
      .orderBy("created_date", "desc")
      .onSnapshot((snapshot) => {
        setActivitiesData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  const handleSearchOrders = () => {
    const startDateTimestamp = startDate ? startDate.getTime() : 0;
    const endDateTimestamp = endDate ? endDate.getTime() : new Date().getTime();

    db.collection("order_history")
      .where("delivery_timestamp", ">=", new Date(startDateTimestamp))
      .where("delivery_timestamp", "<=", new Date(endDateTimestamp))
      .where("customer_id", "==", params.id)
      .orderBy("delivery_timestamp", "desc")
      .onSnapshot((snapshot) => {
        setOrderListData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  const handleDateRangeWallet = () => {
    const startDateTimestamp = startDate ? startDate.getTime() : 0;
    const endDateTimestamp = endDate ? endDate.getTime() : new Date().getTime();

    db.collection("wallet_history")
      .where("created_date", ">=", new Date(startDateTimestamp))
      .where("created_date", "<=", new Date(endDateTimestamp))
      .where("customer_id", "==", params.id)
      .orderBy("created_date", "desc")
      .onSnapshot((snapshot) => {
        setWalletData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  useEffect(() => {
    const getCurrentTimeOfDay = () => {
      const currentTime = new Date().getHours();
      if (currentTime < 12) {
        setTimeOfDay("Good Morning");
      } else if (currentTime >= 12 && currentTime < 18) {
        setTimeOfDay("Good Afternoon");
      } else {
        setTimeOfDay("Good Evening");
      }
    };

    getCurrentTimeOfDay();
  }, []);

  useEffect(() => {
    productmap();
    const odId = generateCustomerId();
    setOid(odId);
    const unsubscribe = db
      .collection("customers_data")
      .where("customer_id", "==", params.id)
      .onSnapshot((snapshot) => {
        if (!snapshot.empty) {
          const userDataFromDb = {
            id: snapshot.docs[0].id,
            data: snapshot.docs[0].data(),
          };
          setData(userDataFromDb);

          // Fetch hub details based on the retrieved hub name
          if (userDataFromDb.data && userDataFromDb.data.hub_name) {
            // alert(userDataFromDb.data.delivery_exe_id)
            db.collection("hubs_data")
              .where("hub_name", "==", userDataFromDb.data.hub_name)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  setHubData(doc.data());
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
            const de = [];
            db.collection("hubs_users_data")
              .where("hub_user_id", "==", userDataFromDb.data.delivery_exe_id)
              .get()
              .then((userSnapshot) => {
                userSnapshot.forEach((userDoc) => {
                  de.push(userDoc.data());
                });
                setDeliveryExecutives(de);
              })
              .catch((error) => {
                console.log("Error getting user documents: ", error);
              });

            setUserMapID({}); // Reset the state before fetching new data

            db.collection("hubs_users_data")
              .where("hub_name", "==", userDataFromDb.data.hub_name)
              .onSnapshot((snapshot) => {
                const userMapIDData = snapshot.docs.map((doc) => {
                  const data = doc.data();

                  const { hub_user_id, first_name, last_name, phone_no } = data;

                  const delivery_executive_name = `${first_name} ${last_name}`;
                  return {
                    [hub_user_id]: delivery_executive_name,
                    [hub_user_id + "_phone"]: phone_no,
                  };
                });

                // Set the state with the fetched data
                setUserMapID(Object.assign({}, ...userMapIDData));
              });
          }
        } else {
          // Handle case when no user data is found
          console.log("No user data found");
        }
      });

    // Unsubscribe from the snapshot listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    db.collection("customers_vacation")
      .where("customer_id", "==", params.id)
      .orderBy("updated_date", "desc")
      .onSnapshot((snapshot) => {
        setVacationData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  }, []);

  useEffect(() => {
    console.log("Fetching tickets data...", params.id);
    if (!params.id) {
      console.error("Customer ID is not defined");
      return;
    }

    const unsubscribe = db
      .collection("customers_tickets")
      .where("customer_id", "==", params.id)
      .onSnapshot(
        (snapshot) => {
          setTicketsData(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        },
        (error) => {
          console.error("Error fetching tickets data: ", error);
        }
      );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [params.id]);

  useEffect(() => {
    db.collection("customer_activities")
      .orderBy("created_date", "desc")
      .where("customer_id", "==", params.id)
      .onSnapshot((snapshot) => {
        setActivitiesData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  }, []);

  useEffect(() => {
    async function fetchOrderHistoryCount() {
      try {
        const collRef = collection(db, "order_history");
        const q = query(collRef, where("customer_id", "==", params.id));
        const snapshot = await getCountFromServer(q);
        setOrderHistoryCount(snapshot.data().count);
      } catch (error) {
        console.error("Error fetching order history count: ", error);
      }
    }

    fetchOrderHistoryCount();
  }, [params.id]);
  const fetchOrderHistory = () => {
    db.collection("order_history")
      .where("customer_id", "==", params.id)
      .orderBy("created_date", "desc")
      .onSnapshot((snapshot) => {
        setOrderListData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  };

  useEffect(() => {
    fetchOrderHistory();
  }, [params.id]);

  useEffect(() => {
    const uniqueOrdersMap = new Map();

    orderListData.forEach((order) => {
      const orderID = order.data.order_id;
      if (!uniqueOrdersMap.has(orderID)) {
        uniqueOrdersMap.set(orderID, order);
      }
    });

    const uniqueOrdersArray = Array.from(uniqueOrdersMap.values());

    uniqueOrdersArray.sort((a, b) => {
      const dateA = a.data.created_date.toDate
        ? a.data.created_date.toDate()
        : new Date(a.data.created_date);
      const dateB = b.data.created_date.toDate
        ? b.data.created_date.toDate()
        : new Date(b.data.created_date);
      return dateB - dateA;
    });

    setUniqueOrders(uniqueOrdersArray);
  }, [orderListData]);

  useEffect(() => {
    db.collection("wallet_history")
      .where("customer_id", "==", params.id)
      .orderBy("created_date", "desc")
      .orderBy("current_wallet_balance", "asc")
      .onSnapshot((snapshot) => {
        setWalletData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  }, []);

  useEffect(() => {
    db.collection("delivery_preference")
      .where("customer_id", "==", params.id)
      .onSnapshot((snapshot) => {
        setdelPreData(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  }, []);

  const openWallet = () => {
    // alert("ooo")
    window.customerWalletopen();
  };

  const closeWallet = () => {
    window.customerWalletclose();
  };

  const openCredit = () => {
    // alert("ooo")
    window.customerCredittopen();
  };

  const closeCredit = () => {
    window.customerCreditclose();
  };

  const openCreditFromCheckout = () => {
    // alert("ooo")
    window.customerCredittopenFromCheckout();
  };

  const closeCreditFromCheckout = () => {
    window.customerCreditcloseFromCheckout();
    setShowCreditButton(false);
  };

  const openDF = () => {
    if (delPreData && delPreData.length > 0) {
      setSubmitDF({
        delivery_mode: delPreData[0].data.delivery_mode,
        additional_instruction: delPreData[0].data.additional_instruction,
      });
      setSelectedDays(delPreData[0].data.selected_days);
      setDfID(delPreData[0].id);
      window.customerDFopen();
    } else {
      // Handle case when delPreData is empty or undefined
      window.customerDFopen();
      console.error("No delivery data found.");
      // You can also show a message to the user indicating that no data is available.
      // alert("No delivery data found.");
    }
  };

  const closeDF = () => {
    window.customerDFclose();
  };

  const openCash = () => {
    // alert("ooo")
    window.customerCashopen();
  };

  const closeCash = () => {
    window.customerCashclose();
  };

  const openSRL = () => {
    // alert("ooo")
    window.customerSRLopen();
  };

  const closeSRL = () => {
    window.customerSRLclose();
  };

  const openVacation = () => {
    // alert("ooo")
    window.customerVacationopen();
  };

  const closeVacation = () => {
    window.customerVacationclose();
  };

  const openTickets = () => {
    // alert("ooo")
    window.customerTicketsopen();
  };

  const closeTickets = () => {
    window.customerTicketsclose();
  };

  const openSubscription = () => {
    // alert("ooo")
    setStartDate(addDays(new Date(), 1));
    setShowModal("addSubscription");
    window.customerSubscriptionopen();
  };

  const openAdhocOrder = () => {
    // alert("ooo")
    setShowModal("adhocOrder");
    window.customerSubscriptionopen();
  };

  const closeSubscription = () => {
    window.customerSubscriptionclose();
  };

  const openInterval = () => {
    // alert("ooo")
    window.customerIntervalopen();
  };

  const closeInterval = () => {
    window.customerIntervalclose();
  };

  const openCalendar = () => {
    // handleFutureBulk();
    setEditingRow(null);
    handleDateChange(getTomorrowDate());

    resetCalendar();
    // alert("ooo")
    // console.log(startCalendarDate)
    // console.log("ppppppppppppppp")
    window.customerCalendarlopen();
  };

  const closeCalendar = () => {
    window.customerCalendarlclose();
  };

  const openmdlv = (oid, st) => {
    setOiid(oid);
    setOiidStatus(st);
    window.mdlvopen();
  };

  const closemdlv = () => {
    window.mdlvclose();
  };

  const openPauseSubDialog = () => {
    window.pausesubdialogopen();
  };

  const closePauseSubDialog = () => {
    window.pausesubdialogclose();
  };

  const addVacation = () => {
    setEdit(false);
    openVacation();
    setSubmitVacation({
      start_date: "",
      end_date: "",
      source: "",
      status: "1",
      created_date: new Date(),
      updated_date: new Date(),
    });
  };

  const editVacation = (data, id) => {
    setEdit(true);
    setEditID(id);
    setSubmitVacation({
      start_date: moment(data.start_date.toDate().toISOString()).format(
        "YYYY-MM-DD"
      ),
      end_date: moment(data.end_date.toDate().toISOString()).format(
        "YYYY-MM-DD"
      ),
      source: data.source,
      status: "1",
      updated_date: new Date(),
    });
    openVacation();
  };

  const addTickets = () => {
    setEdit(false);
    openTickets();
    setSubmitTickets({
      category: "",
      status: "",
      priority: "",
      due_date: "",
      owner: "",
      description: "",
      visible_on: "",
      status: "1",
      created_date: new Date(),
      updated_date: new Date(),
    });
  };

  const editTickets = (data, id) => {
    setEdit(true);
    setEditID(id);
    setSubmitTickets({
      category: data.category,
      status: data.status,
      priority: data.priority,
      due_date: moment(data.due_date.toDate().toISOString()).format(
        "YYYY-MM-DD"
      ),
      owner: data.owner,
      description: data.description,
      visible_on: data.visible_on,
      data_status: "1",
      created_date: new Date(),
      updated_date: new Date(),
      ticket_id: data.ticket_id,
    });
    setOldTicketData(data);
    openTickets();
  };

  const initialFormEditSubs = {
    end_date: "",
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    interval: 0,
    next_delivery_date: "",
    product_name: "",
    quantity: 0,
    subscription_type: "",
    price: 0,
    status: "",
    updated_date: new Date(),
  };
  const [editSubs, setEditSubs] = useState(initialFormEditSubs);
  const [idES, setIdES] = useState("");
  const opensedit = (subs, id) => {
    setEditSubs({
      subscription_id: subs.subscription_id,
      end_date: subs.end_date,
      monday: subs.monday,
      tuesday: subs.tuesday,
      wednesday: subs.wednesday,
      thursday: subs.thursday,
      friday: subs.friday,
      saturday: subs.saturday,
      sunday: subs.sunday,
      interval: subs.interval,
      next_delivery_date: subs.next_delivery_date,
      product_name: subs.product_name,
      quantity: subs.quantity,
      subscription_type: subs.subscription_type,
      price: subs.price,
      status: subs.status,
      updated_date: new Date(),
    });
    // console.log(subs)
    setIdES(id);
    window.seditopen();
  };

  const closesedit = () => {
    window.seditclose();
  };

  const handleChangeES = (e) => {
    const { name, value } = e.target;
    setEditSubs((prevState) => ({ ...prevState, [name]: value }));
  };

  const [nthES, setNthES] = useState(false);
  const handleIntervalChangeES = (interval) => {
    if (interval === "nth") {
      setNthES(true);
      setEditSubs((prevState) => ({ ...prevState, interval }));
    } else {
      setNthES(false);
      setEditSubs((prevState) => ({ ...prevState, interval }));
    }
  };

  const handleQuantityChangeES = (delta) => {
    setEditSubs((prevState) => ({
      ...prevState,
      quantity: Math.max(0, prevState.quantity + delta),
    }));
  };

  const handleNthDayChangeES = (e) => {
    const value = e.target.value;
    setEditSubs((prevState) => ({ ...prevState, interval: value }));
  };

  /** subscripation all  activity records here */
  const handleSubmitES = async (e) => {
    e.preventDefault();

    console.log("editSubs:", editSubs); // Log editSubs to see all fields
    console.log("Subscription ID:", idES); // Log the document ID

    const nextDeliveryDates = new Date();
    console.log('date---------',nextDeliveryDates);
    
    if (editSubs.subscription_type !== "Custom") {
      nextDeliveryDates.setDate(
        nextDeliveryDates.getDate() +
          (editSubs.subscription_type === "Everyday"
            ? 1
            : parseInt(editSubs.interval))
      );
    }

    try {
      // Fetch current subscription data
      const doc = await db.collection("subscriptions_data").doc(idES).get();

      if (!doc.exists) {
        throw new Error(`Subscription with ID ${idES} does not exist.`);
      }

      const currentData = doc.data();
      console.log("Current subscription data:", currentData);

      const initialQuantity = currentData?.quantity;
      const initialSubscriptionType = currentData?.subscription_type;
      const initialInterval = currentData?.interval;

      // Set default values for fields that might be undefined
      const updatedData = {
        monday: parseInt(editSubs.monday) || 0,
        tuesday: parseInt(editSubs.tuesday) || 0,
        wednesday: parseInt(editSubs.wednesday) || 0,
        thursday: parseInt(editSubs.thursday) || 0,
        friday: parseInt(editSubs.friday) || 0,
        saturday: parseInt(editSubs.saturday) || 0,
        sunday: parseInt(editSubs.sunday) || 0,
        interval:
          editSubs.subscription_type === "Everyday"
            ? 1
            : editSubs.subscription_type === "On-Interval"
            ? parseInt(editSubs.interval) || 0
            : 0,
        next_delivery_date:
          editSubs.subscription_type === "Custom"
            ? editSubs.next_delivery_date || ""
            : moment(nextDeliveryDates).format("YYYY-MM-DD"),
        product_name: editSubs.product_name || "",
        quantity: parseInt(editSubs.quantity) || 0,
        subscription_type: editSubs.subscription_type || "",
        package_unit: editSubs.package_unit || "", // Correct field name
        price: parseInt(editSubs.price) || 0,
        status: editSubs.status || "",
        updated_date: new Date(),
      };

      console.log("Updated data to be saved:", updatedData);

      await db.collection("subscriptions_data").doc(idES).update(updatedData);

      closesedit();

      // Log for successful update
      console.log("Subscription updated successfully");

      // Ensure data object has the necessary fields
      if (!data || !data.data) {
        throw new Error("Data object is not defined properly.");
      }

      // Check if the quantity has changed
      if (initialQuantity !== parseInt(editSubs.quantity)) {
        await db.collection("customer_activities").add({
          customer_phone: data.data?.customer_phone ?? "N/A",
          customer_id: data.data?.customer_id ?? "N/A",
          customer_name: data.data?.customer_name ?? "N/A",
          customer_address: data.data?.customer_address ?? "N/A",
          hub_name: data.data?.hub_name ?? "N/A",
          delivery_exe_id: data.data?.delivery_exe_id ?? "N/A",
          user: "Server",
          object: "Edit Subscription",
          action: "Edit Subscription",
          description: `Subscription ID: ${
            editSubs?.subscription_id ?? "N/A"
          } for ${editSubs?.product_name ?? "N/A"} ${
            editSubs?.package_unit ?? "N/A"
          } has been edited through CRM by ${
            loggedIn_user ?? "Unknown"
          }. Quantity changed from ${initialQuantity ?? "N/A"} to ${
            editSubs.quantity
          }.`,
          date: new Date(),
          created_date: new Date(),
        });
        console.log("Added activity for quantity change");
      }

      // Check if the subscription type has changed
      if (initialSubscriptionType !== editSubs.subscription_type) {
        let subscriptionChangeDescription = `Subscription ID: ${
          editSubs?.subscription_id ?? "N/A"
        } for ${editSubs?.product_name ?? "N/A"} ${
          editSubs?.package_unit ?? "N/A"
        } has been edited through CRM by ${
          loggedIn_user ?? "Unknown"
        }. Subscription type changed from ${
          initialSubscriptionType ?? "N/A"
        } to ${editSubs.subscription_type}.`;
        if (editSubs.subscription_type === "On-Interval") {
          subscriptionChangeDescription += ` Interval: ${editSubs.interval} days.`;
        } else if (editSubs.subscription_type === "Custom") {
          const days = [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday",
          ];
          const customQuantities = days
            .map((day) => {
              const quantity = editSubs[day.toLowerCase()];
              return quantity > 0 ? `${day}: ${quantity}` : null;
            })
            .filter(Boolean)
            .join(", ");
          subscriptionChangeDescription += ` Custom Quantities - ${customQuantities}.`;
        }
        await db.collection("customer_activities").add({
          customer_phone: data.data?.customer_phone ?? "N/A",
          customer_id: data.data?.customer_id ?? "N/A",
          customer_name: data.data?.customer_name ?? "N/A",
          customer_address: data.data?.customer_address ?? "N/A",
          hub_name: data.data?.hub_name ?? "N/A",
          delivery_exe_id: data.data?.delivery_exe_id ?? "N/A",
          user: "Server",
          object: "Edit Subscription",
          action: "Edit Subscription",
          description: subscriptionChangeDescription,
          date: new Date(),
          created_date: new Date(),
        });
        console.log("Added activity for subscription type change");
      }

      const Toast = Swal.mixin({
        toast: true,
        background: "#69aba6",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Subscription Updated Successfully",
      });
      // Proceed with the rest of your logic here, such as adding wallet history
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        background: "#69aba6",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "failure",
        title: "Error Occurred while updating Subscription",
      });
      console.error("Error updating subscription:", error);
    }
  };

  /**subscripation all  activity records  end */
  const reason = [
    { name: "Cash Deposit", action: "plus" },
    { name: "Recharge", action: "plus" },
    { name: "Bank Transfer", action: "plus" },
    { name: "Cheque Payment", action: "plus" },
    { name: "Via Payment Gateway", action: "plus" },
    { name: "Refund", action: "plus" },
    { name: "Adjustment (Positive)", action: "plus" },
    { name: "Adjustment (Negative)", action: "minus" },
    { name: "Penalty (Cheque Bounce)", action: "minus" },
    { name: "Penalty (Late Payment)", action: "minus" },
    { name: "Penalty (Bottle Charges)", action: "minus" },
    { name: "Last Cycle Payment", action: "minus" },
    { name: "Recharge Bonus", action: "plus" },
    { name: "Registration Bonus", action: "plus" },
    { name: "Referral Bonus", action: "plus" },
    { name: "Cashback", action: "plus" },
    { name: "Cashback Reversal", action: "minus" },
    { name: "Influencer Campaign", action: "plus" },
    { name: "Farm visit", action: "plus" },
  ];

  const del_mo = [
    "Ring Door Bell",
    "Drop at the Door",
    "In Hand Delivery",
    "Keep in Bag",
    "No Preference",
  ];

  const handleChange = (e) => {
    // console.log(e.target.value)
    const { id, value } = e.target;
    setSubmit({ ...submit, [id]: value });
  };

  const handleChangeCredit = (e) => {
    const { id, value } = e.target;
    // For the checkbox, update the isChecked state based on the checked attribute
    if (id === "send_notification") {
      setSubmitCredit({ ...submitCredit, [id]: e.target.checked });
    } else {
      setSubmitCredit({ ...submitCredit, [id]: value });
    }
  };

  const handleChangeDF = (e) => {
    const { id, value } = e.target;
    setSubmitDF({ ...submitDF, [id]: value });
  };

  const handleChangeCash = (e) => {
    const { id, value } = e.target;
    setSubmitCash({ ...submitCash, [id]: value });
  };

  const handleChangeSRL = (e) => {
    const { id, value, type, checked } = e.target;
    setSubmitSRL((prevState) => ({
      ...prevState,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmitSRL = async (e) => {
    e.preventDefault();
    const buttonType = e.nativeEvent.submitter.getAttribute("data-type");

    if (buttonType != "copy") {
      // Validation check for email or SMS checkbox
      if (!submitSRL.email && !submitSRL.sms) {
        alert("Please check either Email or SMS.");
        return;
      }
    }
    const dataRL = {
      amount: submitSRL.amount,
      customer_id: data.data && data.data.customer_id,
      email: data.data && data.data.customer_email,
      name: data.data && data.data.customer_name,
      phone: data.data && data.data.customer_phone,
      created_date: new Date(),
    };

    try {
      // Save data to the link collection
      const docRef = await db.collection("recharge_link").add(dataRL);

      if (buttonType === "copy") {
        // Copy the document ID to clipboard
        navigator.clipboard
          .writeText(`https://www.whytefarms.com/rechargelink/${docRef.id}`)
          .then(() => {
            closeSRL();
          })
          .catch((err) => {
            console.error("Error copying to clipboard: ", err);
          });
      } else if (buttonType === "send") {
        // alert('Document ID: ' + docRef.id);
        const myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=gjhn17aj59uck1uj3jgfan2b60");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        // fetch(`https://api.textlocal.in/send/?apikey=SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH&sender=WHYTEF&numbers=91${data.data.customer_phone}&message=Dear Customer, Please click on the link to recharge your account. Link: https://www.whytefarms.com/rechargelink/${docRef.id} - Whyte Farms`, requestOptions)
        //     .then((response) => response.text())
        //     .then((result) => console.log(result))
        //     .catch((error) => console.error(error));

        closeSRL();
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleChangeTickets = (e) => {
    const { id, value } = e.target;
    setSubmitTickets({ ...submitTickets, [id]: value });
  };

  const [selectedDays, setSelectedDays] = useState([]);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleDayChange = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const wallethistoryAdhoc = (action, amount, cwb, reason) => {
    const txn = generateCustomerId();

    db.collection("wallet_history")
      .add({
        txn_id: txn,
        amount: amount,
        description: "Re-Order",
        reason: reason,
        customer_phone: data.data && data.data.customer_phone,
        customer_id: data.data && data.data.customer_id,
        customer_name: data.data && data.data.customer_name,
        hub_name: hubData.hub_name,
        delivery_executive: `${deliveryExecutives[0].first_name} ${deliveryExecutives[0].last_name}`,
        current_wallet_balance: cwb,
        status: "1",
        type: action,
        source: "Backend",
        user: loggedIn_user,
        created_date: new Date(),
      })
      .then(() => {
        console.log("Created Successfully");
      })
      .catch((error) => {
        console.error("Error updating:", error);
      });
  };

  const wallethistory = (action, amount) => {
    const txn = generateCustomerId();

    db.collection("wallet_history")
      .add({
        txn_id: txn,
        amount: amount,
        description: submit.description,
        reason: submit.reason,
        customer_phone: data.data && data.data.customer_phone,
        customer_id: data.data && data.data.customer_id,
        customer_name: data.data && data.data.customer_name,
        hub_name: hubData.hub_name,
        delivery_executive: `${deliveryExecutives[0].first_name} ${deliveryExecutives[0].last_name}`,
        current_wallet_balance: data.data?.wallet_balance,
        status: "1",
        type: action,
        source: "Backend",
        user: loggedIn_user,
        created_date: new Date(),
      })
      .then(() => {
        console.log("Created Successfully");
      })
      .catch((error) => {
        console.error("Error updating:", error);
      });
  };

  const wallethistoryRefund = (action, amount, cba, reason) => {
    const txn = generateCustomerId();

    db.collection("wallet_history")
      .add({
        txn_id: txn,
        amount: amount,
        description: "Cancelled Order",
        reason: reason,
        customer_phone: data.data && data.data.customer_phone,
        customer_id: data.data && data.data.customer_id,
        customer_name: data.data && data.data.customer_name,
        hub_name: hubData.hub_name,
        delivery_executive: `${deliveryExecutives[0].first_name} ${deliveryExecutives[0].last_name}`,
        current_wallet_balance: parseInt(cba),
        status: "1",
        type: action,
        source: "Backend",
        user: loggedIn_user,
        created_date: new Date(),
      })
      .then(() => {
        console.log("Created Successfully");
      })
      .catch((error) => {
        console.error("Error updating:", error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Disable button

    const selectedReason = reason.find((item) => item.name === submit.reason);
    const txn = generateCustomerId();

    if (selectedReason && selectedReason.action) {
      const { action } = selectedReason;
      const amount = parseFloat(submit.amount);

      try {
        if (action === "plus") {
          // Add amount to wallet_balance
          const newWalletBalance = data.data?.wallet_balance + amount;
          await db.collection("wallet_history").add({
            txn_id: txn,
            amount: submit.amount,
            description: submit.description,
            reason: submit.reason,
            customer_phone: data.data?.customer_phone,
            customer_id: data.data?.customer_id,
            customer_name: data.data?.customer_name,
            hub_name: hubData.hub_name,
            delivery_executive: `${deliveryExecutives[0].first_name} ${deliveryExecutives[0].last_name}`,
            current_wallet_balance: newWalletBalance,
            status: "1",
            type: "Credit",
            user: loggedIn_user,
            source: "Backend",
            created_date: new Date(),
          });

          // const response = await fetch(
          //   `https://api.textlocal.in/send/?apikey=SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH&sender=WHYTEF&numbers=91${data.data.customer_phone}&message=Dear Customer, The amount of Rs. ${submit.amount} has been credited. Updated Bal: Rs. ${newWalletBalance} - Whyte Farms`
          // );
          // const result = await response.text();
          // console.log(result);

          await db
            .collection("customers_data")
            .where("customer_id", "==", params.id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(async (doc) => {
                await db
                  .collection("customers_data")
                  .doc(doc.id)
                  .update({
                    wallet_balance: newWalletBalance,
                    credit_limit: parseInt(0),
                  });
              });
            });

          await db.collection("customer_activities").add({
            description: `${amount} is added to Wallet balance by ${loggedIn_user}`,
            customer_phone: data.data?.customer_phone,
            customer_id: data.data?.customer_id,
            customer_name: data.data?.customer_name,
            created_date: new Date(),
            object: "Payment Successful",
            action: "Payment",
            user: loggedIn_user,
          });

          Swal.fire({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: "Created Successfully",
          });
        } else if (action === "minus") {
          // Subtract amount from wallet_balance
          const newWalletBalance = data.data?.wallet_balance - amount;
          await db.collection("wallet_history").add({
            txn_id: txn,
            amount: submit.amount,
            description: submit.description,
            reason: submit.reason,
            customer_phone: data.data?.customer_phone,
            customer_id: data.data?.customer_id,
            customer_name: data.data?.customer_name,
            hub_name: hubData.hub_name,
            delivery_executive: `${deliveryExecutives[0].first_name} ${deliveryExecutives[0].last_name}`,
            current_wallet_balance: newWalletBalance,
            status: "1",
            type: "Debit",
            source: "Backend",
            user: loggedIn_user,
            created_date: new Date(),
          });

          await db
            .collection("customers_data")
            .where("customer_id", "==", params.id)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach(async (doc) => {
                await db.collection("customers_data").doc(doc.id).update({
                  wallet_balance: newWalletBalance,
                });
              });
            });

          Swal.fire({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: "Created Successfully",
          });
        } else {
          console.error("Invalid action:", action);
        }
      } catch (error) {
        console.error("Error updating:", error);
      } finally {
        setLoading(false); // Re-enable the button
        setSubmit({
          amount: "",
          description: "",
          reason: "",
          status: "",
          created_date: new Date(),
        });
        subscriptionReasonUpdate();
        closeWallet();
      }
    } else {
      console.error("Invalid reason:", submit.reason);
      setLoading(false); // Re-enable the button
    }
  };

  const [oiid, setOiid] = useState("");
  const [oiidStatus, setOiidStatus] = useState("");
  const [submitCR, setSubmitCR] = useState({ reason: "" });

  const handleChangeCR = (e) => {
    const { id, value } = e.target;
    setSubmitCR((prevState) => ({ ...prevState, [id]: value }));
  };

  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, "0");
    const minutes = currentTime.getMinutes().toString().padStart(2, "0");
    const seconds = currentTime.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  const getCalendarDate = () => {
    const today = new Date();
    const currentHour = today.getHours();
    const currentMinute = today.getMinutes();
    if (currentHour >= 23 && currentMinute <= 59) {
      setAfterEleven(true);
    } else {
      setAfterEleven(false);
    }
  };

  const handleSubmitMarkDelivered = async (e) => {
    e.preventDefault();
    //const totalAmount = calculateTotalAmount(oiid);
    const { utilised_wallet_balance, utilised_credit_limit } =
      await extractUtilisedBalances(oiid);
    let walletBalance = 0;
    let creditLimit = 0;

    if (utilised_credit_limit > 0 && data.data?.wallet_balance < 0) {
      walletBalance =
        data.data?.wallet_balance +
        utilised_wallet_balance +
        utilised_credit_limit;
      creditLimit = data.data?.credit_limit + utilised_credit_limit;
    } else {
      walletBalance = data.data?.wallet_balance + utilised_wallet_balance;
      creditLimit = data.data?.credit_limit + utilised_credit_limit;
    }
    if (oiidStatus === "1") {
      try {
        // Query documents with matching order_id
        const querySnapshot = await db
          .collection("order_history")
          .where("order_id", "==", oiid)
          .get();

        // Update status for each matching document
        querySnapshot.forEach(async (doc) => {
          await doc.ref.update({
            status: "1",
            delivery_time: getCurrentTime(),
          });
        });
        db.collection("customer_activities")
          .add({
            customer_phone: data.data && data.data.customer_phone,
            customer_id: data.data && data.data.customer_id,
            customer_name: data.data && data.data.customer_name,
            customer_address: data.data && data.data.customer_name,
            hub_name: data.data && data.data.hub_name,
            delivery_exe_id: data.data && data.data.delivery_exe_id,
            user: loggedIn_user,
            object: "Mark Delivery",
            action: "Delivery",
            description: `Order Id: ${oiid} Delivery on ${moment(
              new Date()
            ).format("DD-MM-YYYY")} by ${loggedIn_user}`,
            //'description': description,
            date: new Date(),
            created_date: new Date(),
          })
          .then(() => {
            console.log("added activiteies");
          });

        const Toast = Swal.mixin({
          toast: true,
          background: "#69aba6",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "Updated",
          title: "Updated Successfully",
        });
        closemdlv();

        // Handle success
        console.log("Order status updated successfully");
      } catch (error) {
        // Handle error
        console.error("Error updating order status:", error);
      }
    } else {
      try {
        wallethistoryRefund(
          "Credit",
          utilised_wallet_balance + utilised_credit_limit,
          walletBalance,
          submitCR.reason
        );
        // Query documents with matching order_id
        const querySnapshot = await db
          .collection("order_history")
          .where("order_id", "==", oiid)
          .get();

        // Update status for each matching document
        querySnapshot.forEach(async (doc) => {
          await doc.ref.update({
            status: "2",
            cancelled_reason: submitCR.reason,
            cancelled_time: getCurrentTime(),
          });
        });
        //const amount = parseInt(data.data?.wallet_balance) + parseInt(totalAmount)
        db.collection("customers_data")
          .where("customer_id", "==", params.id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              db.collection("customers_data")
                .doc(doc.id)
                .update({
                  wallet_balance: parseInt(walletBalance),
                  credit_limit: parseInt(creditLimit),
                })
                .then(() => {
                  console.log("Wallet balance updated successfully");
                  // Proceed with the rest of your logic here, such as adding wallet history
                })
                .catch((error) => {
                  console.error("Error updating wallet balance:", error);
                });
            });
          });
        db.collection("customer_activities")
          .add({
            customer_phone: data.data && data.data.customer_phone,
            customer_id: data.data && data.data.customer_id,
            customer_name: data.data && data.data.customer_name,
            customer_address: data.data && data.data.customer_name,
            hub_name: data.data && data.data.hub_name,
            delivery_exe_id: data.data && data.data.delivery_exe_id,
            user: loggedIn_user,
            object: "Order",
            action: "Cancel",
            description: `Your Order Id: ${oiid} had been cancelled by ${loggedIn_user} with Reason ${submitCR.reason}`,
            //'description': description,
            date: new Date(),
            created_date: new Date(),
          })
          .then(() => {
            console.log("added activiteies");
          });
        const Toast = Swal.mixin({
          toast: true,
          background: "#69aba6",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "Updated",
          title: "Updated Successfully",
        });
        closemdlv();

        // Handle success
        console.log("Order status updated successfully");
      } catch (error) {
        // Handle error
        console.error("Error updating order status:", error);
      }
    }
  };

  const [filter, setFilter] = useState("all");

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = walletData?.filter(({ data }) => {
    if (filter === "all") return true;
    return data.type.toLowerCase() === filter.toLowerCase();
  });
  const [loadingCredit, setLoadingCredit] = useState(false);

  const handleSubmitCredit = async (e) => {
    e.preventDefault();
    setLoadingCredit(true); // Disable button
    const creditLimit = parseInt(submitCredit.credit_limit); // Convert to integer
    try {
      await db.collection("credit_limit_history").add({
        txn_id: generateCustomerId(),
        credit_limit: creditLimit,
        send_notification: submitCredit.send_notification,
        customer_phone: data.data?.customer_phone,
        customer_id: data.data?.customer_id,
        customer_name: data.data?.customer_name,
        status: "1",
        user: "Admin",
        created_date: new Date(),
        credit_date: moment(today).format("YYYY-MM-DD")
      });

      const querySnapshot = await db
        .collection("customers_data")
        .where("customer_id", "==", params.id)
        .get();
      querySnapshot.forEach(async (doc) => {
        const currentCreditBalance = doc.data().credit_limit || 0; // Ensure it's a number, defaulting to 0 if it's not set
        const newCreditBalance = creditLimit; // Use the converted creditLimit value

        await db.collection("customers_data").doc(doc.id).update({
          credit_limit: newCreditBalance,
        });

        await db.collection("customer_activities").add({
          customer_phone: data.data?.customer_phone,
          customer_id: data.data?.customer_id,
          customer_name: data.data?.customer_name,
          customer_address: data.data?.customer_address, // Corrected to `customer_address`
          hub_name: data.data?.hub_name,
          delivery_exe_id: data.data?.delivery_exe_id,
          user: loggedIn_user,
          object: "Customer",
          action: "Change Credit Limit",
          description: `Credit amount changed from ${currentCreditBalance} to Rs ${creditLimit} through CRM by ${loggedIn_user}`,
          date: new Date(),
          created_date: new Date(),
        });
      });

      const Toast = Swal.mixin({
        toast: true,
        background: "#69aba6",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Credit Updated Successfully",
      });

      closeCredit();
      closeCreditFromCheckout();
      setSubmitCredit({
        credit_limit: "",
        send_notification: false,
        status: "",
        created_date: new Date(),
      });
    } catch (error) {
      console.error("Error updating:", error);
    } finally {
      setLoadingCredit(false); // Re-enable the button
    }
  };

  const handleSubmitDF = (e) => {
    e.preventDefault();
    if (delPreData.length === 1) {
      setLoading(true);
      db.collection("delivery_preference")
        .doc(dfID)
        .update({
          delivery_mode: submitDF.delivery_mode,
          additional_instruction: submitDF.additional_instruction,
          selected_days: selectedDays,
          status: "1",
          customer_phone: data.data && data.data.customer_phone,
          customer_id: data.data && data.data.customer_id,
          customer_name: data.data && data.data.customer_name,
          updated_date: new Date(),
        })
        .then(() => {
          console.log("Created Successfully");
          const Toast = Swal.mixin({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Updated Successfully",
          });
          closeDF();
          setLoading(false);
          setLoading(true);
          setSelectedDays([]);
          setSubmitDF({
            delivery_mode: "",
            time: "",
            additional_instruction: "",
            status: "",
            created_date: new Date(),
          });
        })
        .catch((error) => {
          console.error("Error updating :", error);
        });
    } else {
      setLoading(true);
      db.collection("delivery_preference")
        .add({
          delivery_mode: submitDF.delivery_mode,
          additional_instruction: submitDF.additional_instruction,
          selected_days: selectedDays,
          status: "1",
          customer_phone: data.data && data.data.customer_phone,
          customer_id: data.data && data.data.customer_id,
          customer_name: data.data && data.data.customer_name,
          created_date: new Date(),
          updated_date: new Date(),
        })
        .then(() => {
          console.log("Created Successfully");
          const Toast = Swal.mixin({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Created Successfully",
          });
          closeDF();
          setLoading(false);
          setLoading(true);
          setSelectedDays([]);
          setSubmitDF({
            delivery_mode: "",
            time: "",
            additional_instruction: "",
            status: "",
            created_date: new Date(),
          });
        })
        .catch((error) => {
          console.error("Error updating :", error);
        });
    }
  };

  /** add cash collection start*/

  const handleSubmitCash = async (e) => {
    e.preventDefault();

    if (deliveryExecutives[0] === undefined) {
      alert("No Delivery Executive Found");
      return;
    }
    setLoading(true); // Disable button

    try {
      // Add cash collection document
      await db.collection("cash_collection").add({
        amount: parseInt(submitCash.amount),
        collected_amount: 0,
        created_date: new Date(),
        customer_id: data.data?.customer_id,
        customer_name: data.data?.customer_name,
        customer_phone: data.data?.customer_phone,
        customer_address: data.data?.customer_address,
        date: submitCash.date,
        delivery_exe_id: `${deliveryExecutives[0].hub_user_id}`,
        delivery_executive_name: `${deliveryExecutives[0].first_name} ${deliveryExecutives[0].last_name}`,
        delivery_executive_phone: deliveryExecutives[0].phone_no,
        description: "",
        status: "0",
        time: submitCash.time,
        updated_date: new Date(),
      });

      console.log("Created Successfully");

      const Toast = Swal.mixin({
        toast: true,
        background: "#69aba6",
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Created Successfully",
      });

      // Record activity
      await db.collection("customer_activities").add({
        customer_phone: data.data?.customer_phone,
        customer_id: data.data?.customer_id,
        customer_name: data.data?.customer_name,
        customer_address: data.data?.customer_address,
        hub_name: data.data?.hub_name,
        delivery_exe_id: deliveryExecutives[0].hub_user_id,
        user: loggedIn_user,
        object: "Cash collection request",
        action: "Cash collection request",
        description: `Cash collection request of Amount ${submitCash.amount} has been raised by ${loggedIn_user} for ${submitCash.date}`,
        date: new Date(),
        created_date: new Date(),
      });

      // Reset form state
      closeCash();
      setSubmitCash({
        amount: "",
        date: "",
        time: "",
        status: "",
        created_date: new Date(),
      });
    } catch (error) {
      console.error("Error updating:", error);
    } finally {
      setLoading(false); // Re-enable the button
    }
  };

  const [originalStartDate, setOriginalStartDate] = useState("");
  const [originalEndDate, setOriginalEndDate] = useState("");
  const [minEndDate, setMinEndDate] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const currentYearEnd = `${new Date().getFullYear()}-12-31`;
  useEffect(() => {
    if (edit) {
      setOriginalStartDate(submitVacation.start_date);
      setOriginalEndDate(submitVacation.end_date);
    }
  }, [edit]);
  const handleChangeVacation = (e) => {
    const { id, value } = e.target;
  
    if (id === "start_date") {
      // Set the minimum end date to the selected start date
      setMinEndDate(value);
    }
  
    // Update the state with the new value
    setSubmitVacation({
      ...submitVacation,
      [id]: value,
      start_date: id === "start_date" ? value : submitVacation.start_date,
    });
  };
  

  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitVacation = (e) => {
    e.preventDefault();

    const today = new Date().setHours(0, 0, 0, 0); // Current date without time
    const startDate = new Date(submitVacation.start_date).setHours(0, 0, 0, 0);

    // Check if start date is the current date
    if (startDate <= today) {
      alert("Start date must be a future date.");
      setIsSubmitting(false); // Re-enable the button
      return;
    }

    if (edit) {
      if (
        originalStartDate === submitVacation.start_date &&
        originalEndDate === submitVacation.end_date
      ) {
        alert("Please update the future date for vacations.");
        setIsSubmitting(false); // Re-enable the button
        return;
      }
    }

    // Ensure end date is within the current year
    if (new Date(submitVacation.end_date) > new Date(currentYearEnd)) {
      alert("End date must be within the current year.");
      setIsSubmitting(false); // Re-enable the button
      return;
    }

    // Start the submission process
    setIsSubmitting(true);
    setLoading(true);

    db.collection("customers_vacation")
      .where("customer_id", "==", params.id)
      .get()
      .then((querySnapshot) => {
        const existingVacations = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        // Check for overlap with existing vacations
        const overlap = existingVacations.some((vacation) => {
          if (edit && vacation.id === editID) {
            return false;
          }
          const vacationStartDate = vacation.start_date.toDate();
          const vacationEndDate = vacation.end_date.toDate();
          return (
            (new Date(submitVacation.start_date) >= vacationStartDate &&
              new Date(submitVacation.start_date) <= vacationEndDate) ||
            (new Date(submitVacation.end_date) >= vacationStartDate &&
              new Date(submitVacation.end_date) <= vacationEndDate) ||
            (new Date(submitVacation.start_date) <= vacationStartDate &&
              new Date(submitVacation.end_date) >= vacationEndDate)
          );
        });

        if (overlap) {
          alert("New vacation overlaps with an existing vacation!");
          setIsSubmitting(false); // Re-enable the button
          setLoading(false);
          return;
        }

        // If no overlap, continue with your submission logic
        if (edit) {
          db.collection("customers_vacation")
            .doc(editID)
            .update({
              start_date: new Date(submitVacation.start_date),
              end_date: new Date(submitVacation.end_date),
              source: "backend",
              status: "1",
              updated_date: new Date(),
              updated_by:loggedIn_user,
            })
            .then(() => {
              console.log("Updated Successfully");
              db.collection("customer_activities")
                .add({
                  customer_phone: data.data && data.data.customer_phone,
                  customer_id: data.data && data.data.customer_id,
                  customer_name: data.data && data.data.customer_name,
                  customer_address: data.data && data.data.customer_name,
                  hub_name: data.data && data.data.hub_name,
                  delivery_exe_id: data.data && data.data.delivery_exe_id,
                  user: loggedIn_user,
                  object: "update vacation",
                  action: "update vacation",
                  description: `Vacation has been updated through CRM with start date ${submitVacation.start_date} and end date ${submitVacation.end_date} by ${loggedIn_user}`,
                  date: new Date(),
                  created_date: new Date(),
                })
                .then(() => {
                  console.log("Activities added");
                });

              const Toast = Swal.mixin({
                toast: true,
                background: "#69aba6",
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "success",
                title: "Vacation Updated Successfully",
              });

              closeVacation();
              setSubmitVacation({
                start_date: "",
                end_date: "",
                source: "",
                status: "1",
                created_date: new Date(),
                updated_date: new Date(),
              });
              setEdit(false);
              setEditID("");
            })
            .catch((error) => {
              console.error("Error updating vacation:", error);
            })
            .finally(() => {
              setIsSubmitting(false);
              setLoading(false);
            });

          checkVacationStatus();
        } else {
          db.collection("customers_vacation")
            .add({
              start_date: new Date(submitVacation.start_date),
              end_date: new Date(submitVacation.end_date),
              source: "backend",
              customer_phone: data.data && data.data.customer_phone,
              customer_id: data.data && data.data.customer_id,
              customer_name: data.data && data.data.customer_name,
              user: loggedIn_user,
              updated_date: new Date(),
              created_date: new Date(),
            })
            .then(() => {
              console.log("Vacation Added Successfully");
              db.collection("customer_activities")
                .add({
                  customer_phone: data.data && data.data.customer_phone,
                  customer_id: data.data && data.data.customer_id,
                  customer_name: data.data && data.data.customer_name,
                  customer_address: data.data && data.data.customer_name,
                  hub_name: data.data && data.data.hub_name,
                  delivery_exe_id: data.data && data.data.delivery_exe_id,
                  user: loggedIn_user,
                  object: "Add Vacation",
                  action: "Add Vacation",
                  description: `Vacation has been added through CRM with start date ${submitVacation.start_date} and end date ${submitVacation.end_date} by ${loggedIn_user}`,
                  date: new Date(),
                  created_date: new Date(),
                })
                .then(() => {
                  console.log("Activities added");
                });

              const Toast = Swal.mixin({
                toast: true,
                background: "#69aba6",
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Vacation Added Successfully",
              });

              closeVacation();
              setSubmitVacation({
                start_date: "",
                end_date: "",
                source: "",
                status: "1",
                created_date: new Date(),
                updated_date: new Date(),
              });
            })
            .catch((error) => {
              console.error("Error adding vacation:", error);
            })
            .finally(() => {
              setIsSubmitting(false);
              setLoading(false);
            });

          checkVacationStatus();
        }
      })
      .catch((error) => {
        console.error("Error fetching existing vacations:", error);
        setIsSubmitting(false);
        setLoading(false);
      });
  };

  const deleteVacation = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("customers_vacation")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Deleted Successfully");
            db.collection("customer_activities")
              .add({
                customer_phone: data.data && data.data.customer_phone,
                customer_id: data.data && data.data.customer_id,
                customer_name: data.data && data.data.customer_name,
                customer_address: data.data && data.data.customer_name,
                hub_name: data.data && data.data.hub_name,
                delivery_exe_id: data.data && data.data.delivery_exe_id,
                user: loggedIn_user,
                object: "delete vacation",
                action: "delete vacation",
                description: `Vacation has been deleted through CRM by ${loggedIn_user}`,
                //'description': description,
                date: new Date(),
                created_date: new Date(),
              })
              .then(() => {
                console.log("added activiteies");
              });
            setSubmitVacation({
              start_date: "",
              end_date: "",
              source: "",
              status: "1",
              created_date: new Date(),
              updated_date: new Date(),
            });
            Swal.fire("Deleted!", "Data has been deleted.", "success");
            checkVacationStatus();
            // updateNextDeliveryDateForAllSubscription(new Date());
          });
      }
    });
  };

  //make outbound call
  const triggerCallAPI = async (customerPhone) => {
    try {
      const settingsRef = db.collection("settings").doc("knowlarity");
      const doc = await settingsRef.get();

      if (!doc.exists) {
        throw new Error("Settings document not found");
      }

      const { x_api, authorization, caller_id, k_number, caller_id_alt, k_number_alt } = doc.data();
      const channel = "Basic";
      const apiUrl = `https://kpi.knowlarity.com/${channel}/v1/account/call/makecall`;
      const headers = {
        'x-api-key': x_api,
        'Authorization': authorization,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      };

      const userId = localStorage.getItem('userId');
      let agentNumber;

      switch (userId) {
        case '22626694':
            agentNumber = "+919873876969";
            break;
        case '84904350':
            agentNumber = "+918287088330";
            break;
        case '82469093':
            agentNumber = "+919318466478";
            break;
        case "62013145":
            agentNumber = "+917827980198";
            break;
        case "82212579":
            agentNumber = "+919873710709";
            break;
        case "03478896":
            agentNumber = "+919650515059";
            break;
        case "82212579":
            agentNumber = "+917827998787";
        default:
            break;
    }
    
      const requestBody = {
        "k_number": k_number,
        "agent_number": agentNumber,
        "customer_number": customerPhone.length === 10 ? "+91" + customerPhone : customerPhone,
        "caller_id": caller_id,
      };

      fetch(apiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (response.status !== 200) {
            requestBody.k_number = k_number_alt;
            requestBody.caller_id = caller_id_alt;

            fetch(apiUrl, {
              method: "POST",
              headers: headers,
              body: JSON.stringify(requestBody),
            })
              .then((response) => {
                console.log(
                  "API call triggered successfully with alternate values"
                );
              })
              .catch((error) => {
                console.error(
                  "Error triggering API call with alternate values:",
                  error
                );
              });
          } else {
            console.log("API call triggered successfully");
          }
        })
        .catch((error) => {
          console.error("Error triggering API call:", error);
        });
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };

  const handleSubmitTickets = (e) => {
    e.preventDefault();

    if (edit) {
      setLoading(true);
      db.collection("customers_tickets")
        .doc(editID)
        .update({
          category: submitTickets.category,
          status: submitTickets.status,
          source: "Backend",
          priority: submitTickets.priority,
          due_date: new Date(submitTickets.due_date),
          owner: submitTickets.owner,
          description: submitTickets.description,
          visible_on: submitTickets.visible_on,
          data_status: "1",
          updated_date: new Date(),
        })
        .then(() => {
          console.log("Updated Successfully");
          let changes = calculateChanges(oldTicketData, submitTickets);
          db.collection("customer_activities")
            .add({
              customer_phone: data.data && data.data.customer_phone,
              customer_id: data.data && data.data.customer_id,
              customer_name: data.data && data.data.customer_name,
              customer_address: data.data && data.data.customer_name,
              hub_name: data.data && data.data.hub_name,
              delivery_exe_id: data.data && data.data.delivery_exe_id,
              user: loggedIn_user,
              object: "Ticket",
              action: "Update Ticket",
              description: `Ticket with id ${submitTickets.ticket_id} is updated by ${loggedIn_user}`,
              date: new Date(),
              changes: changes,
              created_date: new Date(),
            })
            .then(() => {
              console.log("added activiteies");
            });
          const Toast = Swal.mixin({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Ticket Updated Successfully",
          });
          closeTickets();
          setSubmitTickets({
            category: "",
            status: "",
            priority: "",
            due_date: "",
            owner: "",
            description: "",
            visible_on: "",
            status: "1",
            created_date: new Date(),
            updated_date: new Date(),
          });
          setLoading(false);
          setEdit(false);
          setEditID("");
        });
    } else {
      setLoading(true);
      db.collection("customers_tickets")
        .add({
          ticket_id: `${generateRandomTicket()}`,
          category: submitTickets.category,
          status: submitTickets.status,
          source: "Backend",
          priority: submitTickets.priority,
          due_date: new Date(submitTickets.due_date),
          owner: submitTickets.owner,
          description: submitTickets.description,
          visible_on: submitTickets.visible_on,
          data_status: "1",
          customer_phone: data.data && data.data.customer_phone,
          customer_id: data.data && data.data.customer_id,
          customer_name: data.data && data.data.customer_name,
          updated_date: new Date(),
          created_date: new Date(),
        })
        .then(() => {
          console.log("Ticket Added Successfully");
          db.collection("customer_activities")
            .add({
              customer_phone: data.data && data.data.customer_phone,
              customer_id: data.data && data.data.customer_id,
              customer_name: data.data && data.data.customer_name,
              customer_address: data.data && data.data.customer_name,
              hub_name: data.data && data.data.hub_name,
              delivery_exe_id: data.data && data.data.delivery_exe_id,
              user: loggedIn_user,
              object: "Ticket",
              action: "Update Ticket",
              description: `Feedback Ticket is created by ${loggedIn_user}`,
              date: new Date(),
              created_date: new Date(),
            })
            .then(() => {
              console.log("added activiteies");
            });
          const Toast = Swal.mixin({
            toast: true,
            background: "#69aba6",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          closeTickets();
          setSubmitTickets({
            category: "",
            status: "",
            priority: "",
            due_date: "",
            owner: "",
            description: "",
            visible_on: "",
            status: "1",
            created_date: new Date(),
            updated_date: new Date(),
          });
          setLoading(false);
          Toast.fire({
            icon: "success",
            title: "Customer Added",
          });
        });
    }
  };

  const deleteTickets = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        db.collection("customers_tickets")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Deleted Successfully");
            db.collection("customer_activities")
              .add({
                description: `Ticket is deleted by ${loggedIn_user}`,
                customer_phone: data.data && data.data.customer_phone,
                customer_id: data.data && data.data.customer_id,
                customer_name: data.data && data.data.customer_name,
                created_date: new Date(),
                user: loggedIn_user,
                action: "Ticket",
                object: "Ticket",
              })
              .then(() => {
                console.log("added activiteies");
              });
            setSubmitTickets({
              category: "",
              status: "",
              priority: "",
              due_date: "",
              owner: "",
              description: "",
              visible_on: "",
              status: "1",
              created_date: new Date(),
              updated_date: new Date(),
            });
            Swal.fire("Deleted!", "Data has been deleted.", "success");
          });
      }
    });
  };

  const renderCheckboxPairs = () => {
    const pairs = [];
    for (let i = 0; i < daysOfWeek.length - 1; i += 2) {
      const pair = [daysOfWeek[i], daysOfWeek[i + 1]];
      pairs.push(pair);
    }

    // Check if there's an odd number of days
    if (daysOfWeek.length % 2 !== 0) {
      const singleDay = [daysOfWeek[daysOfWeek.length - 1]];
      pairs.push(singleDay);
    }

    return pairs.map((pair, index) => (
      <div
        key={index}
        className="form-row"
        style={{ display: "flex", paddingLeft: "38px" }}
      >
        {pair.map((day) => (
          <div key={day} className="form-check col">
            <input
              type="checkbox"
              id={day}
              checked={selectedDays.includes(day)}
              onChange={() => handleDayChange(day)}
              className="form-check-input"
            />
            <label htmlFor={day} className="form-check-label">
              {day}
            </label>
          </div>
        ))}
      </div>
    ));
  };

  const tabs = (name) => {
    setActivetab(name);
  };

  const CustomDatePickerIcon = React.forwardRef(({ value, onClick }, ref) => (
    <span className="calender-placment" onClick={onClick} ref={ref}>
      <i className="mdi mdi-calendar" />
    </span>
  ));

  const handleRemoveProduct = async (orderId, productId) => {
    try {
      const orderRef = db.collection("order_history").doc(orderId);

      // Fetch the current order data
      const orderSnapshot = await orderRef.get();
      if (!orderSnapshot.exists) {
        console.error("Order not found");
        return;
      }
      const orderData = orderSnapshot.data(); // Define orderData here

      // Find the index of the product to remove
      const productIndex = orderData.products.findIndex(
        (product) => product.subscription_id === productId
      );

      // Ensure the product exists in the array
      if (productIndex === -1) {
        console.error("Product not found in the order");
        return;
      }

      // Remove the product from the array
      const updatedProducts = [...orderData.products];
      updatedProducts.splice(productIndex, 1);

      // Update the order document with the updated products array
      if (updatedProducts.length === 0) {
        // If there are no more products, delete the entire document
        await orderRef.delete();
        console.log("Order deleted successfully!");
      } else {
        // Otherwise, update the order document with the updated products array
        await orderRef.update({ products: updatedProducts });
        console.log("Product removed successfully!");
      }
    } catch (error) {
      console.error("Error removing product:", error);
    }
  };

  const rolePermission = () => {
    const Toast = Swal.mixin({
      toast: true,
      background: "#d7e7e6",
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "error",
      title: "You are not authorised to do this action",
    });
  };

  const [discounts, setDiscounts] = useState({});

  const handleDiscountChange = (productId, event) => {
    const { value } = event.target;
    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [productId]: value,
    }));
  };

  const calculateFinalPrice = (price, discount) => {
    return price - (discount || 0);
  };

  const resetCalendar = () => {
    setCalendarDate(getTomorrowDate());
  };

  /** pauseSubscripation start */

  const handlePauseSubscription = async (e) => {
    console.log("Resume date:", resumeDate);
    e.preventDefault();
    if (selectedSubIdToPause !== "" && selectedSubscriptionToPause !== "") {
      // Calculate next delivery date as resumeDate + 1 day
      const calculatedNextDeliveryDate = resumeDate
        ? moment(resumeDate).add(1, "days").format("YYYY-MM-DD")
        : moment().add(1, "days").format("YYYY-MM-DD");
      try {
        await db
          .collection("subscriptions_data")
          .doc(selectedSubIdToPause)
          .update({
            status: "0",
            resume_date: resumeDate ? new Date(resumeDate) : new Date(),
            next_delivery_date: calculatedNextDeliveryDate,
            updated_date: new Date(),
          })
          .then(() => console.log("Subscription paused, delivery date updated"))
          .catch((error) =>
            console.error("Error updating subscription:", error)
          );

        await db
          .collection("customer_activities")
          .add({
            customer_phone: data.data?.customer_phone,
            customer_id: data.data?.customer_id,
            customer_name: data.data?.customer_name,
            customer_address: data.data?.customer_name,
            hub_name: data.data?.hub_name,
            delivery_exe_id: data.data?.delivery_exe_id,
            user: loggedIn_user,
            object: "Subscription",
            action: "Paused",
            description: `Subscription ID: ${
              selectedSubscriptionToPause.subscription_id
            } for ${selectedSubscriptionToPause.product_name} ${
              selectedSubscriptionToPause.package_unit
            } was paused by ${loggedIn_user} through CRM. Updated resume date: ${moment(
              resumeDate
            ).format("DD-MM-YYYY")}`,
            date: new Date(),
            created_date: new Date(),
          })
          .then(() => console.log("Activity logged"))
          .catch((error) => console.error("Error logging activity:", error));

        closePauseSubDialog();
      } catch (error) {
        console.error("Error handling pause subscription:", error);
      }
    }
  };

  const changeSubcriptionStatus = async (id, subscription) => {
    const today = new Date();
    const tomorrow = new Date(today);
    const currentHour = today.getHours();
    const currentMinute = today.getMinutes();

    if (currentHour >= 23 && currentMinute <= 59) {
      tomorrow.setDate(tomorrow.getDate() + 2);
    } else {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }

    const updatedNextDeliveryDate = moment(tomorrow).format("YYYY-MM-DD");

    if (subscription.status === "0") {
      try {
        await db
          .collection("subscriptions_data")
          .doc(id)
          .update({
            status: "1",
            next_delivery_date: updatedNextDeliveryDate,
            updated_date: new Date(),
            resume_date: new Date(),
          })
          .then(() =>
            console.log("Subscription resumed, delivery date updated")
          )
          .catch((error) =>
            console.error("Error updating subscription:", error)
          );

        await db
          .collection("customer_activities")
          .add({
            customer_phone: data.data?.customer_phone,
            customer_id: data.data?.customer_id,
            customer_name: data.data?.customer_name,
            customer_address: data.data?.customer_name,
            hub_name: data.data?.hub_name,
            delivery_exe_id: data.data?.delivery_exe_id,
            user: loggedIn_user,
            object: "Subscription",
            action: "Activated",
            description: `Subscription ID: ${subscription.subscription_id} for ${subscription.product_name} ${subscription.package_unit} was activated by ${loggedIn_user} through the system. Next delivery date: ${updatedNextDeliveryDate}`,
            date: new Date(),
            created_date: new Date(),
          })
          .then(() => console.log("Activity logged"))
          .catch((error) => console.error("Error logging activity:", error));
      } catch (error) {
        console.error("Error changing subscription status:", error);
      }
    } else {
      setSelectedSubscriptionToPause(subscription);
      setSelectedSubIdToPause(id);
      setResumeDate(null);
      openPauseSubDialog();
    }
  };

  /** pauseSubscripation end */

  const [filterText, setFilterText] = useState("");
  // Handle search input change
  const handleSearchChange = (e) => {
    setFilterText(e.target.value.toLowerCase());
  };

  const filteredProducts = products.filter(({ data }) =>
    data.productName.toLowerCase().includes(filterText)
  );
  return (
    <>
      <div class="container-scroller">
        <TopPanel />

        <div class="container-fluid page-body-wrapper">
          <Sidebar />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="d-sm-flex align-items-center justify-content-between">
                      <ul class="nav nav-tabs" role="tablist"></ul>
                      <div>
                        <div class="btn-wrapper"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-md-12 col-xl-12 stretch-card"
                style={{ marginBottom: "10px" }}
              >
                <div class="card">
                  <div class="card">
                    <div class="home-tab">
                      <div class="align-items-center justify-content-between border-bottom">
                        <ul
                          class="nav nav-tabs"
                          role="tablist"
                          style={{
                            background: "#4751b2",
                            borderRadius: "15px",
                          }}
                        >
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Home")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Home" ? "custom-active" : ""
                              } ps-0`}
                              style={{ color: "#fff", marginLeft: "1.4rem" }}
                              id="home-tab"
                              data-bs-toggle="tab"
                              href="#overview"
                              role="tab"
                              aria-controls="overview"
                              aria-selected="true"
                            >
                              {" "}
                              <i
                                class="icon-home"
                                style={{
                                  marginRight: "5px",
                                  marginLeft: "15px",
                                }}
                              ></i>
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Subscription")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Subscription"
                                  ? "custom-active"
                                  : ""
                              }`}
                              id="profile-tab"
                              data-bs-toggle="tab"
                              href="#audiences"
                              role="tab"
                              aria-selected="false"
                            >
                              Subscription ({subscriptionData.length})
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Orders")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Orders" ? "custom-active" : ""
                              }`}
                              id="profile-tab"
                              data-bs-toggle="tab"
                              href="#audiences"
                              onClick={() => fetchOrderHistory()}
                              role="tab"
                              aria-selected="false"
                            >
                              Orders ({uniqueOrders.length})
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Wallet")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Wallet" ? "custom-active" : ""
                              }`}
                              id="contact-tab"
                              data-bs-toggle="tab"
                              href="#demographics"
                              role="tab"
                              aria-selected="false"
                            >
                              Wallet
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Activities")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Activities"
                                  ? "custom-active"
                                  : ""
                              }`}
                              id="contact-tab"
                              data-bs-toggle="tab"
                              href="#demographics"
                              role="tab"
                              aria-selected="false"
                            >
                              Activities
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Tickets")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Tickets" ? "custom-active" : ""
                              }`}
                              id="contact-tab"
                              data-bs-toggle="tab"
                              href="#demographics"
                              role="tab"
                              aria-selected="false"
                            >
                              Tickets (
                              {ticketsData.length === 0
                                ? "0"
                                : ticketsData.length}
                              )
                            </a>
                          </li>
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("Vacation")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "Vacation" ? "custom-active" : ""
                              }`}
                              id="contact-tab"
                              data-bs-toggle="tab"
                              href="#demographics"
                              role="tab"
                              aria-selected="false"
                            >
                              Vacation (
                              {vacationData.length === 0
                                ? "0"
                                : vacationData.length}
                              )
                            </a>
                          </li>

                          
                          <li
                         class="nav-item"
          onClick={() => setActivetab("conversastion_logs")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "conversastion_logs" ? "custom-active" : ""
                              }`}
                              style={{ color: "#fff"}}
                              id="conversastion_logs-tab"
                              data-bs-toggle="tab"
                              href="#overview"
                              role="tab"
                              aria-controls="overview"
                              aria-selected="true"
                            >
                              {" "}
                              Conversastion logs
                            </a>
                          </li>
                        
                         <li
                            class="nav-item"
                            onClick={() => setActivetab("email_logs")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "email_logs" ? "custom-active" : ""
                              }`}
                              style={{ color: "#fff" }}
                              id="email_logs-tab"
                              data-bs-toggle="tab"
                              href="#overview"
                              role="tab"
                              aria-controls="overview"
                              aria-selected="true"
                            >
                              {" "}
                              Email logs
                            </a>

                          </li> 
                          <li
                            class="nav-item"
                            onClick={() => setActivetab("call_logs")}
                          >
                            <a
                              class={`nav-link ${
                                activeTab === "call_logs" ? "custom-active" : ""
                              }`}
                              style={{ color: "#fff" }}
                              id="call_logs-tab"
                              data-bs-toggle="tab"
                              href="#overview"
                              role="tab"
                              aria-controls="overview"
                              aria-selected="true"
                            >
                              {" "}
                              Call logs
                            </a>
                          </li> 

                          {isOnVacation ? (
                            <li class="nav-item">
                              {/* <a class={`nav-link ${activeTab === "Vacation" ? 'custom-active' : ''}`} id="contact-tab" data-bs-toggle="tab" href="#demographics" role="tab"
                              aria-selected="false">Vacation ({vacationData.length === 0 ? "0" : vacationData.length})
                                                            
                                                            </a> */}
                              <i
                                style={{
                                  fontSize: "28px",
                                  marginTop: "8px",
                                  color: "red",
                                  marginLeft: "10px",
                                }}
                                class="fa-solid fa-plane"
                              ></i>
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {activeTab === "Home" ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div class="col-md-12 grid-margin">
                    <div class="row">
                      <div
                        class="col-md-2 grid-margin"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card d-flex align-items-start custom-green">
                          <div
                            class="card-body "
                            onClick={() =>
                              permissible_roles.includes("edit_wallet")
                                ? openWallet()
                                : rolePermission()
                            }
                          >
                            <div class="d-flex flex-row align-items-start">
                              {/* <i class="icon-wallet" style={{ marginRight: "6px" }}></i> */}
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                                class="text-facebook"
                              >
                                <i
                                  class="icon-wallet"
                                  style={{ marginRight: "6px" }}
                                ></i>{" "}
                                Wallet
                              </span>
                              {/* <div class="ms-3">
                                                            <h6 class="text-facebook">50k likes</h6>
                                                        </div> */}
                            </div>
                            {/* <p class="mt-2 text-muted card-text">₹ ${data.data && data.data.wallet_balance === 0 ? 0.00 : data.data.wallet_balance}</p> */}
                            <p class="mt-2 text-muted card-text custom-card-text">
                              ₹{" "}
                              {data.data?.wallet_balance === 0
                                ? 0.0
                                : data.data?.wallet_balance || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 grid-margin"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card d-flex align-items-start custom-green">
                          <div
                            class="card-body"
                            onClick={() =>
                              permissible_roles.includes(
                                "add_credit_limit"
                              )
                                ? openCredit()
                                : rolePermission()
                            }
                          >
                            <div class="d-flex flex-row align-items-start">
                              <span
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                                class="text-facebook"
                              >
                                Credit Limit
                              </span>
                            </div>
                            {/* <p class="mt-2 text-muted card-text">₹ {data.data && data.data.credit_limit === 0 ? 0.00 : data.data.credit_limit}</p> */}
                            <p class="mt-2 text-muted card-text custom-card-text">
                              ₹{" "}
                              {data.data?.credit_limit === 0
                                ? 0.0
                                : data.data?.credit_limit || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 grid-margin"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card d-flex align-items-start">
                          <div class="card-body" onClick={() => openCalendar()}>
                            <div class="d-flex flex-row align-items-start">
                              <span
                                style={{ fontSize: "13px", fontWeight: "600" }}
                                class="text-facebook"
                              >
                                Calendar
                              </span>
                            </div>
                            <p
                              class="mt-2 card-text"
                              style={{ color: "white" }}
                            >
                              .....
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 grid-margin"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card d-flex align-items-start">
                          <div
                            class="card-body"
                            onClick={() =>
                              permissible_roles.includes("delivery_prefrence")
                                ? openDF()
                                : rolePermission()
                            }
                          >
                            <div
                              class="d-flex flex-row align-items-start"
                              style={{ marginBottom: "-1rem" }}
                            >
                              {/* <i class="icon-wallet" style={{ marginRight: "6px" }}></i> */}
                              <span
                                style={{ fontSize: "13px", fontWeight: "600" }}
                                class="text-facebook"
                              >
                                Delivery Preference
                              </span>
                            </div>
                            <p
                              class="mt-2 card-text"
                              style={{ color: "white" }}
                            >
                              .....
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 grid-margin"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card d-flex align-items-start">
                          <div
                            class="card-body"
                            onClick={() =>
                              permissible_roles.includes("cash_collection")
                                ? openCash()
                                : rolePermission()
                            }
                          >
                            <div
                              class="d-flex flex-row align-items-start"
                              style={{ marginBottom: "-1rem" }}
                            >
                              <span
                                style={{ fontSize: "13px", fontWeight: "600" }}
                                class="text-facebook"
                              >
                                Cash Collection
                              </span>
                            </div>
                            <p
                              class="mt-2 card-text"
                              style={{ color: "white" }}
                            >
                              .....
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-2 grid-margin"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card d-flex align-items-start">
                          <div class="card-body" onClick={() => openSRL()}>
                            <div
                              class="d-flex flex-row align-items-start"
                              style={{ marginBottom: "-1rem" }}
                            >
                              <span
                                style={{ fontSize: "13px", fontWeight: "600" }}
                                class="text-facebook"
                              >
                                Copy Recharge Link
                              </span>
                            </div>
                            <p
                              class="mt-2 card-text"
                              style={{ color: "white" }}
                            >
                              .....
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {activeTab === "Home" ? (
                <>
                  <div class="row">
                    <div class="col-md-6 grid-margin stretch-card">
                      <div class="card">
                        {/* <div style={{ display: "flex", justifyContent: "end" }}> */}
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            paddingRight: "21px",
                            paddingTop: "4px",
                          }}
                        >
                          <i
                            className="menu-icon mdi mdi-pencil"
                            onClick={() =>
                              navi(data.data && data.data.customer_id)
                            }
                            style={{
                              color: "grey",
                              cursor: "pointer",
                              fontSize: "23px",
                              paddingLeft: "5px",
                            }}
                          ></i>
                        </span>
                        {/* </div> */}
                        <div class="card-body ">
                          <div
                            className="position-absolute"
                            style={{
                              top: "10px",
                              right: "14px",
                              cursor: "pointer",
                              fontSize: "1.5rem",
                            }}
                          >
                            <i
                              class="ti-bin"
                              //onClick={(e) => changeStatusForm(e, data, id)}
                            ></i>
                          </div>
                          <div class="pb-2" style={{ textAlign: "center" }}>
                            {/* <img src={data.data && data.data.customer_image} class="img-lg rounded-circle mb-2"
                                                        alt="profile image" /> */}
                            <img
                              src={
                                data.data && data.data.customer_image
                                  ? data.data.customer_image
                                  : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                              }
                              class="img-lg rounded-circle mb-2"
                              alt="profile image"
                            />
                            <h4>{data.data && data.data.customer_name}</h4>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "8px"}}>
                              <p class="text-muted mb-0">
                                <i class="ti-mobile"></i> +91-
                                {data.data && data.data.customer_phone}
                              </p>
                              <button
                                className="btn btn-primary btn-rounded btn-sm"
                                style={{
                                  padding: "4px 8px",
                                }}
                                onClick={() => triggerCallAPI(data.data.customer_phone)}
                              >
                              <span>Call</span>
                              </button>
                            </div>
                          </div>
                          <div class="border-top pt-4">
                            <h5 class="card-title">
                              Address <i class="ti-home"></i>
                            </h5>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <address>
                                <p class="">{data.data && data.data.floor}</p>
                                <p>{data.data && data.data.flat_villa_no}</p>
                                <p class="fw-bold">
                                  {data.data && data.data.customer_address}
                                </p>
                                <p>{data.data && data.data.location}</p>
                                <p>{data.data && data.data.hub_name}</p>
                                <p>{data.data && data.data.pincode}</p>
                              </address>
                            </div>
                            <div class="col-md-6">
                              <address class="text-primary">
                                <p class="fw-bold">
                                  E-mail <i class="ti-email"></i>
                                </p>
                                <p class="mb-2">
                                  {/* 9897609090 */}
                                  {data.data && data.data.customer_email}
                                </p>
                                <p class="fw-bold">
                                  Landmark <i class="ti-location-pin"></i>
                                </p>
                                <p>{data.data && data.data.landmark}</p>
                                <br />
                                <p class="fw-bold">
                                  Alternate Contact Number{" "}
                                  <i class="ti-mobile"></i>
                                </p>
                                <p>{data.data && data.data.alt_phone}</p>
                              </address>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="border-top pt-3">
                            <div class="row" style={{ textAlign: "center" }}>
                              <div class="col-3">
                                <h7
                                  style={{ fontWeight: "500", color: "grey" }}
                                >
                                  ID
                                </h7>
                                <p>{data.data && data.data.customer_id}</p>
                              </div>
                              <div class="col-3">
                                <h7
                                  style={{ fontWeight: "500", color: "grey" }}
                                >
                                  Category
                                </h7>
                                <p>
                                  {data.data && data.data.customer_category}
                                </p>
                              </div>
                              <div class="col-6">
                                <h7
                                  style={{ fontWeight: "500", color: "grey" }}
                                >
                                  Registered On
                                </h7>
                                <p>
                                  {data.data &&
                                    moment(
                                      data.data.created_date
                                        .toDate()
                                        .toISOString()
                                    ).format("DD-MM-YYYY, h:mm a")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 grid-margin">
                      <div class="row">
                        <div
                          class="col-md-4 grid-margin"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="card d-flex align-items-start custom-green">
                            <div
                              class="card-body"
                              onClick={() =>
                                permissible_roles.includes("edit_wallet")
                                  ? openWallet()
                                  : rolePermission()
                              }
                            >
                              <div class="d-flex flex-row align-items-start">
                                {/* <i class="icon-wallet" style={{ marginRight: "6px" }}></i> */}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                  class="text-facebook"
                                >
                                  <i
                                    class="icon-wallet"
                                    style={{ marginRight: "6px" }}
                                  ></i>{" "}
                                  Wallet
                                </span>
                                {/* <div class="ms-3">
                                                            <h6 class="text-facebook">50k likes</h6>
                                                        </div> */}
                              </div>
                              {/* <p class="mt-2 text-muted card-text">₹ ${data.data && data.data.wallet_balance === 0 ? 0.00 : data.data.wallet_balance}</p> */}
                              <p class="mt-2 text-muted card-text custom-card-text">
                                ₹{" "}
                                {data.data?.wallet_balance === 0
                                  ? 0.0
                                  : data.data?.wallet_balance || "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-4 grid-margin"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="card d-flex align-items-start">
                            <div class="card-body" onClick={openCalendar}>
                              <div class="d-flex flex-row align-items-start">
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                  class="text-facebook"
                                >
                                  Calendar
                                </span>
                              </div>
                              <p class="mt-4 text-muted card-text"> </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-4 grid-margin"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="card d-flex align-items-start">
                            <div
                              class="card-body"
                              onClick={() =>
                                permissible_roles.includes("delivery_prefrence")
                                  ? openDF()
                                  : rolePermission()
                              }
                            >
                              <div class="d-flex flex-row align-items-start">
                                {/* <i class="icon-wallet" style={{ marginRight: "6px" }}></i> */}
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                  class="text-facebook"
                                >
                                  Delivery Preference
                                </span>
                                {/* <div class="ms-3">
                                                            <h6 class="text-facebook">50k likes</h6>
                                                            <p class="mt-2 text-muted card-text">You main list growing</p>
                                                        </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-md-4 grid-margin"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="card d-flex align-items-start custom-green">
                            <div
                              class="card-body"
                              onClick={() =>
                                permissible_roles.includes(
                                  "add_credit_limit"
                                )
                                  ? openCredit()
                                  : rolePermission()
                              }
                            >
                              <div class="d-flex flex-row align-items-start">
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                  class="text-facebook"
                                >
                                  Credit Limit
                                </span>
                              </div>
                              {/* <p class="mt-2 text-muted card-text">₹ {data.data && data.data.credit_limit === 0 ? 0.00 : data.data.credit_limit}</p> */}
                              <p class="mt-2 text-muted card-text custom-card-text">
                                ₹{" "}
                                {data.data?.credit_limit === 0
                                  ? 0.0
                                  : data.data?.credit_limit || "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-4 grid-margin"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="card d-flex align-items-start">
                            <div
                              class="card-body"
                              onClick={() =>
                                permissible_roles.includes("cash_collection")
                                  ? openCash()
                                  : rolePermission()
                              }
                            >
                              <div class="d-flex flex-row align-items-start">
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                  class="text-facebook"
                                >
                                  Cash Collection
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md-4 grid-margin"
                          style={{ cursor: "pointer" }}
                        >
                          <div class="card d-flex align-items-start">
                            <div class="card-body" onClick={() => openSRL()}>
                              <div class="d-flex flex-row align-items-start">
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                  class="text-facebook"
                                >
                                  Copy Recharge Link
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-body">
                          <div
                            class=""
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <h4 class="card-title">Delivery Executive</h4>
                              <p>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Hub Name
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "11px",
                                    color: "grey",
                                  }}
                                >
                                  {hubData.hub_name}
                                </span>
                              </p>
                            </div>
                            <img
                              src={
                                hubData.image
                                  ? hubData.image
                                  : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                              }
                              class="img-lg rounded-circle mb-2"
                              alt="profile image"
                            />
                            {/* <img src="/images/Delivery_executive.png" class="img-lg rounded-circle mb-3"
                                                        alt="profile image"/> */}
                          </div>
                          <p
                            style={{
                              fontWeight: "700",
                              color: "grey",
                              fontSize: "16px",
                            }}
                            class="card-description"
                          >
                            Details
                          </p>
                          <div class="row">
                            <div class="col-md-6">
                              <address>
                                <p class="fw-bold">
                                  {" "}
                                  <i class="ti-user"></i> Name{" "}
                                </p>
                                <p>
                                  {/* {data.data && data.data.delivery_executive} */}
                                  {deliveryExecutives.length > 0 && (
                                    <>
                                      {deliveryExecutives[0].first_name}{" "}
                                      {deliveryExecutives[0].last_name}
                                    </>
                                  )}
                                </p>
                              </address>
                              <address>
                                <p class="fw-bold">
                                  <i class="ti-location-pin"></i> Location{" "}
                                </p>
                                <p>
                                  {hubData.address}, {hubData.city}
                                </p>
                                <p>{hubData.state}, India</p>
                              </address>
                            </div>
                            <div class="col-md-6">
                              <address class="text-primary">
                                <p class="fw-bold">
                                  <i class="ti-mobile"></i> Phone No
                                </p>
                                <p class="mb-2">
                                  {deliveryExecutives.length > 0 && (
                                    <>{deliveryExecutives[0].phone_no}</>
                                  )}
                                </p>
                                {/* <p class="fw-bold">
                                                                Account Manager
                                                            </p>
                                                            <p>
                                                                {data.data && data.data.account_manager}
                                                            </p> */}
                              </address>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {activeTab === "Orders" ? (
                <>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="home-tab">
                        <div class="d-sm-flex align-items-center justify-content-between">
                          <div>
                            <div class="btn-wrapper">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="From Date"
                                class="form-control"
                                dateFormat="dd-MM-YYYY"
                              />
                              <span class="" style={{ width: "1rem" }}>
                                {" "}
                                TO{" "}
                              </span>
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="To Date"
                                class="form-control"
                                dateFormat="dd-MM-YYYY"
                              />
                              <a
                                href="#"
                                class="btn btn-primary text-white me-0 mr-2"
                                onClick={() => handleSearchOrders()}
                                style={{ marginTop: "9px", marginLeft: "14px" }}
                              >
                                <i class="icon-search"></i>Search
                              </a>
                              <a
                                href="#"
                                class="btn btn-primary text-white me-0 mr-2"
                                onClick={() => resetSearchOrders()}
                                style={{ marginTop: "9px", marginLeft: "14px" }}
                              >
                                <i class="icon-reset"></i>Reset
                              </a>
                            </div>
                          </div>
                          <div>
                            <div class="btn-wrapper">
                              {permissible_roles.includes("create_subscription") ? (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => openAdhocOrder()}
                                  >
                                    <i class="icon-plus"></i>Adhoc Order
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => rolePermission()}
                                  >
                                    <i class="icon-plus"></i>Add Subscription
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title">Orders</h4>
                        <div>
                          <div
                            className="tab-pane"
                            role="tabpanel"
                            aria-labelledby="contact-tab"
                          >
                            {uniqueOrders.map((order) => {
                              const uniqueOrder = order;
                              const allOrdersCancelled =
                                uniqueOrder.data.status === "2";
                              const anyOrderNew =
                                uniqueOrder.data.status === "0";
                              const anyOrderDelivered =
                                uniqueOrder.data.status === "1";

                              // Filter all products related to the current order_id
                              const orders = orderListData.filter(
                                (o) =>
                                  o.data.order_id === uniqueOrder.data.order_id
                              );

                              return (
                                <div className="card mb-2" key={uniqueOrder.id}>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-lg-2">
                                        <address>
                                          <p className="fw-bold">Hub:</p>
                                          <p>{uniqueOrder.data.hub_name}</p>
                                        </address>
                                        <address>
                                          <p className="fw-bold">
                                            Delivery Executive:
                                          </p>
                                          <p>
                                            {
                                              userMapID[
                                                uniqueOrder.data.delivery_exe_id
                                              ]
                                            }
                                          </p>
                                        </address>
                                      </div>
                                      <div className="col-lg-2">
                                        <address>
                                          <p className="fw-bold">
                                            Order Status:
                                          </p>
                                          <p>
                                            {anyOrderNew ? (
                                              <button
                                                className="btn btn-info btn-rounded btn-sm"
                                                style={{
                                                  marginRight: "1rem",
                                                  padding: "4px 19.4px",
                                                }}
                                              >
                                                <span>New</span>
                                              </button>
                                            ) : anyOrderDelivered ? (
                                              <button
                                                className="btn btn-success btn-rounded btn-sm"
                                                style={{
                                                  marginRight: "1rem",
                                                  padding: "4px 19.4px",
                                                }}
                                              >
                                                <span>Delivered</span>
                                              </button>
                                            ) : allOrdersCancelled ? (
                                              <button
                                                className="btn btn-danger btn-rounded btn-sm"
                                                style={{
                                                  marginRight: "1rem",
                                                  padding: "4px 19.4px",
                                                }}
                                              >
                                                <span>Cancelled</span>
                                              </button>
                                            ) : null}
                                          </p>
                                        </address>
                                        <address>
                                          <p className="fw-bold">Order Type</p>
                                          <p>{uniqueOrder.data.order_type}</p>
                                        </address>
                                      </div>
                                      <div className="col-lg-2">
                                        <address>
                                          <p className="fw-bold">Phone No:</p>
                                          <p>
                                            {userMapID &&
                                              uniqueOrder &&
                                              userMapID[
                                                uniqueOrder.data
                                                  .delivery_exe_id + "_phone"
                                              ]}
                                          </p>
                                        </address>
                                        <address>
                                          <p className="fw-bold">Order ID</p>
                                          <p>{uniqueOrder.data.order_id}</p>
                                        </address>
                                      </div>
                                      <div className="col-lg-2">
                                        <address>
                                          <p className="fw-bold">Date:</p>
                                          <p>
                                            {uniqueOrder.data.delivery_date}
                                          </p>
                                        </address>
                                        <address>
                                          <p className="fw-bold">
                                            Delivery Time
                                          </p>
                                          <p>
                                            {uniqueOrder.data.delivery_time}
                                          </p>
                                        </address>
                                      </div>
                                      <div className="col-lg-2">
                                        <address>
                                          <p className="fw-bold">
                                            Order Amount:
                                          </p>
                                          <p>
                                            {uniqueOrder.data.quantity *
                                              uniqueOrder.data.price}
                                          </p>
                                        </address>
                                        <address>
                                          <p className="fw-bold">
                                            Total Order Amount
                                          </p>
                                          <p>
                                            {uniqueOrder.data.quantity *
                                              uniqueOrder.data.price}
                                          </p>
                                        </address>
                                      </div>
                                      <div className="col-lg-2">
                                        <address>
                                          <p className="fw-bold">
                                            Total Discount:
                                          </p>
                                          <p>0.00</p>
                                        </address>
                                        {!allOrdersCancelled ? (
                                          <address>
                                            <p className="fw-bold">Action</p>
                                            <span>
                                              {allOrdersCancelled ? (
                                                <></>
                                              ) : anyOrderDelivered ? (
                                                <button
                                                  onClick={() =>
                                                    openmdlv(
                                                      uniqueOrder.data.order_id,
                                                      "2"
                                                    )
                                                  }
                                                  className="btn btn-danger btn-sm"
                                                  style={{
                                                    marginRight: "1rem",
                                                    padding: "4px 15px",
                                                  }}
                                                >
                                                  <i
                                                    className="menu-icon mdi mdi-delete"
                                                    style={{
                                                      color: "white",
                                                      fontSize: "15px",
                                                    }}
                                                  ></i>
                                                </button>
                                              ) : (
                                                <>
                                                  <button
                                                    onClick={() =>
                                                      openmdlv(
                                                        uniqueOrder.data
                                                          .order_id,
                                                        "1"
                                                      )
                                                    }
                                                    className="btn btn-success btn-sm"
                                                    style={{
                                                      marginRight: "1rem",
                                                      padding: "4px 15px",
                                                    }}
                                                  >
                                                    <i
                                                      className="menu-icon mdi mdi-check-all"
                                                      style={{
                                                        color: "white",
                                                        fontSize: "15px",
                                                      }}
                                                    ></i>
                                                  </button>
                                                  <button
                                                    onClick={() =>
                                                      openmdlv(
                                                        uniqueOrder.data
                                                          .order_id,
                                                        "2"
                                                      )
                                                    }
                                                    className="btn btn-danger btn-sm"
                                                    style={{
                                                      marginRight: "1rem",
                                                      padding: "4px 15px",
                                                    }}
                                                  >
                                                    <i
                                                      className="menu-icon mdi mdi-delete"
                                                      style={{
                                                        color: "white",
                                                        fontSize: "15px",
                                                      }}
                                                    ></i>
                                                  </button>
                                                </>
                                              )}
                                            </span>
                                          </address>
                                        ) : null}
                                      </div>
                                    </div>
                                    <br />
                                    <div
                                      className=""
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          backgroundColor: "",
                                          display: "flex",
                                        }}
                                      ></div>
                                      <div></div>
                                    </div>
                                  </div>
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="pt-1">
                                            Product Image
                                          </th>
                                          <th className="pt-1">Sub ID</th>
                                          <th className="pt-1">Product</th>
                                          <th className="pt-1">Unit Price</th>
                                          <th className="pt-1">Total Price</th>
                                          <th className="pt-1">Quantity</th>
                                          <th className="pt-1">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orders.map((filteredOrder, index) => (
                                          <tr key={filteredOrder.id}>
                                            <td>
                                              <div
                                                className="d-flex align-items-center"
                                                style={{
                                                  paddingBottom: "0rem",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    productDataID[
                                                      filteredOrder.data
                                                        .product_name
                                                    ]
                                                  }
                                                  alt="product"
                                                  style={{
                                                    height: "70px",
                                                    width: "93px",
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              {
                                                filteredOrder.data
                                                  .subscription_id
                                              }
                                            </td>
                                            <td>
                                              {filteredOrder.data.product_name}
                                            </td>
                                            <td>{filteredOrder.data.price}</td>
                                            <td>
                                              {filteredOrder.data.quantity *
                                                filteredOrder.data.price}
                                            </td>
                                            <td>
                                              {editingRowOrd === index ? (
                                                <input
                                                  type="number"
                                                  value={quantityOrd}
                                                  onChange={(e) =>
                                                    setQuantityOrd(
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{ width: "60px" }}
                                                />
                                              ) : (
                                                filteredOrder.data.quantity
                                              )}
                                            </td>
                                            <td>
                                              {filteredOrder.data.status ===
                                              "2" ? (
                                                <div
                                                  className="badge badge-pill badge-outline-danger"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  Cancelled
                                                </div>
                                              ) : filteredOrder.data.status ===
                                                "1" ? (
                                                <div
                                                  className="badge badge-pill badge-outline-success"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  Delivered
                                                </div>
                                              ) : (
                                                <div
                                                  className="badge badge-pill badge-outline-info"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  New
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {activeTab === "Subscription" ? (
                <>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="home-tab">
                        <div class="d-sm-flex align-items-center justify-content-between">
                          <ul class="nav nav-tabs" role="tablist"></ul>
                          <div>
                            <div class="btn-wrapper">
                              {permissible_roles.includes("create_subscription") ? (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => openSubscription()}
                                  >
                                    <i class="icon-plus"></i>Add Subscription
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => rolePermission()}
                                  >
                                    <i class="icon-plus"></i>Add Subscription
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">Subscriptions</h4>
                        <div class="">
                          {subscriptionData.map(({ id, subs }, index) => (
                            <div
                              class="tab-pane"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                              key={index}
                            >
                              <div
                                className={`card mb-2 ${
                                  subs.status === "1"
                                    ? "custom-green"
                                    : "custom-red"
                                }`}
                              >
                                <div className="card-body">
                                  <div
                                    class=""
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {/* <div style={{ backgroundColor: "", display: "flex" }}> */}
                                    <div class="">
                                      {/* <p>{subs.product_name}</p> */}
                                      <img
                                        class="rounded"
                                        src={productDataID[subs.product_name]}
                                        alt="product image"
                                        style={{
                                          height: "6rem",
                                          marginBottom: "10px",
                                        }}
                                      />
                                      <h5
                                        class="mt-0"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "14px",
                                        }}
                                      >
                                        ₹ {subs.price}
                                      </h5>
                                    </div>
                                    <div>
                                      <h4 class="mt-0">{subs.product_name}</h4>
                                      <p style={{ marginTop: "20px" }}>
                                        {/* <b>Delivered By</b>: {subs.delivered_by} */}
                                        <b>Subscription ID</b>:{" "}
                                        {subs.subscription_id}
                                      </p>
                                      {/* <p>
                                                                                    <b style={{ marginLeft: '10px' }}>Interval Days</b> : {subs.interval}</p> */}
                                      <p>
                                        <b>Start Date</b>:{" "}
                                        {subs.start_date
                                          ? moment(
                                              subs.start_date.toDate()
                                            ).format("DD-MM-YYYY")
                                          : ""}
                                      </p>
                                      <p>
                                        {subs.end_date === "" ||
                                        subs.end_date === null ? (
                                          <></> // Render nothing if end_date is empty or null
                                        ) : (
                                          <>
                                            {moment(
                                              subs.end_date.toDate()
                                            ).format("DD-MM-YYYY") !==
                                              "01-01-3000" &&
                                              moment(
                                                subs.end_date.toDate()
                                              ).format("DD-MM-YYYY") !==
                                                "31-12-3000" && (
                                                <>
                                                  <b>End Date</b>:{" "}
                                                  {moment(
                                                    subs.end_date.toDate()
                                                  ).format("DD-MM-YYYY")}
                                                </>
                                              )}
                                          </>
                                        )}
                                      </p>
                                      {/* <b>Address</b>: {subs.delivering_to} */}
                                    </div>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {subs.subscription_type !=
                                          "Custom" ? (
                                            <button
                                              type="button"
                                              class="btn btn-success btn-rounded btn-sm"
                                              style={{
                                                color: "white",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                background: "#4a54ba",
                                              }}
                                            >
                                              Quantity : {subs.quantity}
                                            </button>
                                          ) : (
                                            <>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  marginTop: "1rem",
                                                  justifyContent:
                                                    "space-around",
                                                }}
                                              >
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  S
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  M
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  T
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  W
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  T
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  F
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-primary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  S
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  marginTop: "1rem",
                                                  justifyContent:
                                                    "space-around",
                                                }}
                                              >
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.sunday}
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.monday}
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.tuesday}
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.wednesday}
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.thursday}
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.friday}
                                                </div>
                                                <div
                                                  class="badge badge-pill badge-outline-secondary"
                                                  style={{
                                                    marginRight: "1rem",
                                                  }}
                                                >
                                                  {subs.saturday}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          {subs.subscription_type ===
                                          "One-Time" ? (
                                            <></>
                                          ) : (
                                            <button
                                              type="button"
                                              class="btn btn-success btn-rounded btn-sm"
                                              style={{
                                                color: "#fffff",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                background: "#4a54ba",
                                              }}
                                            >
                                              Sub Type :{" "}
                                              {subs.subscription_type.toUpperCase()}
                                            </button>
                                          )}
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          {subs.subscription_type ===
                                          "On-Interval" ? (
                                            <button
                                              type="button"
                                              class="btn btn-success btn-rounded btn-sm"
                                              style={{
                                                color: "#fffff",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                background: "#4a54ba",
                                              }}
                                            >
                                              Interval Days : {subs.interval}
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <br />

                                    {subs.subscription_type != "One Time" ? (
                                      <div>
                                        {permissible_roles.includes(
                                          "edit_subscription"
                                        ) ? (
                                          <>
                                            <label className="toggle-switch-sub">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  subs.status == "0"
                                                    ? false
                                                    : true
                                                }
                                                onChange={() =>
                                                  changeSubcriptionStatus(
                                                    id,
                                                    subs
                                                  )
                                                }
                                              />
                                              <span className="slider-sub round-sub"></span>
                                            </label>{" "}
                                          </>
                                        ) : (
                                          <>
                                            <label className="toggle-switch-sub">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  subs.status == "0"
                                                    ? false
                                                    : true
                                                }
                                                onChange={() =>
                                                  rolePermission()
                                                }
                                              />
                                              <span className="slider-sub round-sub"></span>
                                            </label>
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {permissible_roles.includes(
                                      "edit_subscription"
                                    ) ? (
                                      <>
                                        <div>
                                          {subs.subscription_type !=
                                          "One Time" ? (
                                            <button
                                              style={{
                                                padding: "0.2rem 0.85rem",
                                              }}
                                              class="btn btn-sm"
                                              onClick={() =>
                                                opensedit(subs, id)
                                              }
                                            >
                                              <i
                                                class="menu-icon mdi mdi-pencil"
                                                style={{ color: "#fff" }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                          <button
                                            style={{
                                              padding: "0.2rem 0.85rem",
                                            }}
                                            class="btn btn-sm"
                                            onClick={() => deleteProduct(id)}
                                          >
                                            <i
                                              class="menu-icon mdi mdi-delete"
                                              style={{ color: "#fff" }}
                                            ></i>
                                          </button>
                                          {subs.subscription_type !=
                                          "One Time" ? (
                                            <button
                                              style={{
                                                padding: "0.2rem 0.85rem",
                                              }}
                                              class="btn btn-sm"
                                              onClick={() =>
                                                openbulkQt(
                                                  subs.subscription_id,
                                                  subs.product_name,
                                                  subs
                                                )
                                              }
                                            >
                                              <i
                                                class="menu-icon mdi mdi-settings"
                                                style={{ color: "#fff" }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                          {subs.subscription_type !=
                                          "One Time" ? (
                                            <button
                                              style={{
                                                marginRight: "1rem",
                                                padding: "0.2rem 0.85rem",
                                              }}
                                              onClick={() =>
                                                shiftByDay(
                                                  subs.next_delivery_date,
                                                  id
                                                )
                                              }
                                              class="btn btn-sm"
                                            >
                                              <i
                                                class="menu-icon mdi mdi-share"
                                                style={{ color: "#fff" }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          {subs.subscription_type !=
                                          "One Time" ? (
                                            <button
                                              style={{
                                                padding: "0.2rem 0.85rem",
                                              }}
                                              class="btn btn-sm"
                                              onClick={() => rolePermission()}
                                            >
                                              <i
                                                class="menu-icon mdi mdi-pencil"
                                                style={{ color: "#fff" }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                          <button
                                            style={{
                                              padding: "0.2rem 0.85rem",
                                            }}
                                            class="btn btn-sm"
                                            onClick={() => rolePermission()}
                                          >
                                            <i
                                              class="menu-icon mdi mdi-delete"
                                              style={{ color: "#fff" }}
                                            ></i>
                                          </button>
                                          {subs.subscription_type !=
                                          "One Time" ? (
                                            <button
                                              style={{
                                                padding: "0.2rem 0.85rem",
                                              }}
                                              class="btn btn-sm"
                                              onClick={() => rolePermission()}
                                            >
                                              <i
                                                class="menu-icon mdi mdi-settings"
                                                style={{ color: "#fff" }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                          {subs.subscription_type !=
                                          "One Time" ? (
                                            <button
                                              style={{
                                                marginRight: "1rem",
                                                padding: "0.2rem 0.85rem",
                                              }}
                                              onClick={() => rolePermission()}
                                              class="btn btn-sm"
                                            >
                                              <i
                                                class="menu-icon mdi mdi-share"
                                                style={{ color: "#fff" }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {activeTab === "Vacation" ? (
                <>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="home-tab">
                        <div class="d-sm-flex align-items-center justify-content-between">
                          <ul class="nav nav-tabs" role="tablist"></ul>
                          <div>
                            <div class="btn-wrapper">
                              {permissible_roles.includes("add_vacation") ? (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => addVacation()}
                                  >
                                    <i class="icon-add"></i>Add Vacation
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => rolePermission()}
                                  >
                                    <i class="icon-add"></i>Add Vacation
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">CURRENT VACATIONS</h4>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="pt-1 ps-0">Sr.No</th>
                                <th class="pt-1">Start Date</th>
                                <th class="pt-1">End Date</th>
                                <th class="pt-1">Source</th>

                                <th class="pt-1">Created On</th>
                                <th class="pt-1">Updated On</th>
                                <th class="pt-1">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {vacationData?.map(({ id, data }, index) => (
                                <>
                                  <tr key={id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {moment(
                                        data.start_date.toDate().toISOString()
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      {moment(
                                        data.end_date.toDate().toISOString()
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>{data.source}</td>
                                    <td>
                                      {moment(
                                        data.created_date.toDate().toISOString()
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                      {moment(
                                        data.updated_date.toDate().toISOString()
                                      ).format("DD-MM-YYYY")}
                                    </td>

                                    <td>
                                      {permissible_roles.includes(
                                        "edit_vacation"
                                      ) ? (
                                        <>
                                          <button
                                            style={{
                                              marginRight: "1rem",
                                              padding: "0.2rem 0.85rem",
                                            }}
                                            onClick={() =>
                                              editVacation(data, id)
                                            }
                                            class="btn btn-dark btn-sm"
                                          >
                                            <i
                                              class="menu-icon mdi mdi-pencil"
                                              style={{ color: "white" }}
                                            ></i>
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            style={{
                                              marginRight: "1rem",
                                              padding: "0.2rem 0.85rem",
                                            }}
                                            onClick={() => rolePermission()}
                                            class="btn btn-dark btn-sm"
                                          >
                                            <i
                                              class="menu-icon mdi mdi-pencil"
                                              style={{ color: "white" }}
                                            ></i>
                                          </button>
                                        </>
                                      )}

                                      <button
                                        style={{
                                          marginRight: "1rem",
                                          padding: "0.2rem 0.85rem",
                                        }}
                                        class="btn btn-dark btn-sm"
                                        onClick={() => deleteVacation(id)}
                                      >
                                        <i
                                          class="menu-icon mdi mdi-delete"
                                          style={{ color: "white" }}
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {activeTab === "Tickets" ? (
                <>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="home-tab">
                        <div class="d-sm-flex align-items-center justify-content-between">
                          <ul class="nav nav-tabs" role="tablist"></ul>
                          <div>
                            <div class="btn-wrapper">
                              {permissible_roles.includes("add_ticket") ? (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => addTickets()}
                                  >
                                    <i class="icon-add"></i>Add Tickets
                                  </a>
                                </>
                              ) : (
                                <>
                                  <a
                                    href="#"
                                    class="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => rolePermission()}
                                  >
                                    <i class="icon-add"></i>Add Tickets
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title">Tickets</h4>
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th class="pt-1 ps-0">Ticket ID</th>
                                <th class="pt-1">Category</th>

                                <th class="pt-1">Source</th>
                                <th class="pt-1">Description</th>

                                <th class="pt-1">Status</th>
                                <th class="pt-1">Created Date Time</th>
                                <th class="pt-1">Due date</th>
                                <th class="pt-1">Priority</th>
                                <th class="pt-1">Ticket Owner</th>
                                <th class="pt-1">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {console.log(ticketsData)}
                              {ticketsData?.map(({ id, data }, index) => (
                                <>
                                  <tr>
                                    <td>{data.ticket_id}</td>
                                    <td>{data.category}</td>
                                    <td>{data.source}</td>
                                    <td>{data.description}</td>

                                    <td>
                                      <label class="badge badge-success">
                                        {data.status}
                                      </label>
                                    </td>
                                    <td>
                                      {moment(
                                        data.created_date.toDate().toISOString()
                                      ).format("DD-MM-YYYY, h:mm a")}
                                    </td>
                                    <td>
                                      {moment(
                                        data.due_date.toDate().toISOString()
                                      ).format("DD-MM-YYYY")}
                                    </td>
                                    <td>{data.priority}</td>
                                    <td>{data.owner}</td>
                                    <td>
                                      <button
                                        style={{
                                          marginRight: "1rem",
                                          padding: "0.2rem 0.85rem",
                                        }}
                                        onClick={() => editTickets(data, id)}
                                        class="btn btn-dark btn-sm"
                                      >
                                        <i
                                          class="menu-icon mdi mdi-pencil"
                                          style={{ color: "white" }}
                                        ></i>
                                      </button>
                                      <button
                                        style={{
                                          marginRight: "1rem",
                                          padding: "0.2rem 0.85rem",
                                        }}
                                        class="btn btn-dark btn-sm"
                                        onClick={() => deleteTickets(id)}
                                      >
                                        <i
                                          class="menu-icon mdi mdi-delete"
                                          style={{ color: "white" }}
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {activeTab === "Activities" ? (
                <>
                  <div class="">
                    <div class="col-sm-12">
                      <div class="home-tab">
                        <div class="d-sm-flex align-items-center justify-content-between">
                          <ul class="nav nav-tabs" role="tablist"></ul>
                          <div>
                            <div class="btn-wrapper">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="From Date"
                                className="form-control"
                              />
                              <span className="" style={{ width: "1rem" }}>
                                {" "}
                                TO{" "}
                              </span>
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="To Date"
                                className="form-control"
                              />
                              <a
                                href="#"
                                class="btn btn-primary text-white me-0 mr-2"
                                onClick={() => handleDateRange()}
                                style={{ marginTop: "9px", marginLeft: "14px" }}
                              >
                                <i class="icon-search"></i>Search
                              </a>
                              <a
                                href="#"
                                class="btn btn-primary text-white me-0 mr-2"
                                onClick={() => resetActivities()}
                                style={{ marginTop: "9px", marginLeft: "14px" }}
                              >
                                <i class="icon-reset"></i>Reset
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <a href="#" class="btn btn-primary text-white me-0" onClick={handleDateRange}><i class="icon-search"></i>Search</a> */}
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Activities History</h4>
                      <div class="container-fluid">
                        <div class="row example-basic">
                          <div class="col-xs-12 col-xs-offset-1 col-sm-12 col-sm-offset-2">
                            <ul class="timeline">
                              {activitiesData?.map(({ id, data }, index) => (
                                <>
                                  <li class="timeline-item">
                                    <div class="timeline-info">
                                      <span>
                                        {moment(
                                          data.created_date
                                            .toDate()
                                            .toISOString()
                                        ).format("DD-MM-YYYY, h:mm a")}
                                      </span>
                                    </div>
                                    <div class="timeline-marker"></div>
                                    <div class="timeline-content">
                                      {/* <h3 class="timeline-title">Event Title</h3> */}
                                      <p>{data.description} {data.platform && <span>platform: {data.platform}</span>}</p>
                                      {data.changes && (
                                        <div>
                                          <h5>Changes:</h5>
                                          <ul>
                                            {data.changes && (
                                              <div>
                                                <ul>
                                                  {Object.keys(
                                                    data.changes
                                                  ).map((key) => (
                                                    <li key={key}>
                                                      <strong>{key}:</strong>
                                                      <span>
                                                        {key === "due_date"
                                                          ? (() => {
                                                              try {
                                                                const oldDate =
                                                                  moment(
                                                                    data.changes[
                                                                      key
                                                                    ].old
                                                                      .toDate()
                                                                      .toISOString()
                                                                  ).format(
                                                                    "DD-MM-YYYY, h:mm a"
                                                                  );
                                                                const newDate =
                                                                  moment(
                                                                    data.changes[
                                                                      key
                                                                    ].new
                                                                      .toDate()
                                                                      .toISOString()
                                                                  ).format(
                                                                    "DD-MM-YYYY, h:mm a"
                                                                  );
                                                                return `old: ${oldDate}, new: ${newDate}`;
                                                              } catch (error) {
                                                                return `old: ${data.changes[key].old}, new: ${data.changes[key].new}`;
                                                              }
                                                            })()
                                                          : `old: ${data.changes[key].old}, new: ${data.changes[key].new}`}
                                                      </span>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                </>
                              ))}
                              <li class="timeline-item period"></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {activeTab === "conversastion_logs" ? <>
              <ConversastionLogs customer_data={data}/>
              </>:<></>}

             {activeTab === "call_logs" ? <>
              <CallLogs customer_data={data}/>
             </>:<></>}

              {activeTab === "email_logs" ? <>
              <EmailLogs customer_data={data}/>
              </>:<></>}

              {activeTab === "Wallet" ? (
                <>
                  <div class="">
                    <div class="col-sm-12">
                      <div class="home-tab">
                        <div class="d-sm-flex align-items-center justify-content-between">
                          <ul class="nav nav-tabs" role="tablist"></ul>
                          <div>
                            <div class="btn-wrapper">
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="From Date"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                              <span className="" style={{ width: "1rem" }}>
                                {" "}
                                TO{" "}
                              </span>
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="To Date"
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                              />
                              <a
                                href="#"
                                class="btn btn-primary text-white me-0 mr-2"
                                onClick={() => handleDateRangeWallet()}
                                style={{ marginTop: "9px", marginLeft: "14px" }}
                              >
                                <i class="icon-search"></i>Search
                              </a>
                              <a
                                href="#"
                                class="btn btn-primary text-white me-0 mr-2"
                                onClick={() => resetWallet()}
                                style={{ marginTop: "9px", marginLeft: "14px" }}
                              >
                                <i class="icon-reset"></i>Reset
                              </a>
                            </div>
                          </div>
                          <div className="d-flex mx-2">
                            <div class="btn-wrapper">
                              {walletData ? (
                                <a href="#">
                                  <ExportWallet
                                    tableId="waller_history_table"
                                    fileName="wallet_history"
                                    buttonText="Export Wallet"
                                  />
                                </a>
                              ) : (
                                <></>
                              )}
                            </div>
                            {walletData ? (
                              <>
                                <PDFDownloadLink
                                  document={
                                    <ReportPdf
                                      wallet_data={filteredData}
                                      customer_data={data}
                                      start_date={startDate}
                                      end_date={endDate}
                                    />
                                  }
                                  fileName={`${data.data.customer_name}_wallet_transaction.pdf`}
                                >
                                  <div className="btn btn-primary text-white me-0 mr-2">
                                    <span>Generate Statement</span>
                                  </div>
                                </PDFDownloadLink>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                    <div className="col-md-12 grid-margin grid-margin-md-0 stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">Wallet History</h4>

                          {/* Filter dropdown */}
                          <div className="form-group d-flex  justify-content-between">
                            <div>
                              <label htmlFor="filter">Filter by Type:</label>
                              <select
                                id="filter"
                                className="form-control"
                                onChange={handleFilterChange}
                                style={{ width: "10rem" }}
                              >
                                <option value="all">All</option>
                                <option value="debit">Debit</option>
                                <option value="credit">Credit</option>
                              </select>
                            </div>

                            {username === "shruti" ? (
                              <>
                                <div>
                                  <button
                                    className="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => {
                                      reconsileWallet(params.id, filteredData);
                                    }}
                                  >
                                    Reconsile Wallet
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="btn btn-primary text-white me-0 mr-2"
                                    onClick={() => {
                                      setShowTransactionForm(
                                        !showTransactionForm
                                      );
                                    }}
                                  >
                                    Add missing transaction
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          {showTransactionForm ? (
                            <>
                              <AddTransaction
                                customer_data={data}
                                setShowTransaction={setShowTransactionForm}
                              />
                            </>
                          ) : (
                            <></>
                          )}

                          {permissible_roles.includes("wallet_transactions") ? (
                            <>
                              <div className="table-responsive">
                                <table
                                  className="table"
                                  id="waller_history_table"
                                >
                                  <thead>
                                    <tr>
                                      <th className="pt-1 ps-0">Sr.No</th>
                                      <th className="pt-1">Transaction Id</th>
                                      <th className="pt-1">Amount</th>
                                      <th className="pt-1">Type</th>
                                      <th className="pt-1">Ledger Balance</th>
                                      <th className="pt-1">Reason</th>
                                      <th className="pt-1">Description</th>
                                      <th className="pt-1">Source</th>
                                      <th className="pt-1">Created On</th>
                                      <th className="pt-1">User</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {filteredData?.map(
                                      ({ id, data }, index) => (
                                        <tr key={id}>
                                          <td>{index + 1}</td>
                                          <td>{data.txn_id}</td>
                                          <td>{data.amount}</td>
                                          <td
                                            style={{
                                              color:
                                                data.type === "debit" ||
                                                data.type === "Debit"
                                                  ? "red"
                                                  : "green",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {data.type}
                                          </td>
                                          <td>{data.current_wallet_balance}</td>
                                          <td>{data.reason}</td>
                                          <td>{data.description}</td>
                                          <td>{data.source}</td>
                                          <td>
                                            {moment(
                                              data.created_date
                                                .toDate()
                                                .toISOString()
                                            ).format("DD-MM-YYYY, h:mm a")}
                                          </td>
                                          <td>{data.user}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : (
                            <>Permission Denied</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            <Footer />
          </div>
        </div>
      </div>
      <div
        class="modal cw fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Wallet
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="myForm" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Amount:</label>
                  <input
                    className="form-control"
                    type="number"
                    onChange={handleChange}
                    id="amount"
                    value={submit.amount}
                    required
                    autoComplete="off"
                    min="1"
                  />
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <textarea
                    className="form-control"
                    rows="2"
                    cols="30"
                    onChange={handleChange}
                    id="description"
                    value={submit.description}
                    style={{ height: "4rem" }}
                  />
                </div>
                <div className="form-group">
                  <label>Reason:</label>
                  <select
                    className="form-control"
                    onChange={handleChange}
                    id="reason"
                    value={submit.reason}
                    required
                  >
                    <option value="">Select Reason</option>
                    {reason.map((data, index) => (
                      <option key={index} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer">
                  <button
                    type="submit"
                    value="submit"
                    className="btn btn-success"
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? "Processing..." : "Submit"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal cc fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Credit Limit
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="myForm" onSubmit={handleSubmitCredit}>
                <div className="form-group">
                  <label>Credit Limit:</label>
                  <input
                    className="form-control"
                    type="number"
                    onChange={handleChangeCredit}
                    id="credit_limit"
                    value={submitCredit.credit_limit}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      id="send_notification"
                      checked={submitCredit.send_notification}
                      onChange={handleChangeCredit}
                    />
                    &nbsp; Send Notification
                  </label>
                </div>

                <div className="modal-footer">
                  <button
                    type="submit"
                    value="submit"
                    className="btn btn-success"
                    disabled={loadingCredit} // Disable the button when loading
                  >
                    {loadingCredit ? "Processing..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal df fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Set Delivery Preferences
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="myForm" onSubmit={handleSubmitDF}>
                <div class="form-group">
                  <label>Delivery Mode</label>
                  <select
                    class="form-control"
                    onChange={handleChangeDF}
                    id="delivery_mode"
                    value={submitDF.delivery_mode}
                    required
                  >
                    <option value="">Select Delivery Mode</option>
                    {del_mo.map((data, index) => (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group row">
                  <label>Delivery Preferences:</label>
                  {renderCheckboxPairs()}
                </div>

                <div class="form-group">
                  <label>Additional Instruction:</label>
                  <input
                    class="form-control"
                    type="text"
                    onChange={handleChangeDF}
                    id="additional_instruction"
                    value={submitDF.additional_instruction}
                    autoComplete="off"
                  />
                </div>

                <div class="modal-footer">
                  <button type="submit" value="submit" class="btn btn-success">
                    Submit
                  </button>
                  <button
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal calendar fade" id="exampleModal-2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel-2">Calendar:</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <button style={{ padding: "6px", marginBottom: "4px", marginRight: "4px" }} class="btn btn-dark"><i class="menu-icon mdi mdi-calendar" style={{ color: "white" }}></i></button>
                            <DatePicker
                                selected={startCalendarDate}
                                className="form-control"
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                            />

                            <div className="table-responsive mt-4">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="pt-1">Category</th>
                                            <th className="pt-1">Product</th>
                                            <th className="pt-1">Quantity</th>
                                            <th className="pt-1">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(subscriptionCalendarData) && subscriptionCalendarData.some(subArr => Array.isArray(subArr) && subArr.length > 0) ? (
                                            subscriptionCalendarData.map((subArr, index) => (
                                                subArr.map((item, subIndex) => (
                                                    <tr key={subIndex}>
                                                        <td>Dairy</td>
                                                        <td>{item.product_name}</td>

                                                        <td>
                                                            {selectedIndex === subIndex ?
                                                                <>
                                                                    <input
                                                                        type="number"
                                                                        value={newQuantity}
                                                                        onChange={handleQuantityUpdate}
                                                                    // value={item.quantity}
                                                                    // onChange={(e) => updateSubscriptionQuantityInFirestore('2024-04-01', '59223388', 4)}
                                                                    />
                                                                    <button style={{ padding: "4px", marginBottom: "4px", marginRight: "4px", marginLeft: "4px" }} onClick={() => handleQuantityUpdateSave(item.subscription_id)} className="btn btn-dark">Save</button>
                                                                </>
                                                                :
                                                                <>
                                                                    {item.quantity}
                                                                    <button style={{ marginRight: "1rem", padding: "0.0rem 0.5rem", marginLeft: "1rem" }} class="btn btn-dark btn-sm" onClick={() => handleSaveIndex(subIndex)}><i class="menu-icon mdi mdi-pencil" style={{ color: "white", fontSize: "12px" }}></i></button>

                                                                </>
                                                            }

                                                        </td>

                                                        <td>{item.price}</td>
                                                    </tr>
                                                ))
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" style={{ textAlign: "center" }}>No data found for selected date.</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div className="modal calendar fade" id="exampleModal-2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel-2" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel-2">Calendar:</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <button style={{ padding: "6px", marginBottom: "4px", marginRight: "4px" }} class="btn btn-dark"><i class="menu-icon mdi mdi-calendar" style={{ color: "white" }}></i></button>
                            <DatePicker
                                selected={startCalendarDate}
                                className="form-control"
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                            />

                            <div className="table-responsive mt-4">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="pt-1">Category</th>
                                            <th className="pt-1">Product</th>
                                            <th className="pt-1">Quantity</th>
                                            <th className="pt-1">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(subscriptionCalendarData) && subscriptionCalendarData.length > 0 ? (
                                            subscriptionCalendarData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>Dairy</td>
                                                    <td>{item.subs.product_name}</td>
                                                    <td>
                                                        {selectedIndex === index ?
                                                            <>
                                                                <input
                                                                    type="number"
                                                                    value={newQuantity}
                                                                    onChange={handleQuantityUpdate}
                                                                />
                                                                <button style={{ padding: "4px", marginBottom: "4px", marginRight: "4px", marginLeft: "4px" }} onClick={() => handleQuantityUpdateSave(item.id)} className="btn btn-dark">Save</button>
                                                            </>
                                                            :
                                                            <>
                                                                {item.subs.quantity}
                                                                <button style={{ marginRight: "1rem", padding: "0.0rem 0.5rem", marginLeft: "1rem" }} className="btn btn-dark btn-sm" onClick={() => handleSaveIndex(index)}><i className="menu-icon mdi mdi-pencil" style={{ color: "white", fontSize: "12px" }}></i></button>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>{item.subs.price}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5" style={{ textAlign: "center" }}>No data found for selected date.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div
        className="modal calendar fade"
        id="exampleModal-2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel-2">
                Calendar
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="panel">
                <div className="datepicker-container">
                  <label className="datepicker-label">Delivery Date:</label>
                  <DatePicker
                    selected={calendarDate}
                    minDate={getTomorrowDate()}
                    onChange={handleCalendarDateChange}
                    dateFormat="dd/MM/yyyy"
                    className="datepicker-input"
                    placeholderText="Select date"
                  />
                </div>
              </div>
              <div className="table-responsive mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="pt-1">Category</th>
                      <th className="pt-1">Product</th>
                      <th className="pt-1">Quantity</th>
                      <th className="pt-1">Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(subscriptionCalendarFutureData) &&
                    subscriptionCalendarFutureData.length > 0 ? (
                      subscriptionCalendarFutureData.map(
                        ({ id, subs }, index) => (
                          <tr key={index}>
                            <td>Dairy</td>
                            <td>{subs.product_name}</td>
                            <td>
                              {editingRow === index ? (
                                <input
                                  type="number"
                                  value={quantityFU}
                                  onChange={(e) =>
                                    setQuantityFU(e.target.value)
                                  }
                                  style={{ width: "60px" }}
                                />
                              ) : (
                                subs.quantity
                              )}
                            </td>
                            <td>
  {editingRow === index ? (
    <button
      onClick={() => handleSaveClick(id, subs.subscription_id, subs.product_name)}
      disabled={isSaving} // Disable button during save
    >
      {isSaving ? (
        <div
          className="spinner-border"
          style={{ width: "1rem", height: "1rem", color: "white" }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        "Save"
      )}
    </button>
  ) : (
    <i
      className="menu-icon mdi mdi-pencil"
      onClick={() => handleEditClick(index, subs.quantity, id)}
      style={{
        color: "blue",
        cursor: "pointer",
        fontSize: "23px",
        paddingLeft: "5px",
      }}
    ></i>
  )}
</td>

                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          No data found for selected date.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal cash fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Cash Collection Time
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="myForm" onSubmit={handleSubmitCash}>
                <div className="form-group">
                  <label>Amount:</label>
                  <input
                    className="form-control"
                    type="number"
                    onChange={handleChangeCash}
                    id="amount"
                    value={submitCash.amount}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <label>Date</label>
                  <input
                    type="date"
                    style={{
                      height: "35px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      resize: "none",
                    }}
                    id="date"
                    name="date"
                    onChange={handleChangeCash}
                    value={submitCash.date}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <label>Time</label>
                  <select
                    className="form-control"
                    onChange={handleChangeCash}
                    id="time"
                    value={submitCash.time}
                    required
                  >
                    <option value="">Select Time</option>
                    <option value="06:00 to 09:30">06:00 to 09:30</option>
                  </select>
                </div>

                <div className="modal-footer">
                  <button
                    type="submit"
                    value="submit"
                    className="btn btn-success"
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? "Processing..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal srl fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Send Recharge Link
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="myForm" onSubmit={handleSubmitSRL}>
                {/* <p style={{ fontWeight: "500" }}>Are you sure you want to send Recharge link?</p> */}
                <div className="form-group">
                  <label>Enter Amount:</label>
                  <input
                    className="form-control"
                    type="number"
                    onChange={handleChangeSRL}
                    id="amount"
                    value={submitSRL.amount}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group row" style={{ display: "flex" }}>
                  <div className="col">
                    <label style={{ paddingRight: "11px" }}>Email:</label>
                    <input
                      type="checkbox"
                      id="email"
                      checked={submitSRL.email}
                      onChange={handleChangeSRL}
                    />
                  </div>
                  <div className="col">
                    <label style={{ paddingRight: "11px" }}>SMS:</label>
                    <input
                      type="checkbox"
                      id="sms"
                      checked={submitSRL.sms}
                      onChange={handleChangeSRL}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    data-type="copy"
                    className="btn btn-success"
                  >
                    Copy Recharge Link
                  </button>
                  <button
                    type="submit"
                    data-type="send"
                    className="btn btn-success"
                  >
                    Send
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>

              {/* <form class="myForm" onSubmit={handleSubmitSRL}>
                                <p style={{ fontWeight: "500" }}>Are you sure you want to send Recharge link?</p>
                                <div class="form-group">
                                    <label>Enter Amount:</label>
                                    <input class="form-control" type="number" onChange={handleChangeSRL} id="amount" value={submitSRL.amount} required autoComplete="off" />
                                </div>

                                <div class="form-group row" style={{ display: "flex" }}>
                                    <div class="col">
                                        <label style={{ paddingRight: "11px" }}>Email:</label>
                                        <input type="checkbox" id="email" checked={submitSRL.email} onChange={handleChangeSRL} />
                                    </div>
                                    <div class="col">
                                        <label style={{ paddingRight: "11px" }}>SMS:</label>
                                        <input type="checkbox" id="sms" checked={submitSRL.sms} onChange={handleChangeSRL} />
                                    </div>
                                </div>



                                <div class="modal-footer">
                                    <button type="submit" value="submit" class="btn btn-success">Copy Recharge Link</button>
                                    <button type="submit" value="submit" class="btn btn-success">Send</button>
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </form> */}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal vacation fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Add Vacation
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="myForm" onSubmit={handleSubmitVacation}>
                <div class="form-group">
                  <label htmlFor="start_date">Start Date</label>
                  <input
                    type="date"
                    style={{
                      height: "35px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      resize: "none",
                    }}
                    id="start_date"
                    name="start_date"
                    min={(() => {
                      const today = new Date();
                      today.setDate(today.getDate() + 1); // Set to tomorrow
                      return today.toISOString().split("T")[0];
                    })()}
                    value={submitVacation.start_date}
                    onChange={handleChangeVacation}
                    class="form-control"
                    
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    type="date"
                    style={{
                      height: "35px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      resize: "none",
                    }}
                    id="end_date"
                    name="end_date"
                    min={(() => {
                      // Get today's date
                      const today = new Date();
                      // Add one day to today's date
                      today.setDate(today.getDate() + 1);
                      // Get the selected start date
                      const startDate = submitVacation.start_date
                        ? new Date(submitVacation.start_date)
                        : today;
                      // Calculate the minimum end date as the later of tomorrow or the selected start date plus one day
                      return startDate.toISOString().split("T")[0];
                    })()}
                    max={currentYearEnd} // End of the current year
                    value={submitVacation.end_date}
                    onChange={handleChangeVacation}
                    class="form-control"
                  />
                </div>

                <div class="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Processing..." : "Submit"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal tickets fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Add Ticket
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="myForm" onSubmit={handleSubmitTickets}>
                <div class="form-group">
                  <label>Category</label>
                  <select
                    class="form-select"
                    onChange={handleChangeTickets}
                    id="category"
                    value={submitTickets.category}
                  >
                    <option>Select category</option>
                    <option value="Support">Support</option>
                    <option value="Feedback">Feedback</option>
                    <option value="Complaint">Complaint</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Status</label>
                  <select
                    class="form-select"
                    onChange={handleChangeTickets}
                    id="status"
                    value={submitTickets.status}
                  >
                    <option>Select Status</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                    <option value="In Process">In Process</option>
                    <option value="Not Started">Not Started</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Priority</label>
                  <select
                    class="form-select"
                    onChange={handleChangeTickets}
                    id="priority"
                    value={submitTickets.priority}
                  >
                    <option>Select Priority</option>
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Due Date</label>
                  <input
                    type="date"
                    style={{
                      height: "35px",
                      padding: "12px 20px",
                      boxSizing: "border-box",
                      borderRadius: "4px",
                      resize: "none",
                    }}
                    id="due_date"
                    name="due_date"
                    onChange={handleChangeTickets}
                    value={submitTickets.due_date}
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Owner</label>
                  <select
                    class="form-select"
                    onChange={handleChangeTickets}
                    id="owner"
                    value={submitTickets.owner}
                  >
                    <option>Select Owner</option>
                    <option value="Shruti">Shruti</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    rows="2"
                    cols="30"
                    onChange={handleChangeTickets}
                    id="description"
                    value={submitTickets.description}
                    style={{ height: "4rem" }}
                  />
                </div>

                <div class="form-group">
                  <label>Visible On</label>
                  <select
                    class="form-select"
                    onChange={handleChangeTickets}
                    id="visible_on"
                    value={submitTickets.visible_on}
                  >
                    <option>Select Visibility</option>
                    <option value="Internal">Internal</option>
                    <option value="Internal & External">
                      Internal & External
                    </option>
                  </select>
                </div>

                <div class="modal-footer">
                  <button type="submit" value="submit" class="btn btn-success">
                    Submit
                  </button>
                  <button
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal subscription fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {showModal == "addSubscription"
                  ? "Subscription"
                  : "Adhoc Order"}
              </h5>

              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                paddingRight: "11px",
              }}
            >
              <div class="btn-wrapper"></div>
            </div>
            <div class="modal-body" style={{ padding: "8px 8px" }}>
              <div class="row">
                <div class="col-md-7 grid-margin grid-margin-md-0 stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <div
                        class="product-src"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div class="product-src1">
                          <h4 class="card-title">Products </h4>
                        </div>
                        <div class="product-src2">
                          <from>
                            <input
                              type="search"
                              placeholder="Search products"
                              onChange={handleSearchChange}
                            />
                          </from>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="pt-1 ps-0">Image</th>
                              <th class="pt-1 ps-0">Name</th>
                              <th class="pt-1">Price</th>
                              <th class="pt-1">Discount</th>
                              <th class="pt-1">Add</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredProducts?.map(
                              ({ id, data, selectedOption }, index) => (
                                <>
                                  {data.packagingOptions.map(
                                    (option, index) => (
                                      <>
                                        <tr>
                                          <td class="py-1 ps-0">
                                            <div
                                              class="d-flex align-items-center"
                                              style={{ paddingBottom: "1rem" }}
                                            >
                                              <img
                                                src={data.image}
                                                alt="product"
                                                style={{
                                                  height: "70px",
                                                  width: "93px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td>{data.productName}</td>
                                          <td>
                                            ₹ {option.price}.00
                                            <p>
                                              {option.packaging}{" "}
                                              {option.pkgUnit}
                                            </p>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              style={{ width: "5rem" }}
                                              placeholder="Discount"
                                              value={
                                                discounts[
                                                  data.productName +
                                                    option.price
                                                ] || ""
                                              }
                                              onChange={(e) =>
                                                handleDiscountChange(
                                                  data.productName +
                                                    option.price,
                                                  e
                                                )
                                              }
                                            />
                                            <p>
                                              Final Price: ₹{" "}
                                              {calculateFinalPrice(
                                                option.price,
                                                discounts[
                                                  data.productName +
                                                    option.price
                                                ]
                                              )}
                                            </p>
                                          </td>
                                          <td>
                                            {cartItems.findIndex(
                                              (cartItem) =>
                                                cartItem.price === option.price
                                            ) === -1 ? (
                                              <label
                                                class="badge badge-success"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  handleAddToCart({
                                                    package_unit: `${option.packaging} ${option.pkgUnit}`,
                                                    product_name:
                                                      data.productName,
                                                    price: calculateFinalPrice(
                                                      option.price,
                                                      discounts[
                                                        data.productName +
                                                          option.price
                                                      ]
                                                    ),
                                                    start_date: "",
                                                    end_date: "",
                                                    subscription_type:
                                                      "Everyday",
                                                    quantity: 1,
                                                  })
                                                }
                                              >
                                                Add
                                              </label>
                                            ) : null}
                                            {/* <label class="badge badge-success" style={{ cursor: "pointer" }} onClick={() => handleAddToCart({ name: "Whyte Farms Ghee,500 ml", price: 700, start_date: '', end_date: '', type: '', quantity: 1, subscription_type: 'Subscription', })}>Add</label> */}
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 grid-margin grid-margin-md-0 stretch-card">
                  <div class="card">
                    {showModal === "addSubscription" ? (
                      <div class="card-body">
                        <h4 class="card-title">Cart Items</h4>

                        {cartItems.map((item, index) => (
                          <div key={index} className="card mb-2">
                            <div className="card-body">
                              <div
                                className="form-group"
                                style={{
                                  marginBottom: "0.5rem",
                                  display: "flex",
                                }}
                              >
                                <div className="col">
                               <p>
                               <DatePicker
                                selected={new Date(startDate)} // Ensure startDate is a valid Date object
                                onChange={(date) => handleChangeCartDate(date, index)}
                                dateFormat="dd-MM-yyyy"
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="From Date"
                                className="form-control"
                                minDate={new Date(new Date().setDate(new Date().getDate() + 1))} // Disable current and past dates
                              />
                                
                                <b>
                                  {item.start_date
                                    ? new Date(item.start_date).toLocaleDateString("en-GB") // Format to dd-MM-yyyy
                                    : "No start date selected"}
                                </b>
                              </p>
                                </div>
                                <div className="col">
                                  {item.subscription_type === "One Time" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <p>
                                      <DatePicker
                                        selected={endDate}
                                        onChange={(date) => handleChangeEndDate(date, index)}
                                        dateFormat="dd-MM-yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        placeholderText="To Date"
                                        className="form-control"
                                        minDate={startDate ? new Date(startDate.getTime() + 24 * 60 * 60 * 1000) : null} // Ensure end date is after start date
                                      />

                                
                                  <b>
                                    {item.end_date
                                      ? new Date(item.end_date).toLocaleDateString("en-GB") // Format to dd-MM-yyyy
                                      : "No end date selected"}
                                  </b>
                                  </p>
                                    </>

                                  )}
                                </div>
                              </div>

                              <p className="mt-2 card-text">
                                Name : {item.product_name}
                              </p>
                              <div className="form-group row">
                                <div class="col">
                                  <p className="mt-2 card-text">
                                    Price : ₹ {item.price}
                                  </p>
                                </div>
                                <div class="col">
                                  <p className="mt-2 card-text">
                                    Quantity :{" "}
                                    <i
                                      class="mdi mdi-minus-circle"
                                      style={{
                                        paddingRight: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleQuantityChange(index, "subtract")
                                      }
                                    ></i>{" "}
                                    {item.quantity}{" "}
                                    <i
                                      class="mdi mdi-plus-circle"
                                      style={{
                                        paddingLeft: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleQuantityChange(index, "add")
                                      }
                                    ></i>
                                  </p>
                                </div>
                              </div>
                              {item.subscription_type === "Everyday" ||
                              item.subscription_type === "On-Interval" ||
                              item.subscription_type === "Custom" ? (
                                <>
                                  <div
                                    className="form-group"
                                    style={{
                                      display: "flex",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    <div className="col">
                                      <label style={{ paddingRight: "11px" }}>
                                        Everyday
                                      </label>
                                      <input
                                        type="radio"
                                        id="Everyday"
                                        value="Everyday"
                                        checked={
                                          item.subscription_type === "Everyday"
                                        }
                                        onChange={(e) =>
                                          handleChangeCart(e, index)
                                        }
                                      />
                                    </div>
                                    <div className="col">
                                      <label style={{ paddingRight: "11px" }}>
                                        On Interval
                                      </label>
                                      <input
                                        type="radio"
                                        id="On-Interval"
                                        value="On-Interval"
                                        checked={
                                          item.subscription_type ===
                                          "On-Interval"
                                        }
                                        onChange={(e) =>
                                          handleChangeCart(e, index)
                                        }
                                      />
                                    </div>
                                    <div className="col">
                                      <label style={{ paddingRight: "11px" }}>
                                        Custom
                                      </label>
                                      <input
                                        type="radio"
                                        id="Custom"
                                        value="Custom"
                                        checked={
                                          item.subscription_type === "Custom"
                                        }
                                        onChange={(e) =>
                                          handleChangeCart(e, index)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "11px",
                                }}
                              >
                                <div
                                  class="btn-wrapper"
                                  style={{ display: "ruby" }}
                                >
                                  {item.subscription_type === "Everyday" ||
                                  item.subscription_type === "On-Interval" ||
                                  item.subscription_type === "Custom" ? (
                                    <>
                                      <a
                                        href="#"
                                        class={
                                          item.subscription_type ===
                                          "Subscription"
                                            ? "btn btn-success text-white me-0 mr-2"
                                            : "btn btn-dark text-white me-0 mr-2"
                                        }
                                        style={{
                                          padding: "4px",
                                          fontSize: "11px",
                                        }}
                                        onClick={() =>
                                          changeCart("Subscription", index)
                                        }
                                      >
                                        <i class="icon-add"></i>Subscription
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href="#"
                                        class={
                                          item.subscription_type ===
                                          "Subscription"
                                            ? "btn btn-success text-white me-0 mr-2"
                                            : "btn btn-dark text-white me-0 mr-2"
                                        }
                                        style={{
                                          padding: "4px",
                                          fontSize: "11px",
                                          textDecoration: "line-through",
                                        }}
                                        onClick={() =>
                                          changeCart("Subscription", index)
                                        }
                                      >
                                        <i class="icon-add"></i>Subscription
                                      </a>
                                    </>
                                  )}
                                  {item.subscription_type === "One Time" ? (
                                    <>
                                      <a
                                        href="#"
                                        class={
                                          item.subscription_type === "One Time"
                                            ? "btn btn-success text-white me-0 mr-2"
                                            : "btn btn-dark text-white me-0"
                                        }
                                        style={{
                                          padding: "4px",
                                          marginLeft: "1rem",
                                          fontSize: "11px",
                                        }}
                                        onClick={() =>
                                          changeCart("One Time", index)
                                        }
                                      >
                                        One Time
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href="#"
                                        class={
                                          item.subscription_type === "One Time"
                                            ? "btn btn-success text-white me-0 mr-2"
                                            : "btn btn-dark text-white me-0"
                                        }
                                        style={{
                                          padding: "4px",
                                          marginLeft: "1rem",
                                          fontSize: "11px",
                                          textDecoration: "line-through",
                                        }}
                                        onClick={() =>
                                          changeCart("One Time", index)
                                        }
                                      >
                                        One Time
                                      </a>
                                    </>
                                  )}

                                  {/* {item.subscription_type === "Subscription" ? <> */}
                                  {item.subscription_type === "On-Interval" ? (
                                    <>
                                      <div class="dropdown">
                                        <button
                                          class="btn btn-danger btn-xs dropdown-toggle"
                                          type="button"
                                          id="dropdownMenuSizeButton3"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                          style={{
                                            height: "22px",
                                            padding: "5px",
                                            marginRight: "14px",
                                            marginLeft: "1rem",
                                          }}
                                        >
                                          {item.interval === ""
                                            ? "Days"
                                            : `Every ${item.interval} day`}
                                        </button>
                                        <div
                                          class="dropdown-menu"
                                          aria-labelledby="dropdownMenuSizeButton3"
                                        >
                                          <h6
                                            class="dropdown-item"
                                            onClick={() =>
                                              handleInterval("2", index)
                                            }
                                          >
                                            Every 2nd day
                                          </h6>
                                          <h6
                                            class="dropdown-item"
                                            onClick={() =>
                                              handleInterval("3", index)
                                            }
                                          >
                                            Every 3rd day
                                          </h6>
                                          <h6
                                            class="dropdown-item"
                                            onClick={() =>
                                              handleInterval("4", index)
                                            }
                                          >
                                            Every 4th day
                                          </h6>
                                          <div class="dropdown-divider"></div>
                                          <h6
                                            class="dropdown-item"
                                            onClick={() =>
                                              handleInterval("nth", index)
                                            }
                                          >
                                            Every nth day
                                          </h6>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {/* </>
                                                                    :
                                                                    <>
                                                                    </>
                                                                } */}

                                  {/* {item.subscription_type === "Subscription" ? <> */}
                                  {item.subscription_type === "On-Interval" ? (
                                    <>
                                      {" "}
                                      {showNth ? (
                                        <>
                                          <input
                                            type="tel"
                                            pattern="[0-9]*"
                                            onChange={(e) =>
                                              handleChangenth(e, index)
                                            }
                                            id="interval"
                                            maxLength={2}
                                            class=""
                                            style={{
                                              width: "2rem",
                                              border: "1px solid #dee2e6",
                                              fontWeight: "400",
                                              borderRadius: "4px",
                                              height: "22px",
                                              marginRight: "1rem",
                                              fontSize: "11px",
                                            }}
                                            placeholder="value"
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* </> : <></>} */}
                                </div>
                                <div>
                                  <i
                                    class="menu-icon mdi mdi-delete"
                                    onClick={() => handleRemoveFromCart(index)}
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "23px",
                                      paddingLeft: "5px",
                                    }}
                                  ></i>
                                </div>
                              </div>
                              {/* {item.subscription_type === "Subscription" ? <> */}
                              {item.subscription_type === "Custom" ? (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "1rem",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      S
                                    </div>
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      M
                                    </div>
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      T
                                    </div>
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      W
                                    </div>
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      T
                                    </div>
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      F
                                    </div>
                                    <div
                                      class="badge badge-pill badge-outline-primary"
                                      style={{ marginRight: "1rem" }}
                                    >
                                      S
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "1rem",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(e, index, "sunday")
                                      }
                                      id="sunday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(e, index, "monday")
                                      }
                                      id="monday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(e, index, "tuesday")
                                      }
                                      id="tuesday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(
                                          e,
                                          index,
                                          "wednesday"
                                        )
                                      }
                                      id="wednesday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(
                                          e,
                                          index,
                                          "thursday"
                                        )
                                      }
                                      id="thursday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(e, index, "friday")
                                      }
                                      id="friday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                    <input
                                      type="tel"
                                      pattern="[0-9]*"
                                      onChange={(e) =>
                                        handleChangenCustom(
                                          e,
                                          index,
                                          "saturday"
                                        )
                                      }
                                      id="saturday"
                                      maxLength={2}
                                      className=""
                                      style={{
                                        width: "2rem",
                                        border: "1px solid #dee2e6",
                                        fontWeight: "400",
                                        borderRadius: "4px",
                                        height: "22px",
                                        marginRight: "1rem",
                                        fontSize: "11px",
                                      }}
                                      placeholder="value"
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {/* </> : <></>} */}
                            </div>
                          </div>
                        ))}
                        <p></p>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          {/* {showCreditButton && <button style={{marginRight:'10px'}} onClick={() => rolePermissions.includes('credit_limit_transaction') ? openCreditFromCheckout() : rolePermission()} className="btn btn-success btn-sm mt-3 mb-4">Credit Limit</button>} */}

                          {cartItems.length === 0 ? (
                            <></>
                          ) : (
                            <>
                              <button
                                onClick={handleCheckout}
                                className="btn btn-info btn-sm mt-3 mb-4"
                              >
                                Checkout
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div class="card-body">
                        <h4 class="card-title">Cart Items</h4>

                        {cartItems.map((item, index) => (
                          <div key={index} className="card mb-2">
                            <div className="card-body">
                              <p className="mt-2 card-text">
                                Name : {item.product_name}
                              </p>
                              <div className="form-group row">
                                <div class="col">
                                  <p className="mt-2 card-text">
                                    Price : ₹ {item.price}
                                  </p>
                                </div>
                                <div class="col">
                                  <p className="mt-2 card-text">
                                    Quantity :{" "}
                                    <i
                                      class="mdi mdi-minus-circle"
                                      style={{
                                        paddingRight: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleQuantityChange(index, "subtract")
                                      }
                                    ></i>{" "}
                                    {item.quantity}{" "}
                                    <i
                                      class="mdi mdi-plus-circle"
                                      style={{
                                        paddingLeft: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleQuantityChange(index, "add")
                                      }
                                    ></i>
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p>
                                  Total Price : {item.price * item.quantity}
                                </p>
                              </div>
                              <br />
                              <div>
                                <DatePicker
                                  selected={adhocDeliveryDate}
                                  onChange={(date) =>
                                    setAdhocDeliveryDate(date)
                                  }
                                  maxDate={new Date()}
                                  dateFormat="dd-MM-yyyy"
                                  className="datepicker-input"
                                  placeholderText="Order Date"
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: "11px",
                                }}
                              >
                                <div></div>
                                <div>
                                  <i
                                    class="menu-icon mdi mdi-delete"
                                    onClick={() => handleRemoveFromCart(index)}
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "23px",
                                      paddingLeft: "5px",
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <p></p>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          {cartItems.length === 0 ? (
                            <></>
                          ) : (
                            <button
                              onClick={handleCheckout}
                              className="btn btn-info btn-sm mt-3 mb-4"
                              disabled={loading} // Disable the button when loading
                            >
                              {loading ? "Processing..." : "Confirm order"}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal creditcheckout fade"
              id="exampleModal-2"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel-2"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel-2">
                      Credit Limit
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <form class="myForm" onSubmit={handleSubmitCredit}>
                      <div class="form-group">
                        <label>Credit Limit:</label>
                        <input
                          class="form-control"
                          type="number"
                          onChange={handleChangeCredit}
                          id="credit_limit"
                          value={submitCredit.credit_limit}
                          required
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <input
                            type="checkbox"
                            id="send_notification"
                            checked={submitCredit.send_notification}
                            onChange={handleChangeCredit}
                          />
                          &nbsp; Send Notification
                        </label>
                      </div>

                      <div class="modal-footer">
                        <button
                          type="submit"
                          value="submit"
                          class="btn btn-success"
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-light"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal interval fade"
        id="exampleModal-3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-3"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-3">
                Modal title
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success">
                Submit
              </button>
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal bq fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Update Quantity in bulk
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleSubmitUpdateQuantity}>
                <div class="form-group">
                  <label htmlFor="newQuantity">New Quantity:</label>
                  <input
                    type="number"
                    id="newQuantityBU"
                    value={newQuantityBU}
                    class="form-control"
                    onChange={(e) => setNewQuantityBU(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label htmlFor="startDate">Start Date:</label>
                  <br />
                  {afterEleven ? (
                    <DatePicker
                      selected={startDateBU}
                      onChange={(date) => setStartDateBU(date)}
                      selectsStart
                      minDate={addDays(new Date(), 2)}
                      startDate={startDateBU}
                      endDate={endDateBU}
                      className="form-control"
                      placeholderText="Start Date"
                      dateFormat="dd-MM-yyyy"
                    />
                  ) : (
                    <DatePicker
                      selected={startDateBU}
                      onChange={(date) => setStartDateBU(date)}
                      selectsStart
                      minDate={addDays(new Date(), 1)}
                      startDate={startDateBU}
                      endDate={endDateBU}
                      className="form-control"
                      placeholderText="Start Date"
                      dateFormat="dd-MM-yyyy"
                    />
                  )}
                </div>
                <div class="form-group">
                  <label htmlFor="endDate">End Date:</label>
                  <br />
                  <DatePicker
                    selected={endDateBU}
                    onChange={(date) => setEndDateBU(date)}
                    selectsEnd
                    startDate={startDateBU}
                    endDate={endDateBU}
                    minDate={addDays(new Date(), 2)}
                    className="form-control"
                    placeholderText="End Date"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button type="submit" class="btn btn-primary">
                    Update Quantities
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal mdlv fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Update Status
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="myForm" onSubmit={handleSubmitMarkDelivered}>
                {oiidStatus === "2" ? (
                  <>
                    {" "}
                    <div class="form-group">
                      <label>
                        Are you sure you would like to mark the order(s) as
                        cancelled?
                      </label>
                      {/* <input class="form-control" type="number" onChange={handleChange} id="amount" value={submit.amount} required autoComplete="off" /> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div class="form-group">
                      <label style={{ fontSize: "15px", fontWeight: "500" }}>
                        Are you sure you would like to mark the order(s) as
                        delivered?
                      </label>
                    </div>
                  </>
                )}

                {oiidStatus === "2" ? (
                  <>
                    <div class="form-group">
                      <label>Reason</label>
                      <select
                        class="form-control"
                        onChange={handleChangeCR}
                        id="reason"
                        value={submitCR.reason}
                        required
                      >
                        <option value="">Select Reason</option>
                        <option value="Ordered by mistake">
                          Ordered by mistake
                        </option>
                        <option value="Curdled/taste issue">
                          Curdled/taste issue
                        </option>
                        <option value="Quality issue">Quality issue</option>
                        <option value="Packaging issue">Packaging issue</option>
                        <option value="Quantity not adequate">
                          Quantity not adequate
                        </option>
                        <option value="Delivery time missed">
                          Delivery time missed
                        </option>
                      </select>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div class="modal-footer">
                  <button type="submit" value="submit" class="btn btn-success">
                    {" "}
                    {oiidStatus === "2" ? "Cancel Order" : "Mark Delivered"}
                  </button>
                  <button
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal resumedatesub fade"
        id="exampleModal-2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel-2">
                Update Status
              </h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="myForm" onSubmit={handlePauseSubscription}>
                <div class="form-group">
                  <label style={{ marginRight: "10px" }}>
                    Select Resume Date:
                  </label>
                  {afterEleven ? (
                    <DatePicker
                      selected={resumeDate}
                      onChange={(date) => setResumeDate(date)}
                      selectsStart
                      minDate={addDays(new Date(), 2)}
                      placeholderText="Resume Date"
                      class="form-control"
                      dateFormat="dd-MM-YYYY"
                    />
                  ) : (
                    <DatePicker
                      selected={resumeDate}
                      onChange={(date) => setResumeDate(date)}
                      selectsStart
                      minDate={addDays(new Date(), 1)}
                      placeholderText="Resume Date"
                      class="form-control"
                      dateFormat="dd-MM-YYYY"
                    />
                  )}
                </div>

                <div class="modal-footer">
                  <button type="submit" value="submit" class="btn btn-success">
                    Pause
                  </button>
                  <button
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal subedit fade"
        id="exampleModal-2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel-2"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel-2">
                Update Subscription
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="myForm" onSubmit={handleSubmitES}>
                <div className="card mb-2">
                  <div className="card-body">
                    <p className="mt-2 card-text">
                      Name: {editSubs.product_name}
                    </p>
                    <div className="form-group row">
                      <div className="col">
                        <p className="mt-2 card-text">
                          Price: ₹ {editSubs.price}
                        </p>
                      </div>
                      <div className="col">
                        <div className="quantity-control">
                          <div class="col">
                            <p className="mt-2 card-text">
                              Quantity :{" "}
                              <i
                                class="mdi mdi-minus-circle"
                                style={{
                                  paddingRight: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleQuantityChangeES(-1)}
                              ></i>
                              {editSubs.quantity}
                              <i
                                class="mdi mdi-plus-circle"
                                style={{
                                  paddingLeft: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleQuantityChangeES(1)}
                              ></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group"
                      style={{ display: "flex", marginBottom: "0.5rem" }}
                    >
                      <div className="col">
                        <label>
                          Everyday
                          <input
                            type="radio"
                            name="subscription_type"
                            value="Everyday"
                            checked={editSubs.subscription_type === "Everyday"}
                            onChange={handleChangeES}
                          />
                        </label>
                      </div>
                      <div className="col">
                        <label>
                          On Interval
                          <input
                            type="radio"
                            name="subscription_type"
                            value="On-Interval"
                            checked={
                              editSubs.subscription_type === "On-Interval"
                            }
                            onChange={handleChangeES}
                          />
                        </label>
                      </div>
                      <div className="col">
                        <label>
                          Custom
                          <input
                            type="radio"
                            name="subscription_type"
                            value="Custom"
                            checked={editSubs.subscription_type === "Custom"}
                            onChange={handleChangeES}
                          />
                        </label>
                      </div>
                    </div>

                    {editSubs.subscription_type === "On-Interval" && (
                      <div className="dropdown">
                        <button
                          className="btn btn-danger btn-xs dropdown-toggle"
                          type="button"
                          id="dropdownMenuSizeButton3"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            height: "22px",
                            padding: "5px",
                            marginRight: "14px",
                            marginLeft: "1rem",
                          }}
                        >
                          {editSubs.interval === ""
                            ? "Days"
                            : `Every ${editSubs.interval} day`}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuSizeButton3"
                        >
                          <h6
                            className="dropdown-item"
                            onClick={() => handleIntervalChangeES(2)}
                          >
                            Every 2nd day
                          </h6>
                          <h6
                            className="dropdown-item"
                            onClick={() => handleIntervalChangeES(3)}
                          >
                            Every 3rd day
                          </h6>
                          <h6
                            className="dropdown-item"
                            onClick={() => handleIntervalChangeES(4)}
                          >
                            Every 4th day
                          </h6>
                          <div className="dropdown-divider"></div>
                          <h6
                            className="dropdown-item"
                            onClick={() => handleIntervalChangeES("nth")}
                          >
                            Every nth day
                          </h6>
                        </div>
                      </div>
                    )}

                    {nthES && (
                      <div>
                        <input
                          type="number"
                          className=""
                          style={{
                            width: "2rem",
                            border: "1px solid #dee2e6",
                            fontWeight: "400",
                            borderRadius: "4px",
                            height: "22px",
                            marginRight: "1rem",
                            fontSize: "11px",
                          }}
                          name="interval"
                          value={editSubs.interval}
                          onChange={handleNthDayChangeES}
                          placeholder="value"
                        />
                      </div>
                    )}

                    {editSubs.subscription_type === "Custom" && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1rem",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            S
                          </div>
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            M
                          </div>
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            T
                          </div>
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            W
                          </div>
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            T
                          </div>
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            F
                          </div>
                          <div
                            className="badge badge-pill badge-outline-primary"
                            style={{ marginRight: "1rem" }}
                          >
                            S
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1rem",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="sunday"
                            value={editSubs.sunday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="monday"
                            value={editSubs.monday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="tuesday"
                            value={editSubs.tuesday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="wednesday"
                            value={editSubs.wednesday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="thursday"
                            value={editSubs.thursday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="friday"
                            value={editSubs.friday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                          <input
                            type="number"
                            className=""
                            style={{
                              width: "2rem",
                              border: "1px solid #dee2e6",
                              fontWeight: "400",
                              borderRadius: "4px",
                              height: "22px",
                              marginRight: "1rem",
                              fontSize: "11px",
                            }}
                            name="saturday"
                            value={editSubs.saturday}
                            onChange={handleChangeES}
                            placeholder="value"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="submit" className="btn btn-success">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
