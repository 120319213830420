import React, { useState, useEffect, useContext } from "react";
import db from "./Firebase";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Moment from "moment";
import { extendMoment } from "moment-range";
import "./Report.css";
import { useNavigate } from "react-router-dom";
import ExportTableToExcel from "./ExportTableToExcel";
import { handleLogout } from "./Utility";
import GlobalContext from "./context/GlobalContext";
function ActivityLogs() {
    const navigate = useNavigate();
  
    const [isLoading, setIsLoading] = useState(false);
  
    const {permissible_roles} = useContext(GlobalContext)

    useEffect(() => {
        const loggedIn = localStorage.getItem("loggedIn") === "true";
        
        if (!loggedIn) {
            navigate("/login");
        }else{
            if(permissible_roles.length>0){
                if(!permissible_roles.includes('dashboard')){
                    handleLogout()
                    navigate("/activity_logs");
                }
            }
        }
    }, [navigate,permissible_roles]);
    const moment = extendMoment(Moment);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [errMsg, setErrMsg] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [walletTxnsReport, setWalletTxnsReport] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [activityLogs, setActivityLogs] = useState(null);
    const [endDateQuery, setEndDateQuery] = useState(null);
    const [deliveryExecutivesMap, setDeliveryExecutivesMap] = useState(new Map());

    const getDeliveryExecutives = async () => {
        try {
          const snapshot = await db
            .collection("hubs_users_data")
            .where("role", "==", "Delivery Executive")
            .get();
    
          const map = new Map();
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            const name = `${data.first_name} ${data.last_name}`;
            map.set(data.hub_user_id, name);
          });
          setDeliveryExecutivesMap(map);
        } catch (error) {
          toast.error("Error fetching delivery executives.");
          console.error(error);
        }
      };
    
      useEffect(() => {
        getDeliveryExecutives();
      }, []);


   // Validate date range
   const validateDateRange = () => {
    if (!startDate) {
      toast.error("Please enter From Date");
      return false;
    }
    if (!endDate) {
      toast.error("Please enter To Date");
      return false;
    }
    if (startDate > endDate) {
      toast.error("From Date should not be greater than To Date");
      return false;
    }
    return true;
  };
          const handleStartDateChange = (date) => {
            if (date) {
              setStartDate(new Date(date.setHours(0, 0, 0, 0))); // Set to start of day
            }
          };
        
          const handleEndDateChange = (date) => {
            if (date) {
              setEndDate(new Date(date.setHours(23, 59, 59, 999))); // Set to end of day
            }
          };

    const toUTCDate = (date) => {
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return utcDate;
    };

    const handleSearch = async () => {
      if (!validateDateRange()) return;
    
      setIsLoading(true);
    
      try {
        const activitySnapshot = await db
          .collection("customer_activities")
          .where("created_date", ">=", startDate)
          .where("created_date", "<=", endDate)
          .get();
    
        if (activitySnapshot.empty) {
          toast.info("No activities found for the selected date range.");
          setActivityLogs([]);
          setIsLoading(false);
          return;
        }
    
        let activities = activitySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
    
        // Find activities with missing customer details
        const missingCustomerIds = [
          ...new Set(
            activities
              .filter((activity) => 
                !activity.customer_name || 
                !activity.customer_phone || 
                !activity.customer_address ||
                !activity.hub_name || 
                !activity.delivery_exe_id
              )
              .map((activity) => activity.customer_id)
          ),
        ];
    console.log('Find activities with missing customer details',missingCustomerIds);
    
        if (missingCustomerIds.length > 0) {
          // Fetch missing customer details from `customers_data`
          const customerDetailsSnapshots = await Promise.all(
            missingCustomerIds.map((id) => db.collection("customers_data").where("customer_id", "==", `${id}`).get())
          );
          
          // console.log('Fetch missing customer details from `customers_data',customerDetailsSnapshots);
    
          // Create a customerMap with retrieved customer data
          const customerMap = customerDetailsSnapshots.reduce((map, querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              map[data.customer_id] = {
                customer_name: data.customer_name || "N/A",
                customer_phone: data.customer_phone || "N/A",
                customer_address: data.customer_address || "N/A",
                hub_name: data.hub_name || "N/A",
                delivery_exe_id : data.delivery_exe_id
              };
              
              
            });
            return map;
          }, {});
    console.log(customerMap,'Create a customerMap with retrieved customer data');
    
          // Enrich activities with missing details
          activities = activities.map((activity) => {
            if (!activity.customer_name || !activity.customer_phone || !activity.customer_address || !activity.hub_name  || !activity.delivery_exe_id) {
              const customerData = customerMap[activity.customer_id] || {};
              return {
                ...activity,
                customer_name: customerData.customer_name || activity.customer_name || "N/A",
                customer_phone: customerData.customer_phone || activity.customer_phone || "N/A",
                customer_address: customerData.customer_address || activity.customer_address || "N/A",
                hub_name: customerData.hub_name || activity.hub_name || "N/A",
                delivery_exe_id: customerData.delivery_exe_id || activity.delivery_exe_id || "N/A",
              };
            }
            return activity;
          });
        }
        const data = activities.filter(item => item.description);

        setActivityLogs(data);

        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching activity logs:", error);
        toast.error("Failed to fetch activity logs.");
      } finally {
        setIsLoading(false);
      }
    };
    

 // Export to CSV
 const exportToCSV = () => {
  if (!activityLogs.length) {
    toast.error("No data available for export.");
    return;
  }
  const headers = [
    "S No.",
    "Customer ID",
    "Name",
    "Number",
    "Address",
    "Hub",
    "Delivery Executive",
    "User",
    "Object",
    "Action",
    "Description",
    "Date",
  ];
  const csvRows = activityLogs.map((activity, idx) => [
    `"${idx + 1}"`,
    `"${activity.customer_id}"`,
    `"${activity.customer_name}"`,
    `"${activity.customer_phone}"`,
    `"${activity.customer_address}"`,
    `"${activity.hub_name}"`,
    `"${deliveryExecutivesMap.get(activity.delivery_exe_id) || "N/A"}"`,
    `"${activity.user || "N/A"}"`,
    `"${activity.object || "N/A"}"`,
    `"${activity.action || "N/A"}"`,
    `"${activity.description || "N/A"}"`,
    `"${moment(activity.created_date.toDate()).format("DD/MM/YYYY, h:mm a")}"`,
  ]);

  const csvContent = [
    headers.join(","),
    ...csvRows.map((row) => row.join(",")),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "activity_logs.csv";
  link.click();
};

    const handleReset = () => {
        setFromDate(null);
        setToDate(null);
        setStartDate(null);
        setEndDate(null);
        setWalletTxnsReport([]);
    }

    const navi = (id) => {
        const url = `/profile/${id}`;
        const newTab = window.open(url, '_blank');
        newTab.focus();

    }

    const SpinnerOverlay = () => (
        <div className="spinner-overlay">   
            <div className="spinner"></div>
        </div>
    );

    return (
        <>
            <div className="panel" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <span style={{ fontSize: "18px", color: "#288a84", fontWeight: "700", marginTop: "12px" }}>ACTIVITY LOG</span>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                  
                    <>
                        {dataLoaded && <ExportTableToExcel tableId="activitylog" fileName="activitylog" />}
                        {dataLoaded && <Button variant="outline-success"  className="btn btn-success btn-rounded btn-sm"  onClick={exportToCSV} style={{ marginLeft: '10px' }}>Export CSV</Button>}
                    </>
                   
                </div>
            </div>
            
            <div className="datepickers-container">
                <div className="datepicker-container">
                    <label className="datepicker-label">From Date:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy" // Format for displaying the date
                        className="datepicker-input"
                        placeholderText="From Date"
                        maxDate={endDate}
                    />
                </div>
                <br />
                <div className="datepicker-container">
                    <label className="datepicker-label">To Date:</label>
                    <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={startDate}
                        //endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy" // Format for displaying the date
                        className="datepicker-input"
                        placeholderText="To date"
                    />
                </div>
                {/* <Button variant="outline-success" onClick={()=>{handleSearch()}} style={{zIndex:1}}>Search</Button> */}
                <Button 
    variant="outline-success" 
    onClick={handleSearch} 
    disabled={isLoading}
>
    {isLoading ? "Loading..." : "Search"}
</Button>
                <Button variant="outline-success" onClick={handleReset} style={{ marginLeft: '10px' }}>Reset</Button>
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {showSpinner && <div className="spinner-container"><SpinnerOverlay /></div>}
                {isLoading && <SpinnerOverlay />}
                <div class="col-md-6 col-xl-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-striped" id="activitylog">
                                    <thead>
                                        <tr>    
                                            <th>S No.</th>
                                            <th>Customer ID</th>
                                            <th>Name</th>
                                            <th>Number</th>
                                            <th>Address</th>
                                            <th>Hub</th>
                                            <th>Delivery Executive</th>
                                            <th>User</th>
                                            <th>Object</th>
                                            <th>Action</th>
                                            <th>Description</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                     <tbody>
                                      {activityLogs && activityLogs.length > 0 ? (
                                          activityLogs.map((activity, index) => (
                                            <tr key={activity.id}>
                                                  <td>{index + 1}</td>
                                                  <td>{activity.customer_id || "N/A"}</td>
                                                  <td>{activity.customer_name || "N/A"}</td>
                                                  <td>{activity.customer_phone || "N/A"}</td>
                                                  <td>{activity.customer_address || "N/A"}</td>
                                                  <td>{activity.hub_name || "N/A"}</td>
                                                  <td>
                                                      {deliveryExecutivesMap.get(activity.delivery_exe_id) || "N/A"}
                                                  </td>
                                                  <td>{activity.user || "N/A"}</td>
                                                  <td>{activity.object || "N/A"}</td>
                                                  <td>{activity.action || "N/A"}</td>
                                                  <td>{activity.description || "N/A"}</td>
                                                  <td>
                                                      {activity.created_date
                                                          ? moment(activity.created_date.toDate()).format("DD/MM/YYYY, h:mm a")
                                                          : "N/A"}
                                                  </td>
                                            </tr>
                                              ))
                                          ) : (
                                         <tr>
                                            <td colSpan="12" style={{ textAlign: "center" }}>
                                              No records found.
                                            </td>
                                          </tr>
                                      )}
                                     </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
export default ActivityLogs;
